// utils/snackbarUtils.ts
import React, { Dispatch, SetStateAction } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';

// Composant ErrorMessageSnackbar
interface ErrorMessageSnackbarProps {
  open: boolean;
  message: string;
  handleClose: () => void;
  severity: AlertColor;
}

export const ErrorMessageSnackbar: React.FC<ErrorMessageSnackbarProps> = ({ open, message, handleClose, severity }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

// Fonction openSnackbar
type SetSnackbarMessage = Dispatch<SetStateAction<string>>;
type SetSnackbarOpen = Dispatch<SetStateAction<boolean>>;
type SetSeverity = Dispatch<SetStateAction<AlertColor>>;

export const openSnackbar =
  (setSnackbarMessage: SetSnackbarMessage, setSnackbarOpen: SetSnackbarOpen, setSeverity: SetSeverity) =>
  (msg: string, severity: AlertColor) => {
    setSnackbarMessage(msg);
    setSnackbarOpen(true);
    setSeverity(severity as AlertColor);
  };
