import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const CarSvg = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="car-svg"
      width="100%"
      height="100%"
      style={{
        marginBottom: isMobile ? '0em' : '10em',
        marginLeft: isMobile ? '0em' : '1em',
      }}
      viewBox="-2,0,214,275"
    >
      <g id="anti-brouillard_avg">
        <g>
          <ellipse className="st2" cx="78.1" cy="6.8" rx="6.2" ry="5.8"></ellipse>
        </g>
      </g>
      <g id="anti-brouillard_avd">
        <g>
          <ellipse className="st2" cx="129.7" cy="6.8" rx="6.2" ry="5.8"></ellipse>
        </g>
      </g>
      <g id="optique_ard">
        <g>
          <path
            className="st0"
            d="M163.4,256.1L163.4,256.1c-0.2,0-0.3,0.1-0.5,0.1c-3.4,0.6-4.9,0.6-4.9,0.6c0,2.1,0,9.9,0,9.9           c1.7-0.2,3.5-0.3,5.4-0.5l0-0.3V256.1z"
          ></path>
          <g>
            <path
              className="st0"
              d="M177.3,253.5c-3.2-0.9-9.6-2.9-9.6-2.9c0.1,1.7-1.3,4.7-4.3,5.5v0v9.8l0,0.3c5.6-0.5,11.4-1.3,12.6-4.9            C177.6,256.4,177.3,253.5,177.3,253.5z"
            ></path>
            <line className="st1" x1="175.8" y1="261.7" x2="163.4" y2="261.7"></line>
            <line className="st1" x1="166.4" y1="256.7" x2="163.4" y2="256.7"></line>
            <line className="st1" x1="166.4" y1="258.6" x2="163.4" y2="258.6"></line>
            <line className="st1" x1="166.4" y1="260.2" x2="163.4" y2="260.2"></line>
            <line className="st1" x1="166.4" y1="263.3" x2="163.4" y2="263.3"></line>
            <line className="st1" x1="166.4" y1="264.9" x2="163.4" y2="264.9"></line>
          </g>
        </g>
      </g>
      <g id="optique_arg">
        <g>
          <path
            className="st0"
            d="M43.4,256.1L43.4,256.1c0.2,0,0.3,0.1,0.5,0.1c3.4,0.6,4.9,0.6,4.9,0.6c0,2.1,0,9.9,0,9.9           c-1.7-0.2-3.5-0.3-5.4-0.5l0-0.3V256.1z"
          ></path>
          <g>
            <path
              className="st0"
              d="M29.4,253.5c3.2-0.9,9.6-2.9,9.6-2.9c-0.1,1.7,1.3,4.7,4.3,5.5v0v9.8l0,0.3c-5.6-0.5-11.4-1.3-12.6-4.9            C29.2,256.4,29.4,253.5,29.4,253.5z"
            ></path>
            <line className="st1" x1="30.9" y1="261.7" x2="43.4" y2="261.7"></line>
            <line className="st1" x1="40.3" y1="256.7" x2="43.4" y2="256.7"></line>
            <line className="st1" x1="40.3" y1="258.6" x2="43.4" y2="258.6"></line>
            <line className="st1" x1="40.3" y1="260.2" x2="43.4" y2="260.2"></line>
            <line className="st1" x1="40.3" y1="263.3" x2="43.4" y2="263.3"></line>
            <line className="st1" x1="40.3" y1="264.9" x2="43.4" y2="264.9"></line>
          </g>
        </g>
      </g>
      <g id="bouclier_ard">
        <path
          className="st0"
          d="M106.4,228v20.2l18.5,0c3.5,0,18,0,21.5,0c5.8,0,13.5-0.2,19.2-0.8c4.9-0.5,8.3-1.5,8.3-1.5          c3.7-1,4.6-3.4,5.3-5.8c0.8-2.4,1.5-5.2,2.1-8.2l-17.7-5.2l-3.6-1.1c-4.1,1.1-13.9,2.4-35.2,2.4h-0.2H106.4"
        ></path>
        <polyline className="st0" points="122.9,235.2 122.9,230.6 106.4,230.6    "></polyline>
      </g>
      <g id="bouclier_arg">
        <path
          className="st0"
          d="M100.2,228v20.2l-18.5,0c-3.5,0-18,0-21.5,0c-5.8,0-13.5-0.2-19.2-0.8c-4.9-0.5-8.3-1.5-8.3-1.5          c-3.7-1-4.6-3.4-5.3-5.8c-0.8-2.4-1.5-5.2-2.1-8.2l17.7-5.2l3.6-1.1c4.1,1.1,13.9,2.4,35.2,2.4H82H100.2"
        ></path>
        <polyline className="st0" points="82.8,235.2 82.8,230.6 99.3,230.6    "></polyline>
      </g>
      <g id="aile_ard">
        <path
          className="st0"
          d="M161.2,192h4.9c0.1-0.2,0.2-0.2,0.1-0.1c2.2-4.3,3.6-6.7,8.9-8c2.1-0.5,4.3-1.1,6.3-1.8          c1-0.4,1.9-0.8,2.7-1.3c0.2,0,0.4,0,0.6,0c7.8,0,8.9,0,8.9,0c-0.1,1.8-0.1,2.8-0.1,2.8l-8.7,0.5c-8.7,0.7-15.1,7.4-15.1,16          c0,5,1.9,8.8,5.3,11.9c2.2,1.9,4.7,3.4,8.8,4.2l0,0c-0.3,2.8-0.8,5.7-1.3,8.5l-17.7-5.2h-0.1l-3.6-1.1v-4.1          C161.6,210.5,161.3,200.7,161.2,192z"
        ></path>
      </g>
      <g id="aile_arg">
        <path
          className="st0"
          d="M45.6,192h-4.9c-0.1-0.2-0.2-0.2-0.1-0.1c-2.2-4.3-3.6-6.7-8.9-8c-2.1-0.5-4.3-1.1-6.3-1.8          c-1-0.4-1.9-0.8-2.7-1.3c-0.2,0-0.4,0-0.6,0c-7.8,0-8.9,0-8.9,0c0.1,1.8,0.1,2.8,0.1,2.8l8.7,0.5c8.7,0.7,15.1,7.4,15.1,16          c0,5-1.9,8.8-5.3,11.9c-2.2,1.9-4.7,3.4-8.8,4.2l0,0c0.3,2.8,0.8,5.7,1.3,8.5l17.7-5.2h0.1l3.6-1.1v-4.1          C45.1,210.5,45.4,200.7,45.6,192z"
        ></path>
      </g>
      <g id="hayon">
        <path
          className="st0"
          d="M103.2,219.4c-21.1-0.1-30.9-1.3-35-2.4l1.1,3.8c4.1,1.1,12.6,2.3,33.4,2.4l0,0c0.1,0,0.2,0,0.3,0          c0.1,0,0.2,0,0.3,0v0c20.8-0.1,29.8-1.3,33.9-2.4l1.1-3.8c-4.1,1.1-13.9,2.3-35,2.4H103.2z"
        ></path>
        <path
          className="st0"
          d="M138.5,198.7c-0.1-3.5-0.1-7-0.1-10.1c-6.2,2.2-21.4,4.9-35,4.9h0h-0.2h0c-13.6,0-28.8-2.7-35-4.9          c0,3.1-0.1,6.6-0.1,10.1c-0.1,7.6-0.3,15.2,0.1,18.4c4.1,1.1,13.9,2.3,35,2.4h0.2c21.1-0.1,30.9-1.3,35-2.4          C138.8,213.9,138.6,206.3,138.5,198.7z"
        ></path>
        <circle className="st1" cx="103.3" cy="221.3" r="0.9"></circle>
      </g>
      <g id="pare_brise_ar">
        <path
          className="st0"
          d="M124.7,171.1c-7.9,1.4-13.5,1.1-21.3,1.1c-7.9,0-13.5,0.2-21.3-1.1c-3.7,4.6-9.9,13.4-9.9,13.4          c3.9,1,13.4,4.1,31.3,4.1s27.4-3.1,31.3-4.1C134.6,184.4,128.5,175.7,124.7,171.1z"
        ></path>
      </g>
      <g style={{ pointerEvents: 'none' }} id="pavillon_d" className="light">
        <g>
          <polygon
            className="st0"
            points="144.5,184.7 147.6,182.2 137.7,169.8 137.7,121.7 147.5,106.8 144.2,104.6 133.7,120.5            133.7,171.1     "
          ></polygon>
        </g>
      </g>
      <g style={{ pointerEvents: 'none' }} id="pavillon_g" className="light">
        <g>
          <polygon
            className="st0"
            points="62.2,184.7 59.1,182.2 69,169.8 69,121.7 59.2,106.8 62.6,104.6 73,120.5 73,171.1     "
          ></polygon>
        </g>
      </g>
      <g id="porte_arg">
        <g>
          <path
            className="st0"
            d="M25.5,144.6c-0.1,9.2-0.3,19.9,0,26.4c-0.1,2,2.1,3.4,5,4.5c1.9,0.7,4.1,1.3,6.3,1.8           c5.3,1.4,6.7,3.8,8.9,8.1h5v-40.9H25.5z"
          ></path>
          <polygon className="st0" points="50.7,144.6 50.7,185.4 61.1,172.4 61.1,144.6     "></polygon>
          <line className="st0" x1="48.2" y1="172.2" x2="48.2" y2="180.2"></line>
        </g>
      </g>
      <g id="porte_ard">
        <g>
          <path
            className="st0"
            d="M181.2,144.6c0.1,9.2,0.3,19.9,0,26.4c0.1,2-2.1,3.4-5,4.5c-1.9,0.7-4.1,1.3-6.3,1.8           c-5.3,1.4-6.7,3.8-8.9,8.1h-5v-40.9H181.2z"
          ></path>
          <polygon className="st0" points="156,144.6 156,185.4 145.6,172.4 145.6,144.6     "></polygon>
          <line className="st0" x1="158.5" y1="172.2" x2="158.5" y2="180.2"></line>
        </g>
      </g>
      <g id="pavillon">
        <path
          className="st0"
          d="M126.3,163.5c-7.9,1.1-14.3,0.9-23,0.9c-8.6,0-15-0.1-23-1.2v-35.5c8.5-1.5,19.9-2.1,23-2.1          c3,0,14.5,0.6,23,2.1V163.5z"
        ></path>
      </g>
      <g id="pare_brise_av">
        <path
          className="st0"
          d="M103.4,100.9c-7.9,0-21.1,1.6-30.4,4.8c5,7.9,9.5,14.4,9.5,14.4c6.6-1.6,18.1-2.2,20.9-2.2          c2.7,0,14.3,0.6,20.9,2.2c0,0,4.5-6.5,9.5-14.4C124.5,102.6,111.3,100.9,103.4,100.9z"
        ></path>
      </g>
      <g id="porte_avd">
        <g>
          <path
            className="st0"
            d="M156,103.7c2.1,0.2,17.6,1.6,20.6,2c3.6,0.5,4.7,3.2,4.7,6.5c0.2,6.3-0.2,13.7-0.2,23.1c0,1.7,0,3.4,0,5.3           H156V103.7z"
          ></path>
          <path className="st0" d="M145.6,140.6v-21.5l10.1-15.4c0,0,0.1,0,0.2,0v36.9H145.6z"></path>
          <line className="st0" x1="159.3" y1="128.9" x2="159.3" y2="136.9"></line>
        </g>
      </g>
      <g id="porte_avg">
        <g>
          <path
            className="st0"
            d="M50.7,103.7c-2.1,0.2-17.6,1.6-20.6,2c-3.6,0.5-4.7,3.2-4.7,6.5c-0.2,6.3,0.2,13.7,0.2,23.1           c0,1.7,0,3.4,0,5.3h25.2V103.7z"
          ></path>
          <path className="st0" d="M61.1,140.6v-21.5L51,103.7c0,0-0.1,0-0.2,0v36.9H61.1z"></path>
          <line className="st0" x1="47.5" y1="128.9" x2="47.5" y2="136.9"></line>
        </g>
      </g>
      <g id="capot">
        <path
          className="st0"
          d="M103.4,63.1c-12.3,0-29.8,2.1-35,3.4c0,0.8,0,1.8,0,2.8c0,11.5,0,32.4,0,32.4c5.2-1.8,20.4-5.5,35-5.5          s29.8,3.8,35,5.5c0,0,0-20.9,0-32.4c0-1,0-1.9,0-2.8C133.1,65.2,115.6,63.1,103.4,63.1z"
        ></path>
      </g>
      <g id="retroviseur_d">
        <g>
          <rect x="142.1" y="97.7" className="st0" width="12.9" height="2.8"></rect>
          <path
            className="st0"
            d="M154.8,86.9l-9.1,5.9l0,0l5.7-1.1c1.8-0.6,3.6,0.7,3.6,2.6l1.7-6C157.1,87.2,155.8,86.3,154.8,86.9z"
          ></path>
          <path
            className="st0"
            d="M151.4,91.8l-5.7,1.1l-1,0.2c-1.5,0.5-2.6,2-2.6,3.6v1H155v-3.4C155,92.5,153.2,91.2,151.4,91.8z"
          ></path>
        </g>
      </g>
      <g id="retroviseur_g">
        <g>
          <rect
            x="51.7"
            y="97.7"
            transform="matrix(-1 -4.485935e-11 4.485935e-11 -1 116.3213 198.2558)"
            className="st0"
            width="12.9"
            height="2.8"
          ></rect>
          <path
            className="st0"
            d="M52,86.9l9.1,5.9l0,0l-5.7-1.1c-1.8-0.6-3.6,0.7-3.6,2.6l-1.7-6C49.7,87.2,51,86.3,52,86.9z"
          ></path>
          <path
            className="st0"
            d="M55.3,91.8l5.7,1.1l1,0.2c1.5,0.5,2.6,2,2.6,3.6v1H51.7v-3.4C51.7,92.5,53.6,91.2,55.3,91.8z"
          ></path>
        </g>
      </g>
      <g id="bas_de_caisse_droit">
        <path
          className="st0"
          d="M184.4,168.9c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2          c0.1-0.1,0.2-0.3,0.3-0.4c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.4,0.1-0.6          c0.2-6.5,0.1-17.1,0-26.4c0-1.8,0-3.6,0-5.3c0-9.4,0.3-16.8,0.2-23.1c0-0.9-0.1-1.7-0.3-2.5c2.8,0.3,5.4,0.6,7.8,0.8          c0.2,6.4,0.4,15.8,0.4,24.8c0,12.1-0.5,28.4-0.8,34.9c0,0-1,0-8.9,0c-0.3,0-0.5,0-0.8,0c0.2-0.1,0.3-0.2,0.5-0.3          C184.3,168.9,184.4,168.9,184.4,168.9z"
        ></path>
      </g>
      <g id="bas_de_caisse_gauche">
        <path
          className="st0"
          d="M22.3,168.9c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.2          c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.4-0.1-0.6          c-0.2-6.5-0.1-17.1,0-26.4c0-1.8,0-3.6,0-5.3c0-9.4-0.3-16.8-0.2-23.1c0-0.9,0.1-1.7,0.3-2.5c-2.8,0.3-5.4,0.6-7.8,0.8          c-0.2,6.4-0.4,15.8-0.4,24.8c0,12.1,0.5,28.4,0.8,34.9c0,0,1,0,8.9,0c0.3,0,0.5,0,0.8,0c-0.2-0.1-0.3-0.2-0.5-0.3          C22.4,168.9,22.4,168.9,22.3,168.9z"
        ></path>
      </g>
      <g id="aile_avd">
        <path
          className="st0"
          d="M160.5,93L160.5,93c0-5.3,0-21.1,0-30.6c7.9-1.6,18.7-4.1,18.7-4.1l3.5-0.8c0.4,2.4,0.6,4.6,0.9,6.2l0,0          c-3.8,0.8-6.1,2.2-8.2,4c-3.5,3.1-5.9,7.3-5.9,12.3c0,8.7,6.1,13.8,15.7,14.7c2.9,0.3,8,0.5,8,0.5s0,1.7,0.1,2.8l0,0l0,0          c0,1.3,0.1,3.3,0.2,5.8c-2.4-0.2-5-0.5-7.8-0.8c-0.5-2.1-1.8-3.7-4.4-4c-3-0.4-18.5-1.8-20.6-2L160.5,93z"
        ></path>
      </g>
      <g id="aile_avg">
        <path
          className="st0"
          d="M46.2,93L46.2,93c0-5.3,0-21.1,0-30.6c-7.9-1.6-18.7-4.1-18.7-4.1L24,57.6c-0.4,2.4-0.6,4.6-0.9,6.2l0,0          c3.8,0.8,6.1,2.2,8.2,4c3.5,3.1,5.9,7.3,5.9,12.3c0,8.7-6.1,13.8-15.7,14.7c-2.9,0.3-8,0.5-8,0.5s0,1.7-0.1,2.8l0,0l0,0          c0,1.3-0.1,3.3-0.2,5.8c2.4-0.2,5-0.5,7.8-0.8c0.5-2.1,1.8-3.7,4.4-4c3-0.4,18.5-1.8,20.6-2L46.2,93z"
        ></path>
        <path
          className="st0"
          d="M46.2,93L46.2,93c0-5.3,0-21.1,0-30.6c-7.9-1.6-18.7-4.1-18.7-4.1L24,57.6c-0.4,2.4-0.6,4.6-0.9,6.2l0,0          c3.8,0.8,6.1,2.2,8.2,4c3.5,3.1,5.9,7.3,5.9,12.3c0,8.7-6.1,13.8-15.7,14.7c-2.9,0.3-8,0.5-8,0.5s0,1.7-0.1,2.8l0,0l0,0          c0,1.3-0.1,3.3-0.2,5.8c2.4-0.2,5-0.5,7.8-0.8c0.5-2.1,1.8-3.7,4.4-4c3-0.4,18.5-1.8,20.6-2L46.2,93z"
        ></path>
      </g>
      <g id="calandre">
        <g>
          <path
            className="st0"
            d="M71.5,60.1c6.1-0.6,26.6-1.1,32.2-1.1h0h0c5.6,0,25.2,0.4,31.3,1.1h0.1V44.3c-6.4-0.2-26.1-0.4-31.4-0.6           c-6.1,0.1-26.3,0.2-32.3,0.3L71.5,60.1L71.5,60.1z"
          ></path>
          <g>
            <polyline className="st1" points="74.3,46.3 74.3,48.3 103.8,48.3      "></polyline>
            <line className="st1" x1="74.3" y1="50.4" x2="103.8" y2="50.4"></line>
            <line className="st1" x1="74.3" y1="52.4" x2="103.8" y2="52.4"></line>
            <polyline className="st1" points="132.5,46.3 132.5,48.3 103.8,48.3      "></polyline>
            <line className="st1" x1="132.5" y1="50.4" x2="103.8" y2="50.4"></line>
            <line className="st1" x1="132.5" y1="52.4" x2="103.8" y2="52.4"></line>
            <line className="st1" x1="74.3" y1="54.4" x2="103.8" y2="54.4"></line>
            <line className="st1" x1="132.5" y1="54.4" x2="103.8" y2="54.4"></line>
            <line className="st1" x1="74.3" y1="56.4" x2="103.8" y2="56.4"></line>
            <line className="st1" x1="132.5" y1="56.4" x2="103.8" y2="56.4"></line>
          </g>
        </g>
      </g>
      <g id="bouclier_avg">
        <path
          className="st0"
          d="M42.1,23.1c-5.5,0.7-12.6,1.9-15,5.4c-1.6,2.3-2.5,7.6-3.2,12.2l3.5,0.8c0,0,10.8,2.5,18.7,4.1          c0-1,0-1.9,0-2.8c4.9-1.2,39.2-3.1,54-3.3V21.8C84.3,22,50.9,22.1,42.1,23.1z"
        ></path>
      </g>
      <g id="bouclier_avd">
        <path
          className="st0"
          d="M179.5,28.5c-2.4-3.5-9.5-4.7-15-5.4c-8.8-1-42.4-1.1-58.1-1.3v17.7c14.7,0.3,49.3,2.2,54.1,3.3          c0,0.8,0,1.8,0,2.8c7.9-1.6,18.7-4.1,18.7-4.1l3.5-0.8C182.1,36,181.1,30.8,179.5,28.5z"
        ></path>
      </g>
      <g id="optique_avd">
        <g>
          <g>
            <path
              className="st0"
              d="M162.5,2.6c3.4,0.4,6.3,0.9,7.8,1.3c6,1.4,6.2,4.2,6.4,5.4c0.2,1.2,0.2,1.7,1.1,7.1c0,0-5.2,1.5-10.7,2.4            c-0.4-3.4-2.4-4.7-4.6-5.3l0,0V2.6z"
            ></path>
            <line className="st1" x1="175.9" y1="12.4" x2="176.7" y2="16.7"></line>
            <line className="st1" x1="174.7" y1="17.2" x2="173.8" y2="12.9"></line>
            <line className="st1" x1="172.6" y1="17.7" x2="171.8" y2="13.3"></line>
            <line className="st1" x1="170.6" y1="18.1" x2="169.7" y2="13.8"></line>
            <line className="st1" x1="168.5" y1="18.6" x2="167.7" y2="14.3"></line>
          </g>
          <g>
            <path
              className="st0"
              d="M142.6,12.4V1.5c2.6,0.1,9.4,0.1,15.7,0.6c1.4,0.1,2.8,0.3,4.2,0.4v10.9l0,0c-1.4-0.3-2.9-0.4-4.2-0.5            C155,12.8,150.1,12.5,142.6,12.4z"
            ></path>
            <path className="st1" d="M162.5,7.2c-4.4,2-11.7,4.4-18.2-1.5"></path>
          </g>
        </g>
      </g>
      <g id="optique_avg">
        <g>
          <g>
            <path
              className="st0"
              d="M45.9,2.6c-3.4,0.4-6.3,0.9-7.8,1.3c-6,1.4-6.2,4.3-6.4,5.4c-0.2,1.2-0.2,1.7-1.1,7.1            c0,0,5.2,1.5,10.7,2.4c0.4-3.4,2.4-4.7,4.6-5.3l0,0V2.6z"
            ></path>
            <line className="st1" x1="32.6" y1="12.4" x2="31.7" y2="16.7"></line>
            <line className="st1" x1="33.8" y1="17.2" x2="34.6" y2="12.8"></line>
            <line className="st1" x1="35.8" y1="17.6" x2="36.6" y2="13.3"></line>
            <line className="st1" x1="37.9" y1="18.1" x2="38.7" y2="13.8"></line>
            <line className="st1" x1="39.9" y1="18.6" x2="40.7" y2="14.2"></line>
          </g>
          <g>
            <path
              className="st0"
              d="M65.9,12.4V1.5c-2.6,0.1-9.4,0.1-15.7,0.6c-1.4,0.1-2.8,0.3-4.2,0.4v10.9l0,0c1.4-0.3,2.9-0.4,4.2-0.5            C53.4,12.8,58.3,12.5,65.9,12.4z"
            ></path>
            <path className="st1" d="M45.9,7.1c4.4,2,11.7,4.4,18.2-1.5"></path>
          </g>
        </g>
      </g>
      <g id="pneu_ard">
        <circle className="st0" cx="188.9" cy="199.9" r="13.9"></circle>
      </g>
      <g id="pneu_arg">
        <circle className="st0" cx="17.8" cy="199.9" r="13.9"></circle>
      </g>
      <g id="pneu_avd">
        <circle className="st0" cx="192.8" cy="77.8" r="13.9"></circle>
      </g>
      <g id="pneu_avg">
        <circle className="st0" cx="13.9" cy="77.8" r="13.9"></circle>
      </g>
    </svg>
  );
};
