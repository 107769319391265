import axios, { Method } from 'axios';
import { getAuth } from 'firebase/auth';

export const makeAuthenticatedApiCall = async <T>(
  method: Method,
  url: string,
  data?: any,
  options?: { responseType: 'json' | 'blob' },
) => {
  const auth = getAuth();
  if (!auth.currentUser) {
    throw new Error('User not authenticated');
  }

  const token = await auth.currentUser.getIdToken();
  try {
    const response = await axios<T>({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: options?.responseType || 'json', // Utiliser la valeur du paramètre ou 'json' par défaut
    });
    return response;
  } catch (error) {
    console.error('Error making API call:', error);
    throw error;
  }
};
