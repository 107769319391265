import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  Tabs,
  Tab,
  Box,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from '@mui/material';
import { makeAuthenticatedApiCall } from '../../../apiHelper';
import { DBDeal } from '../../../types/deal';
import FirebaseUploadComponent from '../../CreateCotation/FirebaseUploadComponent';
import { FormDataPerson, FormDataPro } from '../../../types/buyerData';
import theme from '../../../theme';
import { removeSpaces } from '../../../utils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
  deal: DBDeal;
  onRefresh: () => void;
}

const SoldDepositSaleButton: React.FC<Props> = ({ deal, onRefresh }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]); // photos?.soldDepositSaleFiles || []

  const handleUploadComplete = (url: string) => {
    setUploadedUrls((prevUrls) => [...prevUrls, url]);
  };

  const handleDeleteComplete = (url: string) => {
    setUploadedUrls((prevUrls) => prevUrls.filter((existingUrl) => existingUrl !== url));
  };

  const formMethods = useForm<FormDataPerson | FormDataPro>({
    mode: 'onChange',
  });
  const { control, handleSubmit, formState, trigger, watch, setValue, setError, clearErrors } = formMethods;

  const { errors, isValid } = formState;

  const validationRules = {
    firstName: { required: 'Ce champ est requis' },
    lastName: { required: 'Ce champ est requis' },
    addressNumber: { required: 'Ce champ est requis' },
    addressType: { required: 'Ce champ est requis' },
    addressName: { required: 'Ce champ est requis' },
    city: { required: 'Ce champ est requis' },
    zipCode: { required: 'Ce champ est requis' },
    country: { required: 'Ce champ est requis' },
    siret: { required: 'Ce champ est requis' },
    companyName: { required: 'Ce champ est requis' },
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!deal.ext_url) {
      console.log('Waiting for deal.ext_url to be available');
      // Vous pourriez déclencher une action pour recharger les données ou informer l'utilisateur que les données sont en cours de chargement
    }
    console.log('deal.ext_url', deal.ext_url);
  }, [deal.ext_url]);

  const handleClickSubmit = async (formData: FormDataPerson | FormDataPro) => {
    if (isLoading) return; // Éviter les doubles soumissions

    setIsLoading(true);
    const formValid = await trigger();

    try {
      const clientType = tabValue === 1 ? 'pro' : 'person';
      const uploadedUrlsJson = JSON.stringify(uploadedUrls);
      const depositSaleFormData = { ...formData, dealId: deal.id, clientType, uploadedUrls: uploadedUrlsJson };

      const response = await makeAuthenticatedApiCall(
        'post',
        `/api/deals/${deal.id}/confirm-deposit-sale`,
        depositSaleFormData,
      );

      if (response.status === 200) {
        const responseDepositSaleSold = await makeAuthenticatedApiCall('post', '/api/mails/deposit-sale-sold', {
          dealId: deal.id,
          extUrl: deal.ext_url ?? deal.details_link,
          regPlate: deal.registration,
        });
        console.log('responseDepositSaleSold', responseDepositSaleSold);
        onRefresh();
      }
    } catch (error: any) {
      console.error('Error handling the request:', error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Dépôt-Vente vendu
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
        <DialogTitle id="form-dialog-title" fontWeight={700} sx={{ textAlign: 'center', paddingBottom: 0 }}>
          Informations sur l'acheteur
        </DialogTitle>
        <Tabs
          value={tabValue}
          sx={{ justifyItems: 'center', margin: 'auto' }}
          onChange={handleTabChange}
          aria-label="Type de client"
        >
          <Tab label="Particulier" {...a11yProps(0)} />
          <Tab label="Professionnel" {...a11yProps(1)} />
        </Tabs>
        <DialogContent sx={{ paddingTop: 0 }}>
          <TabPanel value={tabValue} index={0}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit((data: FormDataPerson | FormDataPro) => handleClickSubmit(data))}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={validationRules.firstName}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="firstName"
                          label="Prénom"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={validationRules.lastName}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="lastName"
                          label="Nom"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: 'Ce champ est requis' }}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="email"
                          label="Email"
                          type="email"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="dateOfBirth"
                      rules={tabValue === 0 ? { required: 'Ce champ est requis' } : {}}
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          id="dateOfBirth"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          sx={{ marginTop: '8px' }}
                          className="inputElement"
                          label="Date de naissance"
                          type="date"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="placeOfBirth"
                      control={control}
                      rules={tabValue === 0 ? { required: 'Ce champ est requis' } : {}}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="placeOfBirth"
                          label="Lieu de naissance"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  {/* Champ pour le numéro de l'adresse */}
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressNumber"
                      control={control}
                      rules={validationRules.addressNumber}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressNumber"
                          label="Numéro d'adresse"
                          type="number"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  {/* Champ pour l'extension de l'adresse (Bis, Ter, ...) */}
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressExtension"
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressExtension"
                          label="Extension d'adresse"
                          type="text"
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressType"
                      control={control}
                      defaultValue={''}
                      rules={validationRules.addressType}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth margin="dense" error={!!error}>
                          <InputLabel className="inputElement" id={`${field.name}-label`}>
                            Type d'adresse
                          </InputLabel>
                          <Select
                            className="inputElement"
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '0' }}
                            {...field}
                            labelId={`${field.name}-label`}
                            id={field.name}
                            label="Type d'adresse"
                            value={field.value ? field.value.toLowerCase() : ''}
                          >
                            <MenuItem value="rue">Rue</MenuItem>
                            <MenuItem value="all">Allée</MenuItem>
                            <MenuItem value="avn">Avenue</MenuItem>
                            <MenuItem value="blvd">Boulevard</MenuItem>
                            <MenuItem value="chau">Chaussée</MenuItem>
                            <MenuItem value="chm">Chemin</MenuItem>
                            <MenuItem value="cour">Cour</MenuItem>
                            <MenuItem value="fbg">Faubourg</MenuItem>
                            <MenuItem value="fort">Fort</MenuItem>
                            <MenuItem value="imp">Impasse</MenuItem>
                            <MenuItem value="ldit">Lieu-Dit</MenuItem>
                            <MenuItem value="parc">Parc</MenuItem>
                            <MenuItem value="pass">Passage</MenuItem>
                            <MenuItem value="plc">Place</MenuItem>
                            <MenuItem value="quai">Quai</MenuItem>
                            <MenuItem value="rte">Route</MenuItem>
                            <MenuItem value="sent">Sentier</MenuItem>
                            <MenuItem value="squa">Square</MenuItem>
                            <MenuItem value="sans">Inconnu</MenuItem>
                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="addressName"
                      control={control}
                      rules={validationRules.addressName}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          margin="dense"
                          id="addressName"
                          label="Nom de la voie (Rue, Allée, ou Avenue, ne doivent pas apparaitre ici)"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="addressPlace"
                      control={control}
                      rules={{}}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressPlace"
                          label="Lieu-dit"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="addressStairs"
                      control={control}
                      rules={{}}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressStairs"
                          label="Étage/Escalier/Appartement"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="addressComp"
                      control={control}
                      rules={{}}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressComp"
                          label="Complément d'adresse"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="addressPostalBox"
                      control={control}
                      rules={{}}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressPostalBox"
                          label="Boîte postale"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      name="zipCode"
                      control={control}
                      rules={validationRules.zipCode}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          margin="dense"
                          id="zipCode"
                          label="Code postal"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      name="city"
                      control={control}
                      rules={validationRules.city}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="city"
                          label="Ville"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="country"
                      control={control}
                      rules={validationRules.country}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          margin="dense"
                          id="country"
                          label="Pays"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="gender"
                      control={control}
                      defaultValue="male"
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Monsieur"
                            className="inputElement"
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Madame"
                            className="inputElement"
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="documentType"
                      control={control}
                      rules={tabValue === 0 ? { required: 'Ce champ est requis' } : {}}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <InputLabel className="inputElement">Type de document</InputLabel>
                          <Select
                            {...field}
                            value={field.value || ''} // Ensure it is always controlled
                            displayEmpty // This can help with displaying a placeholder or default value
                            className="inputElement"
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '0' }}
                            fullWidth
                            margin="dense"
                            id="documentType"
                          >
                            <MenuItem value="id_card">Carte d'identité</MenuItem>
                            <MenuItem value="passport">Passeport</MenuItem>
                            <MenuItem value="driving_licence">Permis de conduire</MenuItem>
                            <MenuItem value="residence_permit">Titre de séjour</MenuItem>
                          </Select>
                          {error && (
                            <Typography className="inputElement" fontSize={12} color="error">
                              {error.message}
                            </Typography>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="documentId"
                      rules={tabValue === 0 ? { required: 'Ce champ est requis' } : {}}
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          margin="dense"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          id="documentId"
                          label="ID du document"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="documentIssueDate"
                      rules={tabValue === 0 ? { required: 'Ce champ est requis' } : {}}
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                          margin="dense"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          id="documentIssueDate"
                          label="Date de délivrance du document"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body2">
                      Justificatif de domicile, Passeport ou Carte d'identité (Recto-Verso)
                    </Typography>
                    <FirebaseUploadComponent
                      onDeleteComplete={handleDeleteComplete}
                      onUploadComplete={handleUploadComplete}
                      uploadedUrls={uploadedUrls}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} justifyItems={'flex-end'}>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Annuler
                      </Button>
                      <Button
                        sx={{
                          fontWeight: 600,
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid || isLoading}
                      >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Valider'}
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit((data: FormDataPerson | FormDataPro) => handleClickSubmit(data))}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="siret"
                      control={control}
                      rules={
                        tabValue === 1
                          ? {
                              required: 'Ce champ est requis',
                              minLength: {
                                value: 14,
                                message: 'Le SIRET doit contenir exactement 14 caractères',
                              },
                              maxLength: {
                                value: 14,
                                message: 'Le SIRET doit contenir exactement 14 caractères',
                              },
                            }
                          : {
                              minLength: {
                                value: 14,
                                message: 'Le SIRET doit contenir exactement 14 caractères',
                              },
                              maxLength: {
                                value: 14,
                                message: 'Le SIRET doit contenir exactement 14 caractères',
                              },
                            }
                      }
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          error={!!error}
                          helperText={error ? error.message : ''}
                          {...field}
                          label="SIRET"
                          fullWidth
                          margin="dense"
                          className="inputElement"
                          inputProps={{ maxLength: 14 }} // Limite le nombre de caractères que l'utilisateur peut taper
                          onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="companyName"
                      control={control}
                      rules={tabValue === 1 ? { required: 'Ce champ est requis' } : {}}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          label="Raison sociale"
                          fullWidth
                          margin="dense"
                          className="inputElement"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: 'Ce champ est requis' }}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="email"
                          label="Email"
                          type="email"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressNumber"
                      control={control}
                      rules={validationRules.addressNumber}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressNumber"
                          label="Numéro d'adresse"
                          type="number"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressExtension"
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressExtension"
                          label="Extension d'adresse"
                          type="text"
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressType"
                      control={control}
                      defaultValue={''}
                      rules={validationRules.addressType}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth margin="dense" error={!!error}>
                          <InputLabel className="inputElement" id={`${field.name}-label`}>
                            Type d'adresse
                          </InputLabel>
                          <Select
                            className="inputElement"
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '0' }}
                            {...field}
                            labelId={`${field.name}-label`}
                            id={field.name}
                            label="Type d'adresse"
                            value={field.value ? field.value.toLowerCase() : ''}
                          >
                            <MenuItem value="rue">Rue</MenuItem>
                            <MenuItem value="all">Allée</MenuItem>
                            <MenuItem value="avn">Avenue</MenuItem>
                            <MenuItem value="blvd">Boulevard</MenuItem>
                            <MenuItem value="chau">Chaussée</MenuItem>
                            <MenuItem value="chm">Chemin</MenuItem>
                            <MenuItem value="cour">Cour</MenuItem>
                            <MenuItem value="fbg">Faubourg</MenuItem>
                            <MenuItem value="fort">Fort</MenuItem>
                            <MenuItem value="imp">Impasse</MenuItem>
                            <MenuItem value="ldit">Lieu-Dit</MenuItem>
                            <MenuItem value="parc">Parc</MenuItem>
                            <MenuItem value="pass">Passage</MenuItem>
                            <MenuItem value="plc">Place</MenuItem>
                            <MenuItem value="quai">Quai</MenuItem>
                            <MenuItem value="rte">Route</MenuItem>
                            <MenuItem value="sent">Sentier</MenuItem>
                            <MenuItem value="squa">Square</MenuItem>
                            <MenuItem value="sans">Inconnu</MenuItem>
                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="addressName"
                      control={control}
                      rules={validationRules.addressName}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          error={!!error}
                          helperText={error ? error.message : ''}
                          {...field}
                          className="inputElement"
                          margin="dense"
                          id="addressName"
                          label="Nom de la voie (Rue, Allée, ou Avenue, ne doivent pas apparaitre ici)"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      name="zipCode"
                      control={control}
                      rules={validationRules.zipCode}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          margin="dense"
                          id="zipCode"
                          label="Code postal"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      name="city"
                      control={control}
                      rules={validationRules.city}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="city"
                          label="Ville"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="country"
                      control={control}
                      rules={validationRules.country}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          margin="dense"
                          id="country"
                          label="Pays"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body2">KBIS</Typography>
                    <FirebaseUploadComponent
                      onDeleteComplete={handleDeleteComplete}
                      onUploadComplete={handleUploadComplete}
                      uploadedUrls={uploadedUrls}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} justifyItems={'flex-end'}>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Annuler
                      </Button>
                      <Button
                        sx={{
                          fontWeight: 600,
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid || isLoading}
                      >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Valider'}
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SoldDepositSaleButton;
