import {
  Fragment,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import axios from 'axios';
import {
  Paper,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
  TableSortLabel,
  TablePagination,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  Button,
  TextField,
  Box,
  useMediaQuery,
} from '@mui/material';
import { PipedriveDealData, columns, createData } from '../constants/deals';
import {
  Capitalize,
  priceFormatter,
  formatDateToDDMMYYYY,
  formatKilometers,
  formatDateToDDMMYYYYWithTime,
  getFuelValueFromLabel,
} from '../utils';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material/styles';
import DealStageActions from './DealsList/DealStageActions';
import DetailsLink from './DealsList/DetailsLink';
import RegistrationLink from './DealsList/RegistrationLink';
import { OrgNameLink } from './DealsList/OrgNameLink';
import {
  useArbitratorDeals,
  useArbitratorOffersForAgency,
  useDatabaseDeals,
  usePipelines,
  useStages,
} from '../hooks/useApiQueries';
import * as Sentry from '@sentry/react';
import { DBDeal, PipedriveDeal } from '../types/deal';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import OfferLegends from './DealsList/OfferLegends';
import { makeAuthenticatedApiCall } from '../apiHelper';

const count = 6000; // Trouver le moyen d'avoir le vrai compte

// type StagesByTab = { [key: string]: number[] };

const DealsList = ({ agencyId }: { agencyId: string }) => {
  const [rows, setRows] = useState<Data[]>([]);
  // const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState({ key: 'org_name', direction: 'asc' });
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tabValue, setTabValue] = useState<number>(0);
  const [hasMoreItems] = useState<boolean>(true);
  const [initialData, setInitialData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState(''); // État pour le terme de recherche
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [expandedRowId, setExpandedRowId] = useState(null);

  const [updatedDealData, setUpdatedDealData] = useState(null);
  const [setUpdatedArbitrationData] = useState(null);

  const [dealsMap, setDealsMap] = useState<Map<number, DBDeal>>(new Map());

  const { currentUser, idToken } = useAuth(); // Obtenez l'utilisateur actuellement connecté
  const userRole = currentUser?.data?.data?.role;
  const currentUserAgencyId = currentUser?.data?.data?.idPipedrive;

  const { data: stages, isLoading: isLoadingStages } = useStages();
  const { data: pipelines, isLoading: isLoadingPipelines } = usePipelines();

  const [dealsFromDB, setDealsFromDB] = useState<DBDeal[]>([]);

  const { data: arbitratorDeals, refetch: refetchArbitratorDeals } =
    userRole !== 'agency' ? useArbitratorDeals() : useArbitratorOffersForAgency(currentUserAgencyId);

  const arbitrationsMap: Map<number, any> = new Map();

  const queryClient = useQueryClient();

  // const DealStageActionsMemo = React.memo(DealStageActions);

  if (arbitratorDeals && Array.isArray(arbitratorDeals.data)) {
    arbitratorDeals.data.forEach((arbitration: any) => {
      if (arbitration.pipedrive_deal_id !== null) {
        arbitrationsMap.set(arbitration.pipedrive_deal_id, arbitration);
      }
    });
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Fonction pour gérer le clic sur le bouton de recherche
  const handleSearchClick = useCallback(async () => {
    // Vérifier si searchTerm est vide ou contient seulement des espaces
    if (!searchTerm.trim()) {
      setIsSearching(false);
      return;
    }

    setIsSearching(true);
    setIsLoading(true);
    // "DW392JE", "DW-392-JE", "DW392-JE", "DW-392JE", avec des variations de majuscules et minuscules
    const normalizedSearchTerm = searchTerm
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '')
      .replace(/(\w{2})(\d{3})(\w{2})/, '$1-$2-$3');

    try {
      // Construire l'URL avec les paramètres de requête
      const url = `/api/pipedrive/search-deals?searchTerm=${normalizedSearchTerm}&userRole=${userRole}&organizationId=${
        currentUser?.data.data.idPipedrive || ''
      }`;
      const response = await axios.get(url);

      if (response.data) {
        const filteredData = response.data.filter(
          (item: { item: { stage: { id: number } } }) => ![46, 47, 48].includes(item.item.stage.id),
        );
        const dealIds = filteredData.map((item: { item: { id: number } }) => item.item.id);
        dealIds.sort((a: number, b: number) => b - a);
        const topDeals = dealIds.slice(0, 40);

        // Fetch deals by IDs
        const dealsResponse = await makeAuthenticatedApiCall('post', '/api/deals-by-ids', { ids: topDeals });
        if (dealsResponse.data) {
          setDealsFromDB(dealsResponse.data);

          // Mettre à jour dealsMap avec les données reçues
          const newDealsMap = new Map<number, DBDeal>();
          dealsResponse.data.forEach((deal: DBDeal) => {
            if (deal.pipedrive_deal_id !== null) {
              newDealsMap.set(deal.pipedrive_deal_id, deal);
            }
          });
          setDealsMap(newDealsMap); // Mettre à jour l'état dealsMap
        }

        const dealsData = await Promise.all(topDeals.map(fetchDealById));
        setDataRows(dealsData);
      }
    } catch (error) {
      console.error('Error during search:', error);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm]);

  const handleRefresh = useCallback(async () => {
    setIsLoading(true);
    const response = await axios.post('/api/deals/synchronize');
    console.log('Synchronization response:', response.data);

    try {
      // Invalider les requêtes pour forcer un rechargement
      queryClient.invalidateQueries({ queryKey: ['useArbitratorDeals'] });
      queryClient.invalidateQueries({ queryKey: ['useDatabaseDeals'] });

      // Si une recherche est en cours, relancer la recherche
      if (isSearching && searchTerm.trim()) {
        // Vous pouvez utiliser la même logique que celle utilisée dans handleSearchClick
        // Assurez-vous que handleSearchClick ne dépend pas d'autres états locaux qui pourraient être réinitialisés
        await handleSearchClick();
      } else {
        // Sinon, recharger les données initiales
        // await refetchUseDatabaseDeals();
        if (userRole !== 'agency') {
          await refetchArbitratorDeals();
        }

        // Rafraîchir les données en tenant compte de l'onglet actuel
        fetchData(page, rowsPerPage, tabValue);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    queryClient,
    isSearching,
    searchTerm,
    page,
    rowsPerPage,
    tabValue,
    handleSearchClick,
    // refetchUseDatabaseDeals,
    refetchArbitratorDeals,
  ]);

  // Fonction pour gérer le changement dans le champ de texte de recherche
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const fetchDealById = async (dealId: number) => {
    try {
      const response = await axios.get(
        `https://api.pipedrive.com/v1/deals/${dealId}?api_token=${import.meta.env.VITE_PIPEDRIVE_API_KEY}`,
      );
      return response.data.data;
    } catch (error) {
      console.error(`Error fetching deal with ID ${dealId}:`, error);
      // Sentry error logging
      Sentry.withScope((scope) => {
        scope.setTag('where', 'fetchDealById'); // Set a tag to identify where the error happened
        scope.setExtra('dealId', dealId);
        Sentry.captureException(error);
      });
      return null;
    }
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    if (newValue !== 6) {
      const stagesForTab = stagesByTab[`tab${newValue}`];

      // Filtrer les lignes en fonction de l'agencyId et des stages
      const filteredRows = rows.filter((row) => {
        const stageCondition = stagesForTab?.length === 0 || stagesForTab?.includes(row?.stage_id?.toString());
        const agencyCondition = !agencyId || (agencyId && row?.org_id?.toString() === agencyId);

        return stageCondition && agencyCondition;
      });

      setRows(filteredRows);
    }
  };

  const renderCellValue = (
    columnId: string,
    value:
      | string
      | number
      | boolean
      | ReactElement<any, string | JSXElementConstructor<any>>
      | Iterable<ReactNode>
      | null
      | undefined,
    row: Data,
  ) => {
    const fuelValue = getFuelValueFromLabel(row['fuel']);
    // Voir si je peux mettre un cas pour checker que au dessus de 8718
    const dealExistsInDB = dealsMap.has(Number(row['id']));
    const dealDataInDB = dealsMap.get(Number(row['id']));

    switch (columnId) {
      case 'registration':
        return (
          <RegistrationLink
            id={row['id']}
            value={String(value)}
            brand={row['brand']}
            fuel={fuelValue}
            page={page}
            rowsPerPage={rowsPerPage}
            dealExists={dealExistsInDB}
          />
        );
      case 'offer_type':
        if (value === '19') return '⏰ Mandat 30 jours';
        else if (value === '21') return '⚡ Reprise sèche';
        else if (value === '60') return '🏪 Dépôt-Vente';
        else return '';
      case 'org_name':
        return <OrgNameLink row={row} value={String(value)} />;
      case 'details_link':
        return (
          <DetailsLink
            value={String(value)}
            id={row['id']}
            brand={row['brand']}
            fuel={fuelValue}
            page={page}
            rowsPerPage={rowsPerPage}
            dealExists={dealExistsInDB}
          />
        );
      case 'stage_id':
        return (
          <DealStageActions
            stageId={value}
            stages={stages}
            pipelines={pipelines}
            deal={row}
            dealData={updatedDealData || dealDataInDB}
            idToken={idToken}
            tab={tabValue}
            onRefresh={handleRefresh}
          />
        );
      default:
        return value;
    }
  };

  const onSort = (columnId: string) => {
    const direction = sortConfig.key === columnId && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: columnId, direction });
    const sortedRows = [...rows].sort((a, b) => {
      if (a[columnId] < b[columnId]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[columnId] > b[columnId]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setRows(sortedRows);
  };

  const handleRowClick = (row: Data) => {
    if (expandedRowId === row.id) {
      setExpandedRowId(null); // Si la ligne est déjà déroulée, la réduire
    } else {
      setExpandedRowId(row.id); // Sinon, dérouler la ligne cliquée
    }
  };

  const stagesByTab: { [key in `tab${number}`]: string[] } = useMemo(
    () => ({
      tab0: [
        '39',
        '14',
        '30',
        '32',
        '24',
        '33',
        '15',
        '6',
        '1',
        '40',
        '34',
        '43',
        '9',
        '10',
        '45',
        '11',
        '18',
        '19',
        '20',
        '36',
        '37',
        '41',
        '42',
        '44',
      ], // Toutes
      tab1: ['39', '14'], // A0, A1 - Proposition cotation et Inspection SKIPCAR
      tab2: ['30', '32', '24', '33', '15'], // A2, A3, A4, A5, A-6 - En attente de DC, Vérification DC, Contrat CashSentinel à créer, À Traiter, En attente des Documents
      tab3: ['6', '1', '40', '34', '43'], // B1, B2, B3, B4, B5 - Mandat en cours, Vendus - A Traiter (Facture), CashSentinel en attente, DA papier - à traiter, En attente des documents
      tab4: ['9', '10', '45', '11', '18', '19', '20'], // C1, C2, C3, C4, D1, D2, D3 - En cours de rappatriement, En cours de vente, Payé (C4 et D3), Rappatriement en cours, En Vente, Vendus
      tab5: ['36', '37', '41', '42', '44'], // E1, E2, E3, E4, E5 - Depot vente en cours, DV Vendus - A traiter (facture), CashSentinel en attente, DC Papier - A Traiter, en attente des documents
    }),
    [],
  );

  const getTabOptionParameter = (tabValue: number) => {
    switch (tabValue) {
      case 0:
        return '&filter_id=62'; // Toutes sauf les trucs d'inscription
      case 1:
        return '&filter_id=56'; // Propositions de cotations
      case 2:
        return '&filter_id=55'; // Processus d'achat
      case 3:
        return '&filter_id=60'; // Mandats en cours
      case 4:
        return '&filter_id=53'; // Reprise sèches / Fin de mandats
      case 5:
        return '&filter_id=78'; // Dépôt-Ventes en cours
      default:
        return '';
    }
  };

  const fetchData = async (page: number, rowsPerPage: number, tabValue: number) => {
    try {
      setIsLoading(true);

      const endpoint =
        (userRole === 'agency' && currentUser?.data?.data?.idPipedrive) || agencyId
          ? `https://skipcar3.pipedrive.com/v1/organizations/${
              agencyId || currentUser?.data.data.idPipedrive
            }/deals?api_token=${import.meta.env.VITE_PIPEDRIVE_API_KEY}&limit=500&start=0&sort=id%20DESC`
          : `https://skipcar3.pipedrive.com/v1/deals?api_token=${
              import.meta.env.VITE_PIPEDRIVE_API_KEY
            }${getTabOptionParameter(tabValue)}&limit=${rowsPerPage}&start=${page * rowsPerPage}&sort=id%20DESC`;

      const response = await axios.get(endpoint);

      if (response.data.data) {
        const dealIds = response.data.data.map((deal: PipedriveDeal) => deal.id);

        const dealsResponse = await makeAuthenticatedApiCall('post', '/api/deals-by-ids', { ids: dealIds });

        if (dealsResponse.data) {
          setDealsFromDB(dealsResponse.data);

          // Mettre à jour dealsMap avec les données reçues
          const newDealsMap = new Map<number, DBDeal>();
          dealsResponse.data.forEach((deal: DBDeal) => {
            if (deal.pipedrive_deal_id !== null) {
              newDealsMap.set(deal.pipedrive_deal_id, deal);
            }
          });
          setDealsMap(newDealsMap); // Mettre à jour l'état dealsMap
        }

        const filteredData =
          userRole === 'agency'
            ? response.data.data.filter((deal: PipedriveDeal) => deal.pipeline_id !== 6 && deal.pipeline_id !== 8)
            : response.data.data;

        setDataRows(filteredData);
        setInitialData(filteredData);
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des deals:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userRole !== 'agency') {
      // Réinitialisez page et rowsPerPage lorsque tabValue change
      setPage(0);
      setRowsPerPage(20);
    }
  }, [tabValue]);

  useEffect(() => {
    if ((userRole === 'agency' && initialData.length === 0) || userRole !== 'agency') {
      fetchData(page, rowsPerPage, tabValue);
    } else {
      setDataRows(initialData);
    }
  }, [page, rowsPerPage, tabValue]);

  useEffect(() => {
    const connect = () => {
      let wsUrl;

      if (process.env.NODE_ENV === 'development' || process.env.FORCE_LOCAL_WS) {
        wsUrl = 'ws://localhost:3001';
      } else {
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        wsUrl = `${protocol}//${window.location.host}/ws`;
      }
      const socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log('Connected to WebSocket server');
        // Envoie un heartbeat toutes les X secondes pour maintenir la connexion.
        const heartbeatInterval = setInterval(() => {
          socket.send(JSON.stringify({ type: 'heartbeat' }));
        }, 30000); // 30 secondes, par exemple.

        // Assurez-vous de nettoyer l'interval lors de la fermeture de la socket
        socket.onclose = () => {
          clearInterval(heartbeatInterval);
          console.log('WebSocket connection closed. Reconnecting...');
          setTimeout(connect, 5000); // Essayez de vous reconnecter après 5 secondes.
        };
      };

      socket.onerror = (error) => {
        console.log('WebSocket error:', error);
        socket.close(); // Assurez-vous que la connexion est fermée si une erreur survient.
      };

      socket.onmessage = async (event) => {
        const message = JSON.parse(event.data);
        console.log('WebSocket message received:', message);
        if (message.type === 'arbitrationUpdate') {
          setUpdatedDealData(message.data.deal);
          setUpdatedArbitrationData(message.data.arbitration);

          await handleRefresh();
        }
        if (message.type === 'refuseArbitration') {
          // Gérer le cas de 'refuseArbitration'
        }
      };

      // Nettoyage en fermant la connexion WebSocket lors du démontage du composant
      return () => {
        socket.close();
      };
    };

    connect(); // Connectez-vous immédiatement lors du montage du composant
  }, []); // Le tableau de dépendances vide signifie que cet effet s'exécute une fois, au montage du composant

  const handleCancelClick = () => {
    setIsSearching(false); // Mettre à jour isSearching lors de l'annulation
    setSearchTerm(''); // Réinitialiser le terme de recherche
    setDataRows(initialData); // Réinitialiser les données du tableau aux données initiales
  };

  const setDataRows = (deals: any[]) => {
    const newRows = deals.map((deal) => {
      const dealDataInDB = dealsMap.get(deal.id);

      const pipedriveMappedData: PipedriveDealData = {
        id: deal.id,
        add_time: formatDateToDDMMYYYYWithTime(deal.add_time),
        org_name: deal.org_name ? deal.org_name : '',
        update_time: deal.update_time,
        stage_id: deal.stage_id,
        pipeline_id: deal.pipeline_id,
        title: deal.title,
        active: deal.active,
        status: deal.status,
        org_id: deal.org_id?.value,
        person_id: deal.person_id?.value,
        model:
          dealDataInDB && dealDataInDB.model
            ? `${dealDataInDB.model} ${dealDataInDB.derivative}`
            : deal['02925a65d0ea914762f8a702363401fe042c0682'], // Utilisation de la valeur de la DB si disponible, sinon celle de Pipedrive
        date_of_circulation: formatDateToDDMMYYYY(deal['79ac7ef7489f93453caeb3871c7bd293c7162278']),
        fuel: Capitalize(deal['bcb5ff5c08492bc23658db0a705383effa6fcd7d']) ?? '',
        brand: Capitalize(deal['5443f60884f02f4aebb678c1b031f4cdf2f1bdd7']) ?? '',
        mileage: formatKilometers(deal['3ac3c99b1ece74dc410165422b1687afeead3334']),
        final_purchase_price: priceFormatter.format(deal['765c004cfa164d3dcc2c66a08236447d026a1d1f']),
        encheres_vo_price: priceFormatter.format(deal['a64377fc6d9068a19cc139acae38dbf8ca4685bf']),
        offer_type: deal['3ea11b0c87c7bdb47335b1dd201040b9cefc143d'],
        purchase_price: priceFormatter.format(deal['1443a395cef3c44782b22d8bb88b077b91aef48d']),
        deposit_sale_arbitrator: deal['b59078b8623241e5fc580aa2ffcc8b417f97b695'],
        straight_trade_in_arbitrator: deal['1a516ffc98b7e9cd2f58ca58b46c0945e069763c'],
        thirty_day_sale_mandate_arbitrator: deal['47cfbd6239cd6fe0504217d35988ca225c081eee'],
        deposit_sale_supervisor: deal['ccc37c74631e324c69d3ca085897a04b8f864c82'],
        straight_trade_in_supervisor: deal['5d0a90c6c3c4676d8b9a14cbf4191289b1afc278'],
        thirty_day_sale_mandate_supervisor: deal['3cc647d97d41a1e8562e3457d28202b1f8cb1a73'],
        details_link: deal['81292394d28a9b8722d4f9c45bde4bcc52d1a973'],
        version: deal['9de2c63437cba2b024fc3255c22d4121bbbecbd0'],
        color: deal['453ac647ce769c38579a91a07f4b56bba3cb819f'],
        motorization: deal['425765b3b2cde03186080fcaa72cc367876154ed'], // pas utilisé ?
        transmission: deal['dca90049866d5fa2089f3d10b4da9dc6711c497e'],
        registration: deal['9b46fdc3826bd4640f115914543718da6f786f37'],
        registration_certificate_number: deal['0852697ff2416bc63c1706823b1ae771143229f9'], // pas utilisé ?
        options: deal['011b8243b3763dab3ca191fdc912fe2a9ef3aff7'],
        data_store: deal['b8cd89664c069e066439ee3d0a13df5c96c5a1f6'],
        repatriation_date: deal['2b9fff9d7952eaca442be026f7593a85632cce68'],
        vin: deal['81d27549b4122664b81ede974fdf0b0915aa2b57'],
        num_formule: deal['4e9c3fdc1ef10e2faabe5281f7b24fa28da49530'],
        sold_mandate_date: deal['13d1285980d957f7b41f60e6ddbc1ed7b6713545'],
      };

      return createData(pipedriveMappedData);
    });

    const filteredRows =
      userRole === 'agency' || agencyId
        ? newRows.filter(
            (deal) =>
              stagesByTab[`tab${tabValue}`]?.length === 0 || // Si tab0, inclure tous les stage_id
              stagesByTab[`tab${tabValue}`]?.includes(deal.stage_id.toString()),
          )
        : newRows;
    setRows(filteredRows);
  };

  return (
    <>
      <Box>
        <Grid container item xs={12} sm={12} justifyContent="center">
          <Button
            type="button"
            variant="contained"
            sx={{
              fontWeight: 900,
              height: 55,
              margin: '16px auto',
              textAlign: 'center',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            onClick={handleRefresh}
          >
            🔄 Rafraichir la liste
          </Button>
        </Grid>
      </Box>
      <Paper sx={{ marginTop: 1, padding: 5 }} elevation={3}>
        <Box>
          <Grid container item xs={12} sm={12} justifyContent="space-between">
            <Grid item xs={12} sm={8}>
              <Typography mb={2}>Recherche par immatriculation</Typography>

              <TextField
                label="Terme de la recherche"
                className="inputElement"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <Button
                type="button"
                variant="contained"
                sx={{
                  marginLeft: 1,
                  fontWeight: 900,
                  height: 55,
                  backgroundColor: theme.palette.saffron.main,
                  '&:hover': { backgroundColor: theme.palette.saffron.main },
                }}
                onClick={handleSearchClick}
              >
                Rechercher
              </Button>
              {isSearching && (
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ fontWeight: 900, width: 256, marginTop: 1, display: 'block' }}
                  onClick={handleCancelClick}
                >
                  Revenir à la liste principale
                </Button>
              )}
              <Typography variant="h6" sx={{ marginTop: 2, fontSize: '14px' }}>
                Seuls les 40 derniers résultats de recherches sont affichés
              </Typography>
            </Grid>
            <OfferLegends />
          </Grid>
        </Box>
      </Paper>
      {!isSearching && (
        <Tabs
          orientation={isMobile ? 'vertical' : 'horizontal'}
          className="DealsListTabs"
          id="DealsListTabs"
          value={tabValue}
          onChange={handleTabChange}
          centered
        >
          <Tab label="Toutes" />
          <Tab label="Proposition de cotations" />
          <Tab label="Processus d'achat" />
          <Tab label="Mandats en cours" />
          <Tab label="Reprises sèches / Fin de mandats" />
          <Tab label="Dépôt-Ventes en cours" />
        </Tabs>
      )}
      {isLoading || isLoadingStages || isLoadingPipelines ? (
        <Grid container direction="column" justifyContent="center" alignItems="center" marginTop={2}>
          <Typography sx={{ marginBottom: 2 }} variant="body1">
            Chargement
          </Typography>
          <CircularProgress size={70} />
        </Grid>
      ) : (
        <Paper sx={{ marginTop: 5 }} elevation={3}>
          <TableContainer>
            {userRole !== 'agency' && !isSearching && (
              <TablePagination
                nextIconButtonProps={{
                  disabled: !hasMoreItems,
                }}
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                rowsPerPage={rowsPerPage}
                count={count}
                page={page}
                labelRowsPerPage={'Lignes par page'}
                labelDisplayedRows={({ from, to, count }) => `de ${from} à ${to}`}
                onPageChange={(event, newPage) => {
                  setPage(newPage);
                }}
                onRowsPerPageChange={(event) => {
                  const newRowsPerPage = parseInt(event.target.value, 10);
                  setRowsPerPage(newRowsPerPage);
                }}
                className="tablePagination"
              />
            )}
            <Table stickyHeader aria-label="sticky table" size={'small'}>
              {!isMobile && (
                <TableHead>
                  <TableRow>
                    {columns
                      .filter((column) => {
                        // Affichez la colonne si le rôle de l'utilisateur correspond et si nous sommes sur desktop, ou si la colonne doit être affichée sur mobile
                        return (
                          column.visibleForRoles.includes(userRole) && (!isMobile || (isMobile && column.showOnMobile))
                        );
                      })
                      .map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align || 'left'}
                          sortDirection={sortConfig.key === column.id ? sortConfig.direction : false}
                          padding="none"
                          style={{
                            minWidth: column.minWidth,
                            paddingLeft: 10,
                            background: '#262626',
                            backgroundColor: '#262626',
                            color: 'white',
                          }}
                        >
                          <TableSortLabel
                            active={sortConfig.key === column.id}
                            direction={sortConfig.direction}
                            onClick={() => onSort(column.id)}
                            style={{
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {rows.map((row, index) => (
                  <Fragment key={row.id}>
                    <TableRow
                      hover
                      key={row.id}
                      onClick={() => handleRowClick(row)}
                      role="checkbox"
                      tabIndex={-1}
                      className={index % 2 === 0 ? 'grayBackground' : ''}
                      sx={{ height: '200px' }}
                    >
                      {columns
                        .filter((column) => {
                          // Si en mode mobile, filtrez les colonnes basées sur showOnMobile
                          if (isMobile) {
                            return column.showOnMobile && column.visibleForRoles.includes(userRole);
                          }
                          // Sinon, utilisez la logique de filtrage existante
                          return column.visibleForRoles.includes(userRole);
                        })
                        .map((column) => (
                          <TableCell
                            padding="none"
                            style={{ minWidth: column.minWidth, paddingLeft: 10 }}
                            key={column.id}
                            align={column.align || 'left'}
                          >
                            {renderCellValue(column.id, row[column.id], row)}
                          </TableCell>
                        ))}
                    </TableRow>
                    {isMobile && expandedRowId === row.id && (
                      <TableRow>
                        <TableCell colSpan={columns.length}>
                          {columns
                            .filter((column) => !column.showOnMobile && column.visibleForRoles.includes(userRole))
                            .map((column) => (
                              <div key={column.id}>
                                <strong>{column.label}:</strong> {renderCellValue(column.id, row[column.id], row)}
                              </div>
                            ))}
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
            {userRole !== 'agency' && !isSearching && (
              <TablePagination
                nextIconButtonProps={{
                  disabled: !hasMoreItems,
                }}
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={'Lignes par page'}
                labelDisplayedRows={({ from, to, count }) => `de ${from} à ${to}`}
                onPageChange={(event, newPage) => {
                  setPage(newPage);
                }}
                onRowsPerPageChange={(event) => {
                  const newRowsPerPage = parseInt(event.target.value, 10);
                  setRowsPerPage(newRowsPerPage);
                }}
                className="tablePagination"
              />
            )}
          </TableContainer>
        </Paper>
      )}
    </>
  );
};

export default DealsList;
