// packages/frontend/src/components/AuthContext.tsx
import { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { getAuth, onIdTokenChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';

interface AuthContextProps {
  currentUser: User;
  idToken: string;
  isInitializing: boolean;
  isAuthenticated: boolean;
  isFetchingPipedriveData: boolean;
}

type User = null | {
  [key: string]: any; // Utilisez `any` ou `Record<string, any>` pour un type flexible
};

export const AuthContext = createContext<AuthContextProps | null>(null);

const fetchPipedriveData = async (url: string) => {
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error('Error fetching data from Pipedrive:', error);
    return null;
  }
};

export function AuthProvider({ children }: { children: ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User>(null);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [isFetchingPipedriveData, setIsFetchingPipedriveData] = useState<boolean>(true);
  const [idToken, setIdToken] = useState<string>('');

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      setIsInitializing(true); // Indique que l'initialisation est en cours

      if (user) {
        setIsFetchingPipedriveData(true); // Indique que la récupération des données de Pipedrive commence

        try {
          const docSnap = await getDoc(doc(db, 'Users', user.uid));
          if (docSnap.exists()) {
            const userData = { ...user, data: docSnap.data(), authUser: user };
            const userEmail = userData.email;
            const baseUrl = `https://api.pipedrive.com/v1/persons/search?term=${userEmail}&start=0&api_token=${
              import.meta.env.VITE_PIPEDRIVE_API_KEY
            }`;

            const emailUrl = `${baseUrl}&fields=email`;
            const customFieldsUrl = `${baseUrl}&fields=custom_fields`;

            const emailResponse = await fetchPipedriveData(emailUrl);
            const response =
              emailResponse && emailResponse?.data?.data?.items?.length > 0
                ? emailResponse
                : await fetchPipedriveData(customFieldsUrl);

            if (response && response?.data?.success && response?.data?.data?.items?.length > 0) {
              const personData = response.data.data.items[0].item;
              const mergedData = { ...userData, ...personData };
              setCurrentUser({ ...user, data: mergedData, authUser: user });
            }
          }
        } catch (error) {
          console.error('Error fetching user document:', error);
        }

        setIsFetchingPipedriveData(false); // Indique que la récupération des données de Pipedrive est terminée

        // Récupérer le token d'ID et le mettre à jour dans l'état
        user
          .getIdToken()
          .then((token: string) => {
            setIdToken(token);
          })
          .catch((error: any) => {
            console.error('Error getting ID token:', error);
          });
      } else {
        setCurrentUser(null); // Réinitialiser l'utilisateur actuel
        setIdToken(''); // Réinitialiser le token d'ID
        setIsFetchingPipedriveData(false); // Indique que la récupération des données de Pipedrive est terminée
      }

      setIsInitializing(false); // Indique que l'initialisation est terminée
    });

    return () => unsubscribe(); // Nettoyer l'abonnement lors du démontage
  }, [auth, db]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        idToken,
        isInitializing, // Provide isInitializing to the context
        isAuthenticated: currentUser !== null,
        isFetchingPipedriveData, // Provide isInitializing to the context
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context as AuthContextProps; // assert non-null
}
