import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Dialog,
  DialogContent,
  Button,
  Link,
  Divider,
  ListItem,
  List,
  Box,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { Capitalize, formatKilometers, formatDamages, priceFormatter, formatDateToDDMMYYYY } from '../utils';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import html2pdf from 'html2pdf.js';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useAuth } from '../components/AuthContext';
import { isPdf } from '../utils';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CarDeal } from '../types/deal';
import CaseArbitration from '../components/CarDealDetails/CaseArbitration';
import { makeAuthenticatedApiCall } from '../apiHelper';

const CarDealDetailsPage: React.FC = () => {
  const [deal, setDeal] = useState<CarDeal | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(0);
  const [allPhotos, setAllPhotos] = useState<string[]>([]);
  const [validPhotos, setValidPhotos] = useState<Record<string, string[]>>({});
  const [filteredPhotos, setFilteredPhotos] = useState<Record<string, string[]>>({});

  const [doubleCotation, setDoubleCotation] = useState({ dcMarketPrice: '', dcDesiredPrice: '' });

  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation('common');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const make = queryParams.get('make');
  const fuel = queryParams.get('fuel');
  const prospection = queryParams.get('prospection');

  const { currentUser } = useAuth();
  const userRole = currentUser?.data?.data?.role;
  const isArbitrator = queryParams.has('arbitrator') && (userRole === 'god' || userRole === 'arbitrator');

  const isEMA = queryParams.has('ema');

  useEffect(() => {
    const fetchDoubleCotation = async () => {
      try {
        const response = await axios.get(`/api/deals/double-cotation/${id}`);
        setDoubleCotation({
          dcMarketPrice: response.data.dc_market_price,
          dcDesiredPrice: response.data.dc_desired_price,
        });
      } catch (error) {
        console.error('Failed to fetch double cotation data', error);
      }
    };

    fetchDoubleCotation();
  }, [id]);

  // Gestionnaire d'erreur pour les images
  const handleImageError = (category: string, url: string) => {
    setValidPhotos((prevValidPhotos) => {
      // Créer une copie de l'état actuel pour éviter de modifier directement l'état
      const updatedPhotos = { ...prevValidPhotos };
      // Filtrer l'URL qui a causé une erreur
      updatedPhotos[category] = updatedPhotos[category].filter((photoUrl) => photoUrl !== url);
      return updatedPhotos;
    });
  };

  const generatePDF = () => {
    const content = document.getElementById('car-details-content');
    if (content) {
      html2pdf(content, {
        margin: 10,
        filename: `Skipcar-Fiche-${deal?.pipedrive_deal_id}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 3 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      });
    }
  };

  const getDepositSaleSoldPictures = async () => {
    const response = await makeAuthenticatedApiCall('get', `/api/misiv/deposit-sale-buyer/${deal?.pipedrive_deal_id}`);
    return response.data.files;
  };

  const fetchDepositSaleSoldPictures = async () => {
    if (deal && deal?.business_type === 'deposit_sale' && userRole === 'god') {
      const depositSaleSoldPictures = await getDepositSaleSoldPictures();
      return depositSaleSoldPictures;
    }
    return [];
  };

  const filterPhotosForRole = (photos: {
    [x: string]: any[] | string[];
    certificateOfTransfer?: any;
    salesCertificate?: any;
    barredVehicleRegistrationDocument?: any;
    signedPurchaseDeclaration?: any;
    signedPurchaseDeclarationDepositSale?: any;
  }) => {
    if (userRole !== 'god') {
      delete photos.certificateOfTransfer;
      delete photos.salesCertificate;
      delete photos.barredVehicleRegistrationDocument;
      delete photos.signedPurchaseDeclaration;
      delete photos.signedPurchaseDeclarationDepositSale;
    } else {
      const procurementFilePhotos = [
        ...(photos.certificateOfTransfer || []),
        ...(photos.salesCertificate || []),
        ...(photos.barredVehicleRegistrationDocument || []),
      ];
      photos['procurementFilePhotos'] = procurementFilePhotos;
      delete photos.certificateOfTransfer;
      delete photos.salesCertificate;
      delete photos.barredVehicleRegistrationDocument;
    }
    return photos;
  };

  useEffect(() => {
    if (deal) {
      console.log('deal', deal.pipedrive_deal_id);
      let filteredPhotos = { ...deal.photos };

      filteredPhotos = filterPhotosForRole(filteredPhotos);

      fetchDepositSaleSoldPictures().then((depositSaleSoldPictures) => {
        filteredPhotos['depositSaleSoldPictures'] = depositSaleSoldPictures;
        setValidPhotos(filteredPhotos);
        setFilteredPhotos(filteredPhotos);

        const photosArray = Object.values(filteredPhotos || {})
          .flat()
          .filter((url) => !isPdf(url));
        setAllPhotos(photosArray);
      });
    }
  }, [deal, userRole]);

  const handleSave = async () => {
    await downloadPhotos();
  };

  const downloadPhotos = async () => {
    const zip = new JSZip();
    const folder = zip.folder(`Skipcar-Fiche-${deal?.pipedrive_deal_id}`);
    const promises = allPhotos.map(async (url, index) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const format = blob.type.split('/')[1];
        const fileName = `Skipcar-Fiche-${deal?.pipedrive_deal_id}-${index}.${format}`;
        folder?.file(fileName, blob);
      } catch (error) {
        console.error('Error handling photo:', error);
      }
    });

    await Promise.all(promises);

    try {
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `Skipcar-Fiche-${deal?.pipedrive_deal_id}.zip`);
    } catch (error) {
      console.error('Error generating ZIP:', error);
    }
  };

  const handleOpen = (photoUrl: string) => {
    const photosArray = Object.values(deal?.photos || {})
      .flat()
      .filter((url) => !isPdf(url)); // Filtre les PDF

    setAllPhotos(photosArray);
    setCurrentPhotoIndex(photosArray.indexOf(photoUrl));
    setOpen(true);
  };

  const handleNext = () => {
    setCurrentPhotoIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % allPhotos?.length;
      return isPdf(allPhotos[nextIndex]) ? (nextIndex + 1) % allPhotos?.length : nextIndex;
    });
  };

  const handlePrev = () => {
    setCurrentPhotoIndex((prevIndex) => {
      const prevIndexAdjusted = (prevIndex - 1 + allPhotos?.length) % allPhotos?.length;
      return isPdf(allPhotos[prevIndexAdjusted])
        ? (prevIndexAdjusted - 1 + allPhotos?.length) % allPhotos?.length
        : prevIndexAdjusted;
    });
  };

  // Gérer la navigation du clavier pour le carrousel
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (open) {
        if (e.key === 'ArrowRight') {
          handleNext();
        } else if (e.key === 'ArrowLeft') {
          handlePrev();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, handleNext, handlePrev, allPhotos?.length]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get(`/api/deals/${id}`, {
        params: {
          make,
          fuel,
          prospection,
        },
      })
      .then((response) => {
        setDeal(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setError('Paramètres invalides');
        } else {
          console.error('Erreur lors de la récupération du deal:', error);
          setError(error.message || 'Erreur lors de la récupération des données');
        }
        setLoading(false);
      });
  }, [id]);

  function extractFileNameFromUrl(url: string) {
    const urlParts = url.split('/');
    const lastPart = urlParts[urlParts?.length - 1];
    if (lastPart) {
      const fileNameSection = lastPart.split('?')[0];
      const encodedFileName = fileNameSection.split('%2F').pop();
      if (encodedFileName) {
        return decodeURIComponent(encodedFileName);
      }
    }
    return ''; // Retourne une chaîne vide si l'URL n'est pas conforme ou si le nom du fichier est introuvable
  }

  if (loading) {
    return <CircularProgress style={{ alignContent: 'center' }} size={10} />;
  }

  if (error) {
    return (
      <Container>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="body1">Erreur : {error}</Typography>
          <Typography variant="body2">Vous n'avez probablement pas l'autorisation d'accéder à cette page</Typography>
          <Typography variant="body2">
            Si vous pensez que c'est une erreur, veuillez contacter le{' '}
            <Link target="_blank" href="mailto:luc@aptekaa.fr">
              support technique
            </Link>
            .
          </Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container>
      {deal && (
        <>
          <Paper id="car-details-content" elevation={3} style={{ padding: '16px' }}>
            <Button
              onClick={generatePDF}
              style={{
                position: 'relative',
                float: 'right',
                right: '0px',
                top: '0px',
                backgroundColor: theme.palette.saffron.main,
                zIndex: 1000,
              }}
            >
              <PrintIcon color="action" />
            </Button>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="body2">ID : {deal.pipedrive_deal_id}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <img src="https://aptekaa.dev/logo_skipcar_black.png" style={{ float: 'right', width: '150px' }} />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1">{deal.registration_plate}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" align="right">
                  {deal.buy_back_date.replace(/-/g, '/')}
                </Typography>
                {(userRole === 'god' || userRole === 'arbitrator') && (
                  <>
                    <Box mt={2}>
                      {deal.purchase_price && (
                        <Typography variant="h5" align="right">
                          Prix d'achat : {priceFormatter.format(parseInt(deal.purchase_price))}
                        </Typography>
                      )}
                      {deal.encheres_vo_price && (
                        <Typography variant="h5" align="right">
                          Prix Enchères VO : {priceFormatter.format(parseInt(deal.encheres_vo_price))}
                        </Typography>
                      )}
                      {deal.final_purchase_price && (
                        <Typography variant="h5" align="right">
                          Prix d'achat (Final) : {priceFormatter.format(parseInt(deal.final_purchase_price))}
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
              </Grid>
              {isArbitrator && (
                <CaseArbitration
                  dealId={deal.pipedrive_deal_id}
                  extUrl={deal.ext_url}
                  purchasePrice={deal.purchase_price ? priceFormatter.format(parseInt(deal.purchase_price)) : ''}
                  encheresVoPrice={
                    deal.encheres_vo_price ? priceFormatter.format(parseInt(deal.encheres_vo_price)) : ''
                  }
                />
              )}
              <Divider
                variant="fullWidth"
                component="hr"
                style={{
                  marginLeft: 24,
                  backgroundColor: '#fdd15f',
                  height: '2px',
                  width: 'calc(100% - 24px)',
                  border: 0,
                  marginTop: 15,
                }}
              />
              <Grid item xs={12} md={12}>
                <Typography variant="body2">{deal.make ? Capitalize(deal.make.replace(/_/g, ' ')) : ''}</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="body2">
                  {deal.model} {deal.derivative} {deal.commercial_finishing}
                </Typography>
              </Grid>

              <Divider
                variant="fullWidth"
                component="hr"
                style={{
                  marginLeft: 24,
                  backgroundColor: '#fdd15f',
                  height: '2px',
                  width: 'calc(100% - 24px)',
                  border: 0,
                  marginTop: 15,
                }}
              />
              <Grid item xs={12} md={12}>
                <Typography variant="body1" fontSize={22}>
                  Informations générales
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Type d'affaire :</Typography>
                <Typography variant="body2">{t(`business_type.${deal.business_type}`)}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Genre :</Typography>
                <Typography variant="body2">{deal.genre ? Capitalize(deal.genre) : ''}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Date d'immatriculation :</Typography>
                <Typography variant="body2">{formatDateToDDMMYYYY(deal.reg_date)}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Carburant :</Typography>
                <Typography variant="body2">{t(`fuel.${deal.fuel}`)}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Transmission :</Typography>
                <Typography variant="body2">{t(`transmission.${deal.transmission}`)}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Couleur :</Typography>
                <Typography variant="body2">{t(`colour.${deal.colour}`)}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Portes :</Typography>
                <Typography variant="body2">{deal.number_of_doors}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Kilométrage compteur :</Typography>
                <Typography variant="body2">{formatKilometers(deal.kms)}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Confirmation kilométrage :</Typography>
                <Typography variant="body2">{deal.kms_confirm ? 'Oui' : 'Non'}</Typography>
              </Grid>
              {deal.location && (
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">Localisation :</Typography>
                  <Typography variant="body2">{deal.location}</Typography>
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Places :</Typography>
                <Typography variant="body2">{deal.number_of_seats}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">VIN :</Typography>
                <Typography variant="body2">{deal.vin}</Typography>
              </Grid>
              <Divider
                variant="fullWidth"
                component="hr"
                style={{
                  marginLeft: 24,
                  backgroundColor: '#fdd15f',
                  height: '2px',
                  width: 'calc(100% - 24px)',
                  border: 0,
                  marginTop: 15,
                }}
              />
              <Grid item xs={12} md={12}>
                <Typography variant="body1" fontSize={22}>
                  Informations complémentaires
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="body1">Carnet d'entretien :</Typography>
                <Typography variant="body2">{t(`documentation.${deal.documentation}`)}</Typography>
              </Grid>
              {deal.duplicate_keys && (
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">Double des clés :</Typography>
                  <Typography variant="body2">{t(`duplicateKeys.${deal.duplicate_keys}`)}</Typography>
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <Typography variant="body1">TVA :</Typography>
                <Typography variant="body2">{deal.vat ? 'Oui' : 'Non'}</Typography>
              </Grid>
              {deal.date_last_revision && (
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">Date de dernière révision :</Typography>
                  <Typography variant="body2">
                    {deal.date_last_revision ? deal.date_last_revision.replace(/-/g, '/') : 'N/C'}
                  </Typography>
                </Grid>
              )}
              {deal.kms_last_revision && (
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">Kilométrage dernière révision :</Typography>
                  <Typography variant="body2">{formatKilometers(deal.kms_last_revision)}</Typography>
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Origine du véhicule :</Typography>
                <Typography variant="body2">{t(`source.${deal.source}`)}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Véhicule d'occasion :</Typography>
                <Typography variant="body2"> {deal.source === 'second_hand_french' ? 'Oui' : 'Non'}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Type de véhicule :</Typography>
                <Typography variant="body2">{t(`vehicle_type.${deal.vehicle_type}`)}</Typography>
              </Grid>
              <Divider
                variant="fullWidth"
                component="hr"
                style={{
                  marginLeft: 24,
                  backgroundColor: '#fdd15f',
                  height: '2px',
                  width: 'calc(100% - 24px)',
                  border: 0,
                  marginTop: 15,
                }}
              />
              <Grid item xs={12} md={6}>
                <Typography variant="body1" fontSize={22}>
                  Dommages
                </Typography>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ marginBottom: 3 }}>
                    Coût des dommages : {deal.damages_cost} €
                  </Typography>
                </Grid>
                <Typography variant="body2">Dommages relevés par outil EVO :</Typography>
                <List>
                  {formatDamages(deal.damages).map((damage: string, index: number) => (
                    <ListItem key={index}>
                      <span style={{ marginRight: '10px' }}>&bull;</span>
                      {damage}
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Photos :</Typography>
                <Button
                  onClick={handleSave}
                  style={{
                    position: 'relative',
                    float: 'right',
                    right: '0px',
                    top: '0px',
                    backgroundColor: theme.palette.saffron.main,
                    zIndex: 1000,
                    cursor: 'pointer',
                  }}
                >
                  <SaveIcon color="action" />
                </Button>

                <Grid>
                  {Object.entries(filteredPhotos).map(([category, photos]) => {
                    console.log('category', category);
                    const hasValidPhotos = validPhotos[category] && validPhotos[category]?.length > 0;
                    return hasValidPhotos ? (
                      <div key={category}>
                        <Typography variant="body2" sx={{ marginTop: 2 }}>
                          {t(`carDealPictures.${category}`)}
                        </Typography>
                        <Box sx={{ display: 'flex', flexFlow: 'row wrap' }}>
                          {validPhotos[category].map((photoUrl, index) => {
                            if (isPdf(photoUrl)) {
                              // Gestion des fichiers PDF
                              return (
                                <Grid
                                  style={{
                                    height: 130,
                                    overflow: 'hidden',
                                    objectPosition: 'center',
                                    objectFit: 'cover',
                                    marginBottom: 10,
                                    background: 'rgba(253, 187, 95, 0.4)',
                                    margin: '6px 8px',
                                    width: 100,
                                  }}
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  key={index}
                                >
                                  <Link
                                    href={photoUrl}
                                    target="_blank"
                                    onError={() => handleImageError(category, photoUrl)}
                                  >
                                    <PictureAsPdfIcon
                                      style={{
                                        fontSize: 10,
                                        height: 'auto',
                                        width: '70px',
                                        color: 'black',
                                        marginTop: '10px',
                                        marginLeft: '10px',
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        fontFamily: 'Urbanist',
                                        marginTop: '-5px',
                                        fontSize: '8px',
                                        textDecoration: 'none',
                                        color: 'black',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {extractFileNameFromUrl(photoUrl)}
                                    </Typography>
                                  </Link>
                                </Grid>
                              );
                            } else {
                              // Gestion des images normales
                              return (
                                <Grid
                                  style={{
                                    minWidth: 150,
                                    height: 130,
                                    overflow: 'hidden',
                                    objectPosition: 'center',
                                    objectFit: 'cover',
                                    marginBottom: 10,
                                  }}
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  key={index}
                                >
                                  <Button onClick={() => handleOpen(photoUrl)}>
                                    <img
                                      src={photoUrl}
                                      alt={`${category} Photo ${index}`}
                                      style={{ width: '100%', cursor: 'pointer' }}
                                      onError={() => handleImageError(category, photoUrl)}
                                    />
                                  </Button>
                                </Grid>
                              );
                            }
                          })}
                        </Box>
                      </div>
                    ) : null;
                  })}
                </Grid>
              </Grid>
              <Divider
                variant="fullWidth"
                component="hr"
                style={{
                  marginLeft: 24,
                  backgroundColor: '#fdd15f',
                  height: '2px',
                  width: 'calc(100% - 24px)',
                  border: 0,
                  marginTop: 15,
                }}
              />
              <Grid item xs={12} md={12}>
                <Typography variant="body1" fontSize={22}>
                  Essai du véhicule
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Essai routier :</Typography>
                <Typography variant="body2">{t(`test_drive.${deal.test_drive}`)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Condition mécanique :</Typography>
                <Typography variant="body2">{t(`mechanical_condition.${deal.mechanical_condition}`)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Conditions d'expertise :</Typography>
                <Typography variant="body2">{t(`conditions_expertise.${deal.conditions_expertise}`)}</Typography>
              </Grid>
              {deal.comp_infos_1 && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Infos complémentaires 1 :</Typography>
                  <Typography variant="body2">{deal.comp_infos_1}</Typography>
                </Grid>
              )}
              <Divider
                variant="fullWidth"
                component="hr"
                style={{
                  marginLeft: 24,
                  backgroundColor: '#fdd15f',
                  height: '2px',
                  width: 'calc(100% - 24px)',
                  border: 0,
                  marginTop: 15,
                }}
              />
              <Grid item xs={12} md={12}>
                <Typography variant="body1" fontSize={22}>
                  Autres informations
                </Typography>
              </Grid>
              {deal.desired_price && !isEMA && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Prix souhaité par le client :</Typography>
                  <Typography variant="body2">{priceFormatter.format(parseInt(deal.desired_price))}</Typography>
                </Grid>
              )}
              {deal.market_price && !isEMA && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Prix du marché selon vous :</Typography>
                  <Typography variant="body2">{priceFormatter.format(parseInt(deal.market_price))}</Typography>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Date de reprise :</Typography>
                <Typography variant="body2">{deal.buy_back_date.replace(/-/g, '/')}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Franchise :</Typography>
                <Typography variant="body2">{deal.franchise}</Typography>
              </Grid>
              {deal.options && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Options :</Typography>
                  <Typography variant="body2">{deal.options}</Typography>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Utilisation du véhicule :</Typography>
                <Typography variant="body2">{t(`vehicle_usage.${deal.vehicle_usage}`)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Durée de détention :</Typography>
                <Typography variant="body2">{t(`detention_duration.${deal.detention_duration}`)}</Typography>
              </Grid>
              {deal.histovec_link && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Lien Histovec :</Typography>
                  <Link target="_blanl" href={deal.histovec_link} variant="body2">
                    Cliquez ici
                  </Link>
                </Grid>
              )}
              {deal.comp_infos_2 && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Infos complémentaires Skipcar :</Typography>
                  <Typography variant="body2">{deal.comp_infos_2}</Typography>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Informations complémentaires (API) :</Typography>
                <Typography variant="body2">{deal.more_api_infos}</Typography>
              </Grid>

              <Grid item xs={12} md={6} sx={{ display: 'none' }}>
                <Typography variant="body1">URL externe :</Typography>
                <Typography variant="body2">{deal.ext_url}</Typography>
              </Grid>

              {(doubleCotation.dcMarketPrice || doubleCotation.dcDesiredPrice) && (
                <>
                  <Divider
                    variant="fullWidth"
                    component="hr"
                    style={{
                      marginLeft: 24,
                      backgroundColor: '#fdd15f',
                      height: '2px',
                      width: 'calc(100% - 24px)',
                      border: 0,
                      marginTop: 15,
                    }}
                  />
                  <Grid item xs={12} md={12}>
                    <Typography variant="body1" fontSize={22}>
                      Double Cotation
                    </Typography>
                  </Grid>
                </>
              )}
              {doubleCotation.dcMarketPrice && !isEMA && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Prix du marché</Typography>
                  <Typography variant="body2">
                    {priceFormatter.format(parseInt(doubleCotation.dcMarketPrice))}
                  </Typography>
                </Grid>
              )}
              {doubleCotation.dcDesiredPrice && !isEMA && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">Prix désiré par le client </Typography>
                  <Typography variant="body2">
                    {priceFormatter.format(parseInt(doubleCotation.dcDesiredPrice))}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
        </>
      )}

      {/* Lightbox/Dialog for photos */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent style={{ position: 'relative' }}>
          <img src={allPhotos[currentPhotoIndex] || ''} alt="Selected" style={{ width: '100%' }} />

          <Button
            onClick={handlePrev}
            disabled={allPhotos?.length <= 1}
            style={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              color: theme.palette.red.main, // Utilisation de la couleur rouge du thème
              fontSize: '4rem', // Taille de l'icône
            }}
          >
            <ArrowBackIosIcon fontSize="inherit" />
          </Button>

          <Button
            onClick={handleNext}
            disabled={allPhotos?.length <= 1}
            style={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              color: theme.palette.red.main, // Utilisation de la couleur rouge du thème
              fontSize: '4rem', // Taille de l'icône
            }}
          >
            <ArrowForwardIosIcon fontSize="inherit" />
          </Button>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default CarDealDetailsPage;
