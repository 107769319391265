// packages/frontend/src/hooks/useApiQueries.ts
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { makeAuthenticatedApiCall } from '../apiHelper';
import { MarketplaceDeal } from '../types/deal';
import { CGListData, CGStatistics } from '../types/cg';

const fetchDealExistence = async (id: number): Promise<boolean> => {
  if (id < 8718) {
    // Premier ID en base, évite de tout tester
    return false;
  }

  try {
    const response = await axios.get(`/api/deals/exists/${id}`);
    return response.data.exists;
  } catch (error) {
    console.error('Error checking deal existence:', error);
    throw error;
  }
};

export const useDealExistence = (pipedriveDealId: number) => {
  return useQuery({
    queryKey: ['dealExistence', pipedriveDealId],
    queryFn: () => fetchDealExistence(pipedriveDealId),
  });
};

const fetchSalesPersons = async (orgId: string): Promise<any[]> => {
  try {
    const response = await axios.get(`/api/pipedrive/sales-persons?orgId=${orgId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sales persons:', error);
    throw error;
  }
};

export const useSalesPersons = (orgId: string) => {
  return useQuery({
    queryKey: ['salesPersons', orgId],
    queryFn: () => fetchSalesPersons(orgId),
  });
};

const fetchOrganizationInfo = async (orgId: string) => {
  const response = await axios.get(`/api/pipedrive/organizations/${orgId}`);
  return response.data;
};

export const useOrganizationInfo = (orgId: string) => {
  return useQuery({
    queryKey: ['organizationInfo', orgId],
    queryFn: () => fetchOrganizationInfo(orgId),
  });
};

const fetchPersonInfo = async (personId: string) => {
  const response = await axios.get(`/api/pipedrive/persons/${personId}`);
  return response.data;
};

export const usePersonInfo = (orgId: string) => {
  return useQuery({
    queryKey: ['personInfo', orgId],
    queryFn: () => fetchPersonInfo(orgId),
  });
};

const fetchStages = async () => {
  try {
    const response = await axios.get('/api/pipedrive/stages');
    return response.data;
  } catch (error) {
    console.error('Error fetching stages:', error);
    throw error;
  }
};

export const useStages = () => {
  return useQuery({
    queryKey: ['stages'],
    queryFn: fetchStages,
    staleTime: 3600000, // Cache valide pour 1 heure
  });
};

const fetchPipelines = async () => {
  try {
    const response = await axios.get('/api/pipedrive/pipelines');
    return response.data;
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    throw error;
  }
};

export const usePipelines = () => {
  return useQuery({
    queryKey: ['pipelines'],
    queryFn: fetchPipelines,
    staleTime: 3600000, // Cache valide pour 1 heure
  });
};

const fetchPipedriveDealInfo = async (dealId: string) => {
  const response = await axios.get(`/api/pipedrive/deals/${dealId}`);
  return response.data;
};

export const usePipedriveDealInfo = (dealId: string) => {
  return useQuery({
    queryKey: ['usePipedriveDealInfo', dealId],
    queryFn: () => fetchPipedriveDealInfo(dealId),
  });
};

const fetchDatabaseDealInfo = async (dealId: number, make: string, fuel: string) => {
  if (dealId < 8718) {
    // Premier ID en base, évite de tout tester
    return false;
  }
  const response = await axios.get(`/api/deals/${dealId}`, {
    params: {
      make,
      fuel,
    },
  });
  return response.data;
};

export const useDatabaseDealInfo = (dealId: number, make: string, fuel: string) => {
  return useQuery({
    queryKey: ['useDatabaseDealInfo', dealId],
    queryFn: () => fetchDatabaseDealInfo(dealId, make, fuel),
  });
};

const fetchDatabaseDealInfoAuth = async (dealId: number) => {
  if (dealId >= 8929) {
    const response = await makeAuthenticatedApiCall('get', `/api/deals/${dealId}/auth`);
    return response;
  }
  return '';
};

export const useDatabaseDealInfoAuth = (dealId: number) => {
  return useQuery({
    queryKey: ['useDatabaseDealInfoAuth', dealId],
    queryFn: () => fetchDatabaseDealInfoAuth(dealId),
  });
};

export const useDatabaseDeals = (orgId?: number | null) => {
  return useQuery({
    queryKey: ['useDatabaseDeals', orgId],
    queryFn: () => fetchDatabaseDeals(orgId),
  });
};

const fetchDatabaseDeals = async (orgId?: number | null) => {
  const baseEndpoint = `/api/deals`;
  const endpoint = orgId ? `${baseEndpoint}?orgId=${orgId}` : baseEndpoint;
  const response = await makeAuthenticatedApiCall('get', endpoint);
  return response;
};

const fetchMarketplaceDeals = async () => {
  try {
    const response = await makeAuthenticatedApiCall<readonly MarketplaceDeal[]>('get', '/api/deals/marketplace');
    return response;
  } catch (error) {
    // Gérer l'erreur selon les besoins
    throw new Error('Erreur lors de la récupération des marketplace deals');
  }
};

export const useMarketplaceDeals = () => {
  return useQuery({
    queryKey: ['useMarketplaceDeals'],
    queryFn: fetchMarketplaceDeals,
  });
};

const fetchArbitratorDeals = async (status?: string, limit?: number, orderByDateField?: boolean) => {
  const params = new URLSearchParams();
  if (status) params.append('status', status);
  if (limit) params.append('limit', limit.toString());
  if (orderByDateField) params.append('orderByDateField', orderByDateField.toString());

  const url = `/api/deals/arbitrator?${params.toString()}`;

  const response = await makeAuthenticatedApiCall('get', url);
  return response;
};

export const useArbitratorDeals = (status?: string, limit?: number, orderByDateField?: boolean) => {
  return useQuery({
    queryKey: ['useArbitratorDeals', status, limit, orderByDateField], // Inclure status, limit, et orderByDateField dans la clé de requête
    queryFn: () => fetchArbitratorDeals(status, limit, orderByDateField), // Passer status, limit, et orderByDateField à la fonction fetch
    refetchOnWindowFocus: false,
  });
};

const fetchArbitratorOffersForAgency = async (orgId: number) => {
  try {
    const response = await makeAuthenticatedApiCall('get', `/api/deals/arbitrator/${orgId}`);
    return response;
  } catch (error) {
    // Gérer l'erreur selon les besoins
    throw new Error('Erreur lors de la récupération des offres d’arbitrage pour l’agence');
  }
};

export const useArbitratorOffersForAgency = (orgId: number) => {
  return useQuery({
    queryKey: ['useArbitratorOffersForAgency', orgId],
    queryFn: () => fetchArbitratorOffersForAgency(orgId),
    enabled: !!orgId,
  });
};

const fetchArbitrationDeal = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/arbitration/${dealId}`);
  return response;
};

export const useArbitrationDeal = (dealId: number, shouldFetch: boolean = true) => {
  return useQuery({
    queryKey: ['useArbitrationDeal', dealId],
    queryFn: () => fetchArbitrationDeal(dealId),
    enabled: shouldFetch, // Exécute la requête uniquement si shouldFetch est true
    refetchOnWindowFocus: false,
    staleTime: 2000,
  });
};

export const useDealMisivA = (dealId: number) => {
  return useQuery({
    queryKey: ['useDealMisivA', dealId],
    queryFn: () => fetchDealMisivA(dealId),
  });
};
const fetchDealMisivA = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deals/a/${dealId}`);
  return response;
};

export const useDealMisivB = (dealId: number) => {
  return useQuery({
    queryKey: ['useDealMisivB', dealId],
    queryFn: () => fetchDealMisivB(dealId),
  });
};
const fetchDealMisivB = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deals/b/${dealId}`);
  return response;
};

export const useDealMisivC = (dealId: number) => {
  return useQuery({
    queryKey: ['useDealMisivC', dealId],
    queryFn: () => fetchDealMisivC(dealId),
  });
};
const fetchDealMisivC = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deals/c/${dealId}`);
  return response;
};

export const useDealMisivE = (dealId: number) => {
  return useQuery({
    queryKey: ['useDealMisivE', dealId],
    queryFn: () => fetchDealMisivE(dealId),
  });
};
const fetchDealMisivE = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deals/e/${dealId}`);
  return response;
};
export const useDealMisivF = (dealId: number) => {
  return useQuery({
    queryKey: ['useDealMisivF', dealId],
    queryFn: () => fetchDealMisivF(dealId),
  });
};
const fetchDealMisivF = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deals/f/${dealId}`);
  return response;
};

const fetchDepositSaleBuyer = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deposit-sale-buyer/${dealId}`);
  return response.data;
};

export const useDepositSaleBuyer = (dealId: number) => {
  return useQuery({
    queryKey: ['useDepositSaleBuyer', dealId],
    queryFn: () => fetchDepositSaleBuyer(dealId),
  });
};

const fetchCGList = async (
  userPipedrive: number,
  userRole: string,
  page: number,
  limit: number,
  searchQuery: string,
  stageId?: number | null,
  sortColumn: string = 'R.RegistrationID',
  sortOrder: string = 'DESC',
): Promise<CGListData> => {
  const stageIdParam = stageId ? `&stage_id=${stageId}` : '';
  const response = await makeAuthenticatedApiCall<CGListData>(
    'get',
    `/api/cg/?org_id=${userPipedrive}&user_role=${userRole}&page=${page}&limit=${limit}&search=${searchQuery}${stageIdParam}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
  );
  return response.data;
};

export const useCGList = (
  userPipedrive: number,
  userRole: string,
  page: number,
  limit: number,
  searchQuery: string,
  stageId?: number | null,
  sortColumn: string = 'R.RegistrationID',
  sortOrder: string = 'DESC',
) => {
  return useQuery<CGListData>({
    queryKey: ['useCGList', userPipedrive, userRole, page, limit, searchQuery, stageId, sortColumn, sortOrder],
    queryFn: () => fetchCGList(userPipedrive, userRole, page, limit, searchQuery, stageId, sortColumn, sortOrder),
    staleTime: 0,
    refetchOnWindowFocus: true,
  });
};

export const useCGData = (registrationId: string) => {
  return useQuery({
    queryKey: ['useCGData'],
    queryFn: () => fetchCGData(registrationId),
  });
};

const fetchCGData = async (registrationId: string) => {
  const response = await makeAuthenticatedApiCall('get', `/api/cg/${registrationId}`);
  return response.data;
};

export const useDealsByIds = (ids: number[]) => {
  return useQuery({
    queryKey: ['useDealsByIds'],
    queryFn: () => fetchDealsByIds(ids),
  });
};

const fetchDealsByIds = async (ids: number[]) => {
  const response = await makeAuthenticatedApiCall('post', '/api/deals-by-ids', { ids });
  return response.data;
};

/*
export const useDealQonto = (dealId: number, pipelineLetter: string) => {
  return useQuery({
    queryKey: ['useDealQonto', dealId, pipelineLetter],
    queryFn: () => fetchDealQonto(dealId, pipelineLetter),
    staleTime: 0,
  });
};

const fetchDealQonto = async (dealId: number, pipelineLetter: string) => {
  const response = await makeAuthenticatedApiCall('get', `/api/qonto/deals/${pipelineLetter}/${dealId}`);
  return response?.data;
};

*/

export const useCGStatistics = () => {
  return useQuery({
    queryKey: ['useCGStatistics'],
    queryFn: () => fetchCGStatistics(),
  });
};

const fetchCGStatistics = async () => {
  const response = await makeAuthenticatedApiCall<CGStatistics>('get', `/api/cg/statistics`);
  return response.data;
};
