import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  CircularProgress,
  TextField,
  Grid,
  Box,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import theme from '../../theme';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import { formatSimpleDateToDDMMYYYYWithTime } from '../../utils';

interface MandateCalculatorFormValues {
  purchasePrice: string;
  commission: string;
  mandateExtension: number;
  netMargin: string;
  commissionMandateExtension: string;
  finalSalePrice: string;
  finalPrice: string;
  netMarginExtension: string;
}

const commissionTable = [
  { min: 1, max: 4999, commission: 580 },
  { min: 5000, max: 7999, commission: 820 },
  { min: 8000, max: 11999, commission: 880 },
  { min: 12000, max: 19999, commission: 940 },
  { min: 20000, max: 29999, commission: 1120 },
  { min: 30000, max: 39999, commission: 1300 },
  { min: 40000, max: 49999, commission: 1540 },
  { min: 50000, max: 59999, commission: 1900 },
  { min: 60000, max: 69999, commission: 2140 },
  { min: 70000, max: 79999, commission: 2320 },
  { min: 80000, max: 89999, commission: 2500 },
  { min: 90000, max: 99999, commission: 2740 },
  { min: 100000, max: 124999, commission: 3100 },
  { min: 125000, max: 149999, commission: 4300 },
  { min: 150000, max: 200000, commission: 4900 },
];

const getDefaultCommission = (purchasePrice: any) => {
  const price = parseFloat(purchasePrice.replace(/\s/g, ''));
  const matchingRange = commissionTable.find((range) => price >= range.min && price <= range.max);
  return matchingRange ? matchingRange.commission : 0;
};

export const MandateCalculator: React.FC<{ dealId: number; purchasePriceDB: number }> = ({
  dealId,
  purchasePriceDB,
}) => {
  const [commissionModified, setCommissionModified] = useState<boolean>(false); // État pour suivre les modifications manuelles dans le champ de commission
  const [lastUpdate, setLastUpdate] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { register, watch, setValue, getValues } = useForm<MandateCalculatorFormValues>({
    defaultValues: {
      purchasePrice: purchasePriceDB.toString(),
      commission: '',
      mandateExtension: 1,
      netMargin: '',
      commissionMandateExtension: '',
      finalSalePrice: '',
      finalPrice: '',
      netMarginExtension: '',
    },
  });

  const watchFields = watch();
  const watchPurchasePrice = watch('purchasePrice');
  const watchMandateExtension = watch('mandateExtension');

  useEffect(() => {
    // Supposons que cette fonction fasse une requête pour récupérer les données existantes
    const fetchExistingData = async () => {
      setIsLoading(true); // Commencer le chargement
      try {
        const response = await makeAuthenticatedApiCall('get', `/api/arbitration/mandate-calculator/${dealId}`);
        if (response.data) {
          const data = response.data;
          // Affectation des valeurs pour tous les champs pertinents
          setValue('purchasePrice', data.purchase_price ? data.purchase_price.toString() : '');
          setValue('commission', data.comission ? data.comission.toString() : '');
          setValue('finalPrice', data.final_price ? data.final_price.toString() : '');
          setValue('netMargin', data.net_margin ? data.net_margin.toString() : '');
          setValue('mandateExtension', parseFloat(data.extension));
          setValue('commissionMandateExtension', data.comission_ext ? data.comission_ext.toString() : '');
          setValue('finalSalePrice', data.selling_final_price ? data.selling_final_price.toString() : '');
          setValue('netMarginExtension', data.net_margin_extension ? data.net_margin_extension.toString() : '');
          setLastUpdate(data.creation_date);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch existing data', error);
        setIsLoading(false);
      }
    };

    fetchExistingData();
  }, [dealId, setValue]);

  const defaultCommission = useMemo(() => getDefaultCommission(watchFields.purchasePrice), [watchFields.purchasePrice]);

  useEffect(() => {
    // Mettre à jour la commission dès que watchFields.purchasePrice change ou au premier rendu
    if (!commissionModified && !watchFields.commission) {
      // Appliquer la commission par défaut seulement si elle n'a pas été modifiée et si aucune commission n'est définie
      setValue('commission', defaultCommission.toString());
    }

    const calculateFinalValues = () => {
      const purchasePrice = parseFloat(watchFields.purchasePrice.replace(/\s+/g, '') || '0');
      const commission = parseFloat(watchFields.commission.replace(/\s+/g, '') || '0');
      const mandateExtension = watchFields.mandateExtension;
      const commissionMandateExtension = commission * mandateExtension;

      // Calcul des valeurs finales
      const finalSalePrice = purchasePrice + commissionMandateExtension;
      const finalPrice = purchasePrice + commission;
      const netMargin = (purchasePrice * 0.2).toFixed(0); // Vérifiez la formule exacte avec celle de l'API
      const netMarginExtensionMandate = (purchasePrice * 0.2 * mandateExtension).toFixed(0); // Vérifiez la formule exacte avec celle de l'API

      // Mise à jour des valeurs dans le formulaire
      setValue('finalSalePrice', finalSalePrice.toString());
      setValue('finalPrice', finalPrice.toString());
      setValue('netMargin', netMargin.toString());
      setValue('commissionMandateExtension', commissionMandateExtension.toString());
      setValue('netMarginExtension', netMarginExtensionMandate.toString());
    };

    // Appeler la fonction pour calculer les valeurs finales
    calculateFinalValues();
  }, [defaultCommission, watchFields.commission, watchFields.mandateExtension, setValue, commissionModified]);

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'commission') {
      setCommissionModified(true); // Marquer la commission comme modifiée manuellement
    }
    const cleanedValue = value.replace(/\D+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    setValue(name as keyof MandateCalculatorFormValues, cleanedValue, { shouldValidate: true });
  };

  const handleSave = async () => {
    const formData = getValues(); // Récupère les valeurs du formulaire

    try {
      const response = await makeAuthenticatedApiCall('post', `/api/arbitration/mandate-calculator`, {
        dealId,
        formData,
      });
      // setButtonDisabled(true);

      const now = new Date();
      const isoDateString = now.toISOString();
      setLastUpdate(isoDateString);
    } catch (error) {
      console.error('Failed to save data', error);
    }
  };

  // Fonction pour gérer le changement de l'extension de mandat
  const handleMandateExtensionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('mandateExtension', parseFloat(event.target.value));
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box padding={3} display={'flex'}>
      <Grid container xs={6} item spacing={2} display={'flex'} flexDirection={'row'}>
        <Grid item xs={12}>
          <TextField
            {...register('purchasePrice')}
            label="Prix d'achat"
            variant="outlined"
            className="inputElement"
            onChange={handleNumberChange}
            value={getValues('purchasePrice')}
            InputProps={{
              endAdornment: (
                <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                  €
                </Typography>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('commission')}
            label="Commission"
            variant="outlined"
            className="inputElement"
            onChange={handleNumberChange}
            value={getValues('commission')}
            InputProps={{
              endAdornment: (
                <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                  €
                </Typography>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Prix Final"
            {...register('finalPrice')}
            variant="outlined"
            className="inputElement"
            sx={{ backgroundColor: '#eeeeee' }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                  €
                </Typography>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('netMargin')}
            label="Marge nette"
            variant="outlined"
            className="inputElement"
            value={watchFields['netMargin']}
            sx={{ backgroundColor: '#eeeeee' }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                  €
                </Typography>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={6} spacing={2} display={'flex'} flexDirection={'row'}>
        <Grid item xs={8}>
          <RadioGroup
            row
            aria-labelledby="mandate-extension-label"
            name="mandateExtension"
            value={watch('mandateExtension')}
            onChange={handleMandateExtensionChange}
            className="inputElement"
          >
            <FormControlLabel value={1} control={<Radio />} label="Pas d'extension" />
            <FormControlLabel value={1.2} control={<Radio />} label="Extension 20%" />
            <FormControlLabel value={1.5} control={<Radio />} label="Extension 50%" />
            <FormControlLabel value={2} control={<Radio />} label="Extension 100%" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Commission Extension de Mandat"
            {...register('commissionMandateExtension')}
            sx={{ backgroundColor: '#eeeeee' }}
            variant="outlined"
            className="inputElement"
            value={getValues('commissionMandateExtension')}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                  €
                </Typography>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Prix Final de Vente"
            {...register('finalSalePrice')}
            sx={{ backgroundColor: '#eeeeee' }}
            variant="outlined"
            className="inputElement"
            value={getValues('finalSalePrice')}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                  €
                </Typography>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Marge Nette Extension de Mandat"
            variant="outlined"
            className="inputElement"
            value={getValues('netMarginExtension')}
            sx={{ backgroundColor: '#eeeeee' }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                  €
                </Typography>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{
              fontWeight: 900,
              height: 55,
              textAlign: 'center',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={buttonDisabled}
          >
            Sauvegarder
          </Button>
          {lastUpdate && (
            <Grid sx={{ paddingTop: '0 !important' }} item xs={12} md={12}>
              <Typography variant="body2">
                Dernière mise à jour : {formatSimpleDateToDDMMYYYYWithTime(lastUpdate)}
                {/*' par '}
                        {arbitrationDealInfo?.data?.last_user*/}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
