// packages/frontend/src/stores/FromExternalStore.ts
import create from 'zustand';

interface FromExternalState {
  fromExternal: boolean;
  setFromExternal: (value: boolean) => void;
}

const useFromExternalStore = create<FromExternalState>((set) => ({
  fromExternal: false,
  setFromExternal: (value) => set({ fromExternal: value }),
}));

export default useFromExternalStore;
