import CompaniesList from "../components/CompaniesList";

const CompaniesListPage = () => {
  return(
    <div>
      <h1>Liste des Sociétés / Agences</h1>
      <p>This is the content for the Companies List page.</p>
      <CompaniesList/>
    </div>
  )
}

export default CompaniesListPage;