import { Container } from '@mui/material';
import DealsList from '../components/DealsList';
import { useParams } from 'react-router-dom';

const DealsListPage = () => {
  const { agencyId } = useParams<{ agencyId?: string }>();
  return (
    <Container maxWidth={false}>
      <DealsList agencyId={agencyId} />
    </Container>
  );
};

export default DealsListPage;
