import { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import FirebaseUploadComponent from '../../CreateCotation/FirebaseUploadComponent';
import { makeAuthenticatedApiCall } from '../../../apiHelper';
import theme from '../../../theme';

interface UploadModalProps {
  dealId: number;
  photos: Record<string, string[]>;
  extUrl?: string;
  regPlate?: string;
}

const UploadModalDepositSale: React.FC<UploadModalProps> = ({ dealId, photos, extUrl, regPlate }) => {
  const [open, setOpen] = useState(false);
  const [uploadedUrls, setUploadedUrls] = useState<string[]>(photos?.signedPurchaseDeclarationDepositSale || []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadComplete = (url: string) => {
    setUploadedUrls((prevUrls) => [...prevUrls, url]);
  };

  const handleDeleteComplete = (url: string) => {
    setUploadedUrls((prevUrls) => prevUrls.filter((existingUrl) => existingUrl !== url));
  };

  const handleValidation = async () => {
    try {
      const response = await makeAuthenticatedApiCall(
        'post',
        `/api/deals/${dealId}/update-car-deal-procurement-file-deposit-sale`,
        {
          photos: uploadedUrls,
        },
      );
      console.log(response.data);

      // Si au moins une photo est présente, on envoie le mail de notif
      if (uploadedUrls) {
        await makeAuthenticatedApiCall('post', `/api/mails/procurement-file-admin-notification`, {
          dealId: dealId,
          extUrl: extUrl,
          regPlate: regPlate,
          isMandate: false,
          isDepositSale: true,
        });
      }

      handleClose();
    } catch (error) {
      console.error('Erreur lors de la mise à jour des photos', error);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        className="uploadModalButton"
        sx={{
          backgroundColor: theme.palette.saffron.main,
          '&:hover': { backgroundColor: theme.palette.saffron.main },
        }}
      >
        📤 Dossier de vente Skipcar {uploadedUrls?.length > 0 ? `(${uploadedUrls?.length})` : ''}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <DialogTitle sx={{ paddingLeft: 0 }}>Déclaration d'achat signée</DialogTitle>
          <FirebaseUploadComponent
            onDeleteComplete={handleDeleteComplete}
            onUploadComplete={handleUploadComplete}
            uploadedUrls={uploadedUrls}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleValidation} variant="contained" color="primary">
            Valider les fichiers
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadModalDepositSale;
