import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CircularProgress, TextField, Grid, Box, Typography, Switch, FormControlLabel, Button } from '@mui/material';
import theme from '../../theme';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import { formatSimpleDateToDDMMYYYYWithTime } from '../../utils';

interface PurchaseCalculatorFormValues {
  marketPrice: string;
  netMargin: string;
  mandate: string;
  estimatedFRE: string;
  guarantee: string;
  proposedPurchasePrice: string;
}

const fieldLabels: {
  [key in keyof Omit<PurchaseCalculatorFormValues, 'proposedPurchasePrice'>]: string;
} = {
  marketPrice: 'Prix du marché',
  netMargin: 'Marge visée',
  mandate: 'Mandat',
  guarantee: 'Garantie',
  estimatedFRE: 'FRE Estimé',
};

export const PurchaseCalculator: React.FC<{ dealId: number }> = ({ dealId }) => {
  const [autoCalculate, setAutoCalculate] = useState(true);
  const [lastUpdate, setLastUpdate] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { register, watch, setValue, getValues } = useForm<PurchaseCalculatorFormValues>({
    defaultValues: {
      marketPrice: '',
      netMargin: '',
      mandate: '990',
      guarantee: '401',
      estimatedFRE: '',
      proposedPurchasePrice: '0',
    },
  });

  const watchFields = watch();

  useEffect(() => {
    const fetchExistingData = async () => {
      setIsLoading(true); // Commencer le chargement
      try {
        const response = await makeAuthenticatedApiCall('get', `/api/arbitration/purchase-calculator/${dealId}`);
        if (response.data) {
          setAutoCalculate(false);
        }
        const data = response.data;

        // Mise à jour des valeurs sans formatage local, pour éviter les problèmes de conversion
        setValue('marketPrice', data.market_price?.toString() ?? '');
        setValue('mandate', data.mandate?.toString() ?? '990'); // '990' comme valeur par défaut si non défini
        setValue('guarantee', data.guarantee?.toString() ?? '401'); // '401' comme valeur par défaut si non défini
        setValue('estimatedFRE', data.estimated_fre?.toString() ?? '');
        setValue('proposedPurchasePrice', data.proposed_purchase_price?.toString() ?? '0');
        setValue('netMargin', data.net_margin?.toString() ?? '');
        setLastUpdate(data.creation_date ?? '');
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch existing data', error);
        setIsLoading(false);
      }
    };

    fetchExistingData();
  }, [dealId, setValue]);

  const proposedPurchasePrice = useMemo(() => {
    // Extrait et transforme les valeurs observées
    const marketPrice = parseFloat(watchFields.marketPrice.replace(/\s/g, '') || '0');
    const netMargin = parseFloat(watchFields.netMargin.replace(/\s/g, '') || '0');
    const mandate = parseFloat(watchFields.mandate.replace(/\s/g, '') || '0');
    const guarantee = parseFloat(watchFields.guarantee.replace(/\s/g, '') || '0');
    const estimatedFRE = parseFloat(watchFields.estimatedFRE.replace(/\s/g, '') || '0');

    // Retourne le calcul seulement si autoCalculate est actif
    return autoCalculate ? marketPrice - netMargin - mandate - guarantee - estimatedFRE : null;
  }, [watchFields, autoCalculate]);

  useEffect(() => {
    // Appliquer le résultat du useMemo au champ approprié, éviter de le faire si le calcul n'a pas eu lieu
    if (proposedPurchasePrice !== null) {
      setValue('proposedPurchasePrice', proposedPurchasePrice.toString());
    }
  }, [proposedPurchasePrice, setValue]);

  const handleAutoCalculateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoCalculate(event.target.checked);
  };

  const handleNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const cleanedValue = value.replace(/\D+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      setValue(name as keyof PurchaseCalculatorFormValues, cleanedValue, { shouldValidate: true });
    },
    [setValue],
  );

  const handleSave = async () => {
    const formData = getValues(); // Récupère les valeurs du formulaire

    try {
      const response = await makeAuthenticatedApiCall('post', `/api/arbitration/purchase-calculator`, {
        dealId,
        formData,
      });

      setButtonDisabled(true);

      const now = new Date();
      const isoDateString = now.toISOString();
      setLastUpdate(isoDateString);
    } catch (error) {
      console.error('Failed to save data', error);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box padding={3} display={'flex'}>
      <Grid container xs={6} item spacing={2} display={'flex'} flexDirection={'row'}>
        {Object.keys(fieldLabels).map((field) => (
          <Grid item xs={12} key={field}>
            <TextField
              {...register(field as keyof PurchaseCalculatorFormValues)}
              label={
                fieldLabels[field as keyof Omit<PurchaseCalculatorFormValues, 'netMargin' | 'proposedPurchasePrice'>]
              }
              className="inputElement"
              variant="outlined"
              value={watchFields[field as keyof PurchaseCalculatorFormValues]}
              onChange={handleNumberChange}
              InputProps={{
                endAdornment: <Typography variant="body2">€</Typography>,
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container item xs={6} spacing={2} display={'flex'} flexDirection={'row'}>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={autoCalculate} onChange={handleAutoCalculateChange} />}
            label="Calcul automatique du Prix d'achat à proposer"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('proposedPurchasePrice')}
            label="Prix d'achat à proposer"
            variant="outlined"
            className="inputElement"
            onChange={handleNumberChange}
            value={getValues('proposedPurchasePrice')}
            InputProps={{
              endAdornment: (
                <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                  €
                </Typography>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{
              fontWeight: 900,
              height: 55,
              textAlign: 'center',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={buttonDisabled}
          >
            Sauvegarder
          </Button>
          {lastUpdate && (
            <Grid sx={{ paddingTop: '0 !important' }} item xs={12} md={12}>
              <Typography variant="body2">
                Dernière mise à jour : {formatSimpleDateToDDMMYYYYWithTime(lastUpdate)}
                {/*' par '}
                        {arbitrationDealInfo?.data?.last_user*/}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
