import { useState, useEffect } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  Box,
  Grid,
  Container,
  Tab,
  Tabs,
  Button,
} from '@mui/material';
import { useArbitratorDeals, useDatabaseDeals } from '../hooks/useApiQueries';
import { DBDeal, ArbitratorDealsListProps } from '../types/deal';
import ArbitratorDeal from './ArbitratorDealsList/ArbitratorDeal';
import { useAuth } from './AuthContext';
import theme from '../theme';
import axios from 'axios';
import { makeAuthenticatedApiCall } from '../apiHelper';

const ArbitratorDealsList: React.FC<ArbitratorDealsListProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tabValue, setTabValue] = useState(0);
  const [filteredDeals, setFilteredDeals] = useState<DBDeal[]>([]);
  const [dealsInfo, setDealsInfo] = useState({ treaded: 0, toBeTreated: 0, awaitingSupervision: 0 });

  const { currentUser } = useAuth();
  const userRole = currentUser?.data?.data?.role;
  const userEmail = currentUser?.email;
  const {
    data: arbitratorDeals,
    isLoading: arbitratorDealsIsLoading,
    error,
    refetch: refetchArbitratorDeals,
  } = useArbitratorDeals(tabValue === 0 ? undefined : tabValue.toString(), tabValue === 0 ? 75 : undefined, true);

  const { data: dealsFromDB, refetch: refetchUseDatabaseDeals } = useDatabaseDeals();

  const dealsMap: Map<number, DBDeal> = new Map();

  if (dealsFromDB && Array.isArray(dealsFromDB.data)) {
    dealsFromDB.data.forEach((deal: DBDeal) => {
      if (deal.pipedrive_deal_id !== null) {
        dealsMap.set(deal.pipedrive_deal_id, deal);
      }
    });
  }

  useEffect(() => {
    setIsLoading(true);
    if (Array.isArray(arbitratorDeals?.data) && arbitratorDeals?.data.length > 0 && !arbitratorDealsIsLoading) {
      setFilteredDeals(arbitratorDeals?.data);
    } else {
      setFilteredDeals([]);
    }
    setIsLoading(false);
  }, [arbitratorDeals, tabValue]);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const [toBeTreatedResponse, awaitingSupervisionResponse, treatedResponse] = await Promise.all([
          makeAuthenticatedApiCall('get', '/api/arbitration/count/to-be-treated'),
          makeAuthenticatedApiCall('get', '/api/arbitration/count/awaiting-supervision'),
          makeAuthenticatedApiCall('get', '/api/arbitration/count/treated'),
        ]);

        setDealsInfo({
          treaded: treatedResponse.data as number,
          toBeTreated: toBeTreatedResponse.data as number,
          awaitingSupervision: awaitingSupervisionResponse.data as number,
        });
      } catch (error) {
        console.error('Error fetching deal counts:', error);
      }
    };
    fetchCounts();
  }, []);

  const handleTabChange = async (_: React.SyntheticEvent, newValue: any) => {
    setTabValue(newValue);
  };

  if (error) {
    return <div>Erreur lors du chargement des données</div>; // Ou une gestion plus avancée des erreurs
  }

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/deals/synchronize');
      console.log('Synchronization response:', response.data);
      await refetchUseDatabaseDeals();
      await refetchArbitratorDeals();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Grid container item xs={12} sm={12} justifyContent="center">
          <Button
            type="button"
            variant="contained"
            sx={{
              fontWeight: 900,
              height: 55,
              margin: '16px auto',
              textAlign: 'center',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            onClick={handleRefresh}
          >
            🔄 Rafraichir la liste
          </Button>
        </Grid>
      </Box>
      <Container maxWidth={false}>
        <Paper sx={{ marginTop: 5, padding: 5 }} elevation={3}>
          <Grid container item xs={12} sm={12} justifyContent="space-between">
            <Grid item xs={12} sm={8}>
              <Grid mt={2} display={'flex'} alignItems={'center'}>
                <Box>
                  <Typography variant="body2">
                    🔄{'  '}Deals à traiter : {dealsInfo.toBeTreated}
                  </Typography>
                  <Typography variant="body2">
                    ✅{'  '}Deals traités : {dealsInfo.treaded}
                  </Typography>

                  {userRole === 'god' && (
                    <Typography variant="body2">
                      👀 Nombre de deals en attente de supervision : {dealsInfo.awaitingSupervision}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid mt={2} display={'flex'} alignItems={'center'}>
                <Typography variant="body2">Charte Skipcar :</Typography>{' '}
                <Box style={{ marginLeft: 10, height: 10, width: 15, backgroundColor: 'green' }}></Box>
              </Grid>
              <Grid display={'flex'} alignItems={'center'}>
                <Typography variant="body2">Hors-charte : </Typography>
                <Box style={{ marginLeft: 10, height: 10, width: 15, backgroundColor: 'red' }}></Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper sx={{ marginTop: 5, padding: 0 }} elevation={3}>
          <Tabs value={tabValue} onChange={handleTabChange} centered className="DealsListTabs">
            <Tab label="Tous" value={0} />
            <Tab label="À Traiter" value={'to_be_processed'} />
            <Tab label="En attente de supervision" value={'awaiting_supervision'} />
            <Tab label="Traités" value={'processed'} />
            <Tab label="Arbitrages Refusés" value={'refused'} />
            <Tab label="Offres acceptées" value={'accepted'} />
          </Tabs>
          {isLoading ? (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Typography sx={{ margin: '1em auto 2em' }} variant="body1">
                Chargement
              </Typography>
              <CircularProgress size={70} sx={{ margin: '1em auto 2em' }} />
            </Grid>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ background: '#262626' }}>
                    <TableCell sx={{ fontWeight: 'bold', minWidth: 90, color: 'white' }}>État</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Date</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white', minWidth: 90 }}>Immat</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Agence</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Marque / Modèle</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Kilométrage</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>MEC</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white', minWidth: 90 }}>Prix</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white', minWidth: 130, textAlign: 'center' }}>
                      Infos
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>ID</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredDeals?.length > 0 ? (
                    filteredDeals.map((deal: DBDeal, index: number) => {
                      const dealId = deal.pipedrive_deal_id ?? -1;
                      const dealDataInDB = dealsMap.get(dealId);

                      if (dealId !== -1) {
                        return (
                          <ArbitratorDeal
                            index={index}
                            key={deal.pipedrive_deal_id}
                            row={deal}
                            userRole={userRole}
                            userEmail={userEmail}
                            refetchArbitratorDeals={refetchArbitratorDeals}
                            dealData={dealDataInDB}
                          />
                        );
                      }
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <Typography>Aucun deal à traiter ! Bravo</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ArbitratorDealsList;
