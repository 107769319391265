import { Typography } from '@mui/material';
import { FC, useState, useRef } from 'react';

interface DropzoneProps {
  onFilesAdded: (files: File[]) => void;
}

const Dropzone: FC<DropzoneProps> = ({ onFilesAdded }) => {
  const [highlight, setHighlight] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFilesAddedWrapper = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files && evt.target.files.length) {
      const filesArray = Array.from(evt.target.files);
      onFilesAdded(filesArray);
    }
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setHighlight(true);
  };

  const onDragLeave = () => {
    setHighlight(false);
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (onFilesAdded) {
      const filesArray = Array.from(files);
      onFilesAdded(filesArray);
    }
    setHighlight(false);
  };

  return (
    <div
      onClick={openFileDialog}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      style={{
        border: '2px dashed lightgray',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: highlight ? 'rgba(255, 204, 0, 0.5)' : 'transparent',
      }}
    >
      <input ref={fileInputRef} type="file" multiple onChange={onFilesAddedWrapper} style={{ display: 'none' }} />
      <Typography variant="body2" className="discretText">
        Glissez et déposez vos fichiers ici, ou cliquez pour sélectionner des fichiers
      </Typography>
    </div>
  );
};

export default Dropzone;
