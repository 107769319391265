import React, { useState } from 'react';
import { Grid, Typography, Popover } from '@mui/material';

const OfferLegends = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openedPopoverId, setOpenedPopoverId] = useState<string | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const offerDetails = {
    '30-jours': {
      emoji: '⏰',
      title: 'Mandat 30 jours',
      description: `Offre Mandat 30 jours : Portage de stock. SKIPCAR porte le stock pendant 30 jours. Si le véhicule se vend, SKIPCAR revend le véhicule à l’agence sinon, SKIPCAR rapatrie le véhicule ou le met en dépôt-vente,
+ L'agence pilote la vente (fixe le prix de vente assurant ainsi le contrôle total de sa marge ; assure les frais de remise en état et la garantie)
+ Aucun frais Skipcar, si le véhicule n’est pas vendu durant les 30 jours.`,
    },
    'reprise-seche': {
      emoji: '⚡',
      title: 'Reprise sèche',
      description: `SKIPCAR achète le véhicule et assure le rapatriement à ses frais. Permet de proposer une reprise pour faire une vente sans s'occuper de la vente du véhicule repris (souvent utilisé pour hors charte ou moins attractif) Commission d’apporteur d’affaires possible à l’entrée.`,
    },
    'depot-vente': {
      emoji: '🏪',
      title: 'Dépôt-Vente',
      description: `Acheteur et vendeur, SKIPCAR offre un mandat d’intermédiation de 990€ TTC à l’agence pour commercialiser le véhicule sans en devenir titulaire. SKIPCAR assume la garantie légale de conformité, les frais de remise en état et positionne une garantie 12 mois (si aucune extension de garantie n’a été vendue).`,
    },
  };

  return (
    <Grid item xs={12} sm={4}>
      <Typography mb={2}>Légende des types d'offres</Typography>
      {Object.entries(offerDetails).map(([key, value]) => (
        <React.Fragment key={key}>
          <div onClick={(e) => handleClick(e, key)}>
            <Typography variant="h6" fontSize={16} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              {value.emoji} {value.title} <sup>💡</sup>
            </Typography>
          </div>
          <Popover
            id={id}
            open={openedPopoverId === key}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              '& .MuiPopover-paper': {
                maxWidth: '500px',
                border: '1px solid #ccc',
                backgroundColor: 'white',
                padding: '20px',
              },
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {value.title}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {value.description}
            </Typography>
          </Popover>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default OfferLegends;
