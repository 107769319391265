// packages/frontend/src/main.tsx
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import ErrorPage from './pages/ErrorPage';
import DealsListPage from './pages/DealsListPage';
import CompaniesListPage from './pages/CompaniesListPage';
import DealsByOrganizationPage from './pages/DealsByOrganizationPage';
import ArbitratorDealsList from './components/ArbitratorDealsList';
import CGForm from './components/CGForm';
import HomePage from './pages/HomePage';
import AuthPage from './pages/AuthPage';
import PasswordResetPage from './pages/PasswordResetPage';
import CreateCotation from './pages/CreateCotationPage';
import CarDealDetailsPage from './pages/CarDealDetailsPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './components/AuthContext';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './index.css';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import CGList from './components/CGList';
import FirebaseAccountList from './components/FirebaseAccountList';

// Configurer l'intercepteur Axios
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log('interceptor : avant de rentrer dans la condition où on refresh le token');
    if (error?.response?.status === 401 && !originalRequest._retry) {
      console.log('interceptor : on refresh le token');
      originalRequest._retry = true;
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  },
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 500,
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: (
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        ),
      },
      {
        path: '/login',
        element: <AuthPage />,
      },
      {
        path: '/password-reset',
        element: <PasswordResetPage />,
      },
      {
        path: 'deals-list',
        element: (
          <PrivateRoute>
            <DealsListPage key="deals-list" />
          </PrivateRoute>
        ),
      },
      {
        path: 'arbitrator',
        element: (
          <PrivateRoute>
            <ArbitratorDealsList key="arbitrator-list" />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts',
        element: (
          <PrivateRoute>
            <FirebaseAccountList />
          </PrivateRoute>
        ),
      },
      {
        path: 'deals-list/agency/:agencyId',
        element: (
          <PrivateRoute>
            <DealsListPage key="deals-list-agency" />
          </PrivateRoute>
        ),
      },
      {
        path: 'companies-list',
        element: (
          <PrivateRoute>
            <CompaniesListPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'deals-by-organization/:org_id',
        element: (
          <PrivateRoute>
            <DealsByOrganizationPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'car-deal/:id',
        element: <CarDealDetailsPage />,
      },
      {
        path: 'cotation',
        element: (
          <PrivateRoute allowExternalAccess={true}>
            <CreateCotation />
          </PrivateRoute>
        ),
      },
      {
        path: 'form-cg',
        element: (
          <PrivateRoute>
            <CGForm />
          </PrivateRoute>
        ),
      },
      {
        path: 'list-cg',
        element: (
          <PrivateRoute>
            <CGList />
          </PrivateRoute>
        ),
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
