import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import theme from '../../theme';

interface PaymentModalProps {
  open: boolean;
  onClose: () => void;
  price: number;
  invoiceNumber: string;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ open, onClose, price, invoiceNumber }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="payment-modal-title">
      <DialogTitle style={{ fontWeight: 'bold', textAlign: 'center' }} id="payment-modal-title">
        Détails du paiement
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom mt={4}>
          <span style={{ fontWeight: '600' }}>Prix à payer :</span> {price.toFixed(2)} € HT
        </Typography>
        <Typography variant="body2" gutterBottom>
          <span style={{ fontWeight: '600' }}>Numéro de facture :</span> {invoiceNumber}
        </Typography>
        <Typography variant="body1" mt={4}>
          Coordonnées bancaires
        </Typography>
        <Typography variant="body2" gutterBottom>
          <span style={{ fontWeight: '600' }}>Nom du bénéficiaire :</span> SKIPCAR
        </Typography>
        <Typography variant="body2" gutterBottom>
          <span style={{ fontWeight: '600' }}>BIC :</span> QNTOFRP1XXX
        </Typography>
        <Typography variant="body2" gutterBottom>
          <span style={{ fontWeight: '600' }}>IBAN</span> : FR7616958000014724291064564
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            fontWeight: 600,
            backgroundColor: theme.palette.saffron.main,
            '&:hover': { backgroundColor: theme.palette.saffron.main },
          }}
          onClick={onClose}
          variant="contained"
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentModal;
