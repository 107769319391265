import { useState, useCallback } from 'react';

const useSearchHandler = <T>(initialQuery = '') => {
  const [searchQuery, setSearchQuery] = useState<string>(initialQuery);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  const handleSearch = useCallback(async (query: string, refetch: () => Promise<T>) => {
    setSearchQuery(query);
    setIsSearching(true);
    setHasSearched(true);
    await refetch();
    setIsSearching(false);
  }, []);

  const resetSearch = useCallback(async (refetch: () => Promise<T>) => {
    setSearchQuery('');
    setIsSearching(false);
    setHasSearched(false);
    await refetch();
  }, []);

  return {
    searchQuery,
    isSearching,
    hasSearched,
    handleSearch,
    resetSearch,
  };
};

export default useSearchHandler;
