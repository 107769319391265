import axios from 'axios';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Tooltip,
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import SaveIcon from '@mui/icons-material/Save';
import { DBDeal } from '../../../types/deal';
import { makeAuthenticatedApiCall } from '../../../apiHelper';
import {
  base64ToBlob,
  documentTypes,
  documentTypesDb,
  genderTypes,
  getAdresseTypeVoie,
  getFormatFromMimetype,
  getPipedriveDealData,
  getPipedriveOrgData,
  getPipedrivePersonData,
  removeSpaces,
} from '../../../utils';
import { ErrorMessageSnackbar } from '../../ErrorMessageSnackbar';
import { useDepositSaleBuyer } from '../../../hooks/useApiQueries';
import theme from '../../../theme';
import FirebaseUploadComponent from '../../CreateCotation/FirebaseUploadComponent';

interface CreateCessionCerfaProps {
  deal: DBDeal;
  isDepositSale: boolean;
  isMandate: boolean;
  onRefresh: () => void;
}

const CreateCessionCerfa: React.FC<CreateCessionCerfaProps> = ({ deal, isDepositSale, isMandate, onRefresh }) => {
  const [niceAddress, setNiceAddress] = useState<Record<string, string | null>>({});
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [orgName, setOrgName] = useState<string>('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [clientType, setClientType] = useState<string>('');
  const [ownerData, setOwnerData] = useState<Record<string, string | null>>({});
  const [tooltipError, setTooltipError] = useState<string>('');
  const [responseErrorCode, setResponseErrorCode] = useState(null);

  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('error');

  const [downloading, setDownloading] = useState(false);
  const [downloadUrls, setDownloadUrls] = useState<string[]>([]);

  const isButtonDisabled = !deal?.num_formule;

  const pipelineLetter = isDepositSale ? 'e' : isMandate ? 'b' : 'a';
  const { data: depositSaleBuyerData, isLoading: isDepositSaleBuyerDataLoading } = useDepositSaleBuyer(deal.id);
  const [createCessionCerfaUploadedUrls, setCreateCessionCerfaUploadedUrls] = useState<string[]>(
    pipelineLetter === 'e' ? depositSaleBuyerData?.files : [],
  );

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const handleConfirmClick = () => {
    setIsConfirmModalOpen(true); // Ouvrir la modale de confirmation au lieu de soumettre directement
  };

  const formMethods = useForm({
    mode: 'all',
  });
  const { handleSubmit, control, formState, trigger } = formMethods;
  const { errors, isValid } = formState;

  const validationRules = {
    firstName: { required: 'Ce champ est requis' },
    lastName: { required: 'Ce champ est requis' },
    addressNumber: { required: 'Ce champ est requis' },
    addressType: { required: 'Ce champ est requis' },
    addressName: { required: 'Ce champ est requis' },
    city: { required: 'Ce champ est requis' },
    postalCode: { required: 'Ce champ est requis' },
    country: { required: 'Ce champ est requis' },
  };

  const handleButtonClick = async () => {
    const hasError = await checkVehicleOwnerInformations();
    console.log(hasError);

    if (!hasError) {
      setIsDialogOpen(true);
    }
  };

  const handleFinalConfirm = async () => {
    const formData = formMethods.getValues(); // Récupérer les données du formulaire actuel

    setIsConfirmModalOpen(false); // Fermer la modale de confirmation
    await handleSave(formData);
    setIsDialogOpen(false);
    await handleCerfaCession(); // Appeler la fonction pour soumettre
    onRefresh();
    // handleDialogClose(); // Fermer la modale principale
  };

  useEffect(() => {
    if (isDialogOpen) {
      trigger(); // Déclencher la validation de tous les champs
    }
  }, [isDialogOpen, trigger]);

  useEffect(() => {
    setCreateCessionCerfaUploadedUrls(depositSaleBuyerData?.files);
  }, [deal, depositSaleBuyerData, pipelineLetter]);

  const checkVehicleOwnerInformations = async () => {
    if (isMandate) {
      setClientType('pro'); // Car c'est l'agence qui achète, et l'agence est un pro t'as vu

      // Purchaser <=> Agence
      try {
        const pipedriveDealData = await getPipedriveDealData(deal.id);
        setOrgName(pipedriveDealData?.org_name);
        const orgId = pipedriveDealData?.org_id?.value;
        const personId = pipedriveDealData?.person_id?.value;
        const _purchaserData = await getPipedriveOrgData(orgId);
        const agencyPersonData = await getPipedrivePersonData(personId);

        const _niceAddress = getAdresseTypeVoie(_purchaserData?.['address_route'] ?? '');
        setNiceAddress(_niceAddress);

        const purchaserData = {
          clientType: 'pro',
          firstName: agencyPersonData.first_name,
          lastName: agencyPersonData.last_name,
          dateOfBirth: null,
          placeOfBirth: null,
          addressNumber: _purchaserData.address_street_number,
          addressExtension: _purchaserData.address_subpremise,
          addressType: _niceAddress.typeVoie,
          addressName: _niceAddress.nomVoie,
          city: _purchaserData.address_locality,
          postalCode: _purchaserData.address_postal_code,
          country: _purchaserData.address_country,
          gender: null,
          documentId: null,
          documentType: null,
          documentIssueDate: null,
          companyName: _purchaserData?.['d7f00bd23217519c4d134d312d1206bc4a7021c6'] ?? pipedriveDealData?.org_name,
          siret: _purchaserData?.['cd6bc84ef4b1392fb08f9fdb9071cccb2c96ce1f'],
        };
        setOwnerData(purchaserData);
      } catch (error) {
        console.error(error);
      }
    } else if (isDepositSale) {
      setOwnerData(depositSaleBuyerData);
      setClientType(depositSaleBuyerData.client_type ?? 'person');
      setOrgName(depositSaleBuyerData.company_name);
    } else {
      try {
        const pipedriveDealData = await getPipedriveDealData(deal.id);
        setOrgName(pipedriveDealData?.org_name);
        // const isDepositSale = pipedriveDealData['3ea11b0c87c7bdb47335b1dd201040b9cefc143d'] === 60;

        const personId = pipedriveDealData['bb9522550edf45372ab2587bd49adbbd09f98a7b']?.value;
        const orgId = pipedriveDealData['a1af3a159b8c9f88ea655ae2c846ecef39136ce9']?.value;

        if (!personId && !orgId) {
          setTooltipError('Problème dans la récupération du titulaire');
          setDisabledButton(true);
          openSnackbar('Problème dans la récupération du titulaire', 'error');
          return true; // Arrêter l'exécution ici
        }

        const _ownerData = personId ? await getPipedrivePersonData(personId) : await getPipedriveOrgData(orgId);

        setOwnerData(_ownerData);
        setClientType(orgId ? 'pro' : 'person');

        setNiceAddress(
          getAdresseTypeVoie(
            _ownerData?.['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_route'] ?? _ownerData?.['address_route'] ?? '',
          ),
        );
        // Particulier => Francois Grolleau : ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_postal_code']
        // Pro => Adena Piscines : ownerData['address_postal_code'];
        if (
          _ownerData &&
          !_ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_postal_code'] &&
          !_ownerData['address_postal_code']
        ) {
          setTooltipError('Le code postal du titulaire est absent de pipedrive');
          setDisabledButton(true);
          return true; // Arrêter l'exécution ici
        } else if (!_ownerData) {
          setDisabledButton(true);
          setTooltipError('Des informations du titulaire sont manquantes dans pipedrive');
          return true; // Arrêter l'exécution ici
        }
      } catch (error: any) {
        setDisabledButton(true);
        setTooltipError(`Problème dans la récupération du titulaire : ${error.message}`);
        return true;
      }
    }
    return false;
  };

  const handleSave = async (formData: any /* FormDataPerson | FormDataPro */) => {
    const submissionData = {
      ...formData,
      fromCessionCerfa: true,
      documentType: documentTypesDb[formData.documentType] || formData.documentType, // Fallback sur la valeur originale au cas où
    };

    try {
      if (isDepositSale) {
        const responseSave = await makeAuthenticatedApiCall(
          'post',
          `/api/deals/${deal.id}/confirm-deposit-sale`,
          submissionData,
        );
      } else {
        const responseSave = await makeAuthenticatedApiCall(
          'post',
          `/api/owners/${pipelineLetter}/${deal.id}`,
          submissionData,
        );
      }
      openSnackbar('Les modifications ont bien été prises en compte', 'success');
      // Ajouter un loader sur le bouton
    } catch (error: any) {
      console.error(error);
      openSnackbar(error.message, 'error');
    } finally {
      setResponseErrorCode(null);
    }
  };

  const handleCerfaCession = async () => {
    setIsLoading(true);
    try {
      const updatedDeal = { ...deal, isDepositSale };
      const response = await makeAuthenticatedApiCall('post', `/api/misiv/cerfa/cession`, updatedDeal);
      const base64String = response?.data;
      const blob = base64ToBlob(base64String, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    } catch (error: any) {
      console.error('Erreur lors de la récupération du document:', error);
      openSnackbar(error.response.data.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadPhotos = async () => {
    setDownloading(true);
    const zip = new JSZip();
    const pipedriveDealId = deal.id;
    const folder = zip.folder(`Skipcar-DC-${pipedriveDealId}`);

    // Parse the deal.AllFiles string into an array of URLs
    const urls = depositSaleBuyerData?.files;
    setDownloadUrls(urls);

    const promises = urls.map(async (url: string, index: number) => {
      try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const fileName = `Skipcar-DC-${pipedriveDealId}-${index}.${getFormatFromMimetype(response.headers['content-type'])}`;
        folder?.file(fileName, blob);
      } catch (error) {
        console.error('Error handling photo:', error);
      }
    });

    await Promise.all(promises);

    try {
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `Skipcar-DC-${pipedriveDealId}.zip`);
    } catch (error) {
      console.error('Error generating ZIP:', error);
    } finally {
      setDownloading(false);
    }
  };

  const handleUploadComplete = (url: string) => {
    setCreateCessionCerfaUploadedUrls((prevUrls) => [...prevUrls, url]);
  };

  const handleDeleteComplete = (url: string) => {
    setCreateCessionCerfaUploadedUrls((prevUrls) => prevUrls.filter((existingUrl) => existingUrl !== url));
  };

  return (
    <>
      <Tooltip placement="top" title={tooltipError ? tooltipError : isButtonDisabled ? 'Numéro de formule absent' : ''}>
        <span>
          <Button
            className="dealStageActionsButton"
            variant="contained"
            onClick={handleButtonClick}
            disabled={isButtonDisabled}
          >
            {isLoading ? (
              <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
              '✍️ Certificat de Cession à signer'
            )}
          </Button>
        </span>
      </Tooltip>
      {tooltipError && (
        <Typography
          color="error"
          sx={{
            fontSize: 12,
            marginTop: 1,
            textAlign: 'center',
          }}
        >
          {tooltipError}
        </Typography>
      )}

      <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="lg">
        {isDepositSaleBuyerDataLoading ? (
          <CircularProgress />
        ) : (
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handleSave)}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                    <Typography variant="body1" textAlign="center">
                      {isDepositSale
                        ? "Veuillez vérifier les informations provenants de Skipcar dans l'étape E1"
                        : 'Veuillez vérifier les informations provenants de Pipedrive.'}
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {isDepositSale
                        ? "Nous les utilisons pour faire l'envoi au SIV."
                        : "Nous les sauvegardons dans notre base de données et les utilisons pour faire l'envoi au SIV."}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="clientType"
                      control={control}
                      defaultValue={clientType ?? ''}
                      render={({ field }) => (
                        <TextField
                          disabled
                          {...field}
                          className="inputElement"
                          margin="dense"
                          id="clientType"
                          label="Type de client"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={validationRules.firstName}
                      defaultValue={clientType === 'pro' ? ownerData?.firstName : ownerData?.first_name ?? ''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.firstName}
                          className="inputElement"
                          margin="dense"
                          id="firstName"
                          label="Prénom"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={validationRules.lastName}
                      defaultValue={clientType === 'pro' ? ownerData?.lastName : ownerData?.last_name ?? ''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="lastName"
                          error={!!errors.lastName}
                          className="inputElement"
                          margin="dense"
                          label="Nom"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressNumber"
                      control={control}
                      rules={validationRules.addressNumber}
                      defaultValue={
                        clientType === 'pro'
                          ? isMandate
                            ? ownerData?.addressNumber
                            : ownerData['address_street_number']
                          : isDepositSale
                            ? ownerData.address_number
                            : ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_street_number'] ?? ''
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.addressNumber}
                          className="inputElement"
                          margin="dense"
                          id="addressNumber"
                          label="Numéro d'adresse"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressExtension"
                      control={control}
                      defaultValue={
                        clientType === 'pro'
                          ? isMandate
                            ? ownerData?.addressExtension
                            : ownerData['address_street_subpremise']
                          : isDepositSale
                            ? ownerData.address_extension
                            : ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_subpremise'] ?? ''
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          margin="dense"
                          id="addressExtension"
                          label="Extension d'adresse"
                          type="text"
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name={'addressType'}
                      control={control}
                      defaultValue={depositSaleBuyerData?.address_type ?? ''}
                      rules={validationRules.addressType}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <InputLabel className="inputElement" id={`${field.name}-label`}>
                            Type d'adresse
                          </InputLabel>
                          <Select
                            className="inputElement"
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                            {...field}
                            labelId={`${field.name}-label`}
                            id={field.name}
                            label="Type d'adresse"
                            value={field.value ? field.value.toLowerCase() : ''}
                          >
                            <MenuItem value="rue">Rue</MenuItem>
                            <MenuItem value="all">Allée</MenuItem>
                            <MenuItem value="avn">Avenue</MenuItem>
                            <MenuItem value="blvd">Boulevard</MenuItem>
                            <MenuItem value="chau">Chaussée</MenuItem>
                            <MenuItem value="chm">Chemin</MenuItem>
                            <MenuItem value="cour">Cour</MenuItem>
                            <MenuItem value="fbg">Faubourg</MenuItem>
                            <MenuItem value="fort">Fort</MenuItem>
                            <MenuItem value="imp">Impasse</MenuItem>
                            <MenuItem value="ldit">Lieu-Dit</MenuItem>
                            <MenuItem value="parc">Parc</MenuItem>
                            <MenuItem value="pass">Passage</MenuItem>
                            <MenuItem value="plc">Place</MenuItem>
                            <MenuItem value="quai">Quai</MenuItem>
                            <MenuItem value="rte">Route</MenuItem>
                            <MenuItem value="sent">Sentier</MenuItem>
                            <MenuItem value="squa">Square</MenuItem>
                            <MenuItem value="sans">Inconnu</MenuItem>
                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="addressName"
                      control={control}
                      rules={validationRules.addressName}
                      defaultValue={niceAddress?.nomVoie ?? isDepositSale ? ownerData.address_name : ''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!errors.addressName}
                          margin="dense"
                          id="addressName"
                          label="Nom de la voie (Rue, Allée, ou Avenue, ne doivent pas apparaitre ici)"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={'addressPlace'}
                      control={control}
                      rules={{}}
                      defaultValue={depositSaleBuyerData?.address_place ?? ''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id={'addressPlace'}
                          label="Lieu-dit"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={'addressStairs'}
                      control={control}
                      rules={{}}
                      defaultValue={depositSaleBuyerData?.address_stairs ?? ''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id={'addressStairs'}
                          label="Étage/Escalier/Appartement"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={'addressComp'}
                      control={control}
                      rules={{}}
                      defaultValue={depositSaleBuyerData?.address_comp ?? ''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id={'addressComp'}
                          label="Complément d'adresse"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={'addressPostalBox'}
                      control={control}
                      rules={{}}
                      defaultValue={depositSaleBuyerData?.address_postalbox ?? ''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id={'addressPostalBox'}
                          label="Boîte postale"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="city"
                      control={control}
                      rules={validationRules.city}
                      defaultValue={
                        clientType === 'pro'
                          ? isMandate
                            ? ownerData?.city
                            : ownerData['address_locality']
                          : isDepositSale
                            ? ownerData.city
                            : ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_locality'] ?? ''
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.city}
                          className="inputElement"
                          margin="dense"
                          id="city"
                          label="Ville"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="postalCode"
                      control={control}
                      rules={validationRules.postalCode}
                      defaultValue={
                        clientType === 'pro'
                          ? isMandate
                            ? ownerData?.postalCode
                            : ownerData['address_postal_code']
                          : isDepositSale
                            ? ownerData.postal_code
                            : ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_postal_code'] ?? ''
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!errors.postalCode}
                          margin="dense"
                          id="postalCode"
                          label="Code postal"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="country"
                      control={control}
                      rules={validationRules.country}
                      defaultValue={
                        clientType === 'pro'
                          ? isMandate
                            ? ownerData?.country
                            : ownerData['address_country']
                          : isDepositSale
                            ? ownerData.country
                            : ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_country'] ?? ''
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!errors.country}
                          margin="dense"
                          id="country"
                          label="Pays"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  {clientType === 'person' && (
                    <>
                      <Grid item xs={12} md={3}>
                        <Controller
                          name="dateOfBirth"
                          rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                          control={control}
                          defaultValue={
                            ownerData?.['e96b6e021e72e800417bfb128337d2c0f1df47e9']
                              ? ownerData?.['e96b6e021e72e800417bfb128337d2c0f1df47e9']
                              : ownerData?.date_of_birth ?? ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="dateOfBirth"
                              error={!!errors.dateOfBirth}
                              sx={{ marginTop: '8px' }}
                              className="inputElement"
                              label="Date de naissance"
                              type="date"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Controller
                          name="placeOfBirth"
                          control={control}
                          rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                          defaultValue={
                            ownerData?.['6a47f4d5730c056eac6437f64e812389c654a4aa']
                              ? ownerData?.['6a47f4d5730c056eac6437f64e812389c654a4aa']
                              : ownerData?.place_of_birth ?? ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              error={!!errors.placeOfBirth}
                              className="inputElement"
                              margin="dense"
                              id="placeOfBirth"
                              label="Lieu de naissance"
                              type="text"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Controller
                          name="gender"
                          control={control}
                          rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                          defaultValue={(() => {
                            const value = ownerData?.['8c67afa374f8fb74710de066ea878a4f30d14c69']
                              ? genderTypes[ownerData?.['8c67afa374f8fb74710de066ea878a4f30d14c69']].value
                              : isDepositSale
                                ? ownerData.gender
                                : '';
                            return value;
                          })()}
                          render={({ field }) => (
                            <Select
                              sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                              {...field}
                              error={!!errors.gender}
                              margin="dense"
                              fullWidth
                              className="inputElement"
                            >
                              <MenuItem value="male">Masculin</MenuItem>
                              <MenuItem value="female">Féminin</MenuItem>
                            </Select>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Controller
                          name="documentId"
                          rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                          control={control}
                          defaultValue={
                            ownerData?.['6d78e71a34367d60a4dc822f4cdd78d73b376021']
                              ? ownerData?.['6d78e71a34367d60a4dc822f4cdd78d73b376021'] ?? ''
                              : isDepositSale
                                ? ownerData.document_id
                                : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              margin="dense"
                              error={!!errors.documentId}
                              className="inputElement"
                              id="documentId"
                              label="ID du document"
                              type="text"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Controller
                          name="documentType"
                          rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                          control={control}
                          defaultValue={
                            ownerData?.['34387af45e0ae48b44f61195ee2f755a00f2280b']
                              ? documentTypes[ownerData?.['34387af45e0ae48b44f61195ee2f755a00f2280b']]?.dbValue || '' // Utiliser dbValue comme valeur par défaut
                              : isDepositSale
                                ? ownerData.document_type
                                : ''
                          }
                          render={({ field }) => (
                            <Select
                              {...field}
                              sx={{ minHeight: 'inherit', height: '56px', marginTop: '0' }}
                              error={!!errors.documentType}
                              fullWidth
                              className="inputElement"
                            >
                              <MenuItem value="id_card">Carte d'identité</MenuItem>
                              <MenuItem value="passport">Passeport</MenuItem>
                              <MenuItem value="driving_licence">Permis de conduire</MenuItem>
                              <MenuItem value="residence_permit">Titre de séjour</MenuItem>
                            </Select>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Controller
                          name="documentIssueDate"
                          rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                          control={control}
                          defaultValue={
                            ownerData?.['0ad2c6ddd477b9b29b4d18858b36f0aee3173b1c']
                              ? ownerData?.['0ad2c6ddd477b9b29b4d18858b36f0aee3173b1c']
                              : isDepositSale
                                ? ownerData.document_issue_date
                                : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              sx={{ marginTop: '0px' }}
                              margin="dense"
                              error={!!errors.documentIssueDate}
                              className="inputElement"
                              id="documentIssueDate"
                              label="Date de délivrance du document"
                              type="date"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="email"
                          control={control}
                          rules={{ required: 'Ce champ est requis' }}
                          defaultValue={ownerData?.email?.[0]?.value ?? ownerData?.email ?? ''}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              sx={{ marginTop: 0 }}
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : ''}
                              className="inputElement"
                              margin="dense"
                              id="email"
                              label="Email"
                              type="email"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {clientType === 'pro' && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="siret"
                          control={control}
                          rules={
                            clientType === 'pro'
                              ? {
                                  required: 'Ce champ est requis',
                                  minLength: {
                                    value: 14,
                                    message: 'Le SIRET doit contenir exactement 14 caractères',
                                  },
                                  maxLength: {
                                    value: 14,
                                    message: 'Le SIRET doit contenir exactement 14 caractères',
                                  },
                                }
                              : {
                                  minLength: {
                                    value: 14,
                                    message: 'Le SIRET doit contenir exactement 14 caractères',
                                  },
                                  maxLength: {
                                    value: 14,
                                    message: 'Le SIRET doit contenir exactement 14 caractères',
                                  },
                                }
                          }
                          defaultValue={
                            isMandate
                              ? ownerData?.siret
                              : ownerData['cd6bc84ef4b1392fb08f9fdb9071cccb2c96ce1f'] ?? isDepositSale
                                ? ownerData.siret
                                : ''
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="inputElement"
                              error={!!errors.siret}
                              helperText={errors.siret ? (errors.siret.message as string) : ''}
                              margin="dense"
                              id="siret"
                              label="SIRET"
                              type="text"
                              fullWidth
                              inputProps={{ maxLength: 14 }} // Limite le nombre de caractères que l'utilisateur peut taper
                              onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="companyName"
                          control={control}
                          rules={clientType === 'pro' ? { required: 'Ce champ est requis' } : {}}
                          defaultValue={
                            isMandate
                              ? ownerData?.companyName
                              : ownerData['d7f00bd23217519c4d134d312d1206bc4a7021c6'] ?? orgName
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              margin="dense"
                              error={!!errors.companyName}
                              id="companyName"
                              className="inputElement"
                              label="Raison sociale"
                              type="text"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2">
                    {depositSaleBuyerData?.client_type === 'pro' || deal?.BuyerType === 'pro'
                      ? 'KBIS'
                      : "Justificatif de domicile, Passeport ou Carte d'identité (Recto-Verso)"}
                  </Typography>
                  <Button
                    onClick={handleDownloadPhotos}
                    style={{
                      position: 'relative',
                      float: 'right',
                      right: '0px',
                      top: '0px',
                      backgroundColor: theme.palette.saffron.main,
                      zIndex: 1000,
                      cursor: 'pointer',
                    }}
                  >
                    {downloading ? (
                      <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} />
                    ) : (
                      <SaveIcon color="action" />
                    )}
                  </Button>
                  <FirebaseUploadComponent
                    onDeleteComplete={handleDeleteComplete}
                    onUploadComplete={handleUploadComplete}
                    uploadedUrls={createCessionCerfaUploadedUrls}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Annuler</Button>
                <Button variant="contained" onClick={handleConfirmClick} disabled={!isValid}>
                  Générer
                </Button>
              </DialogActions>
            </form>
          </FormProvider>
        )}
      </Dialog>
      {/* Modale de confirmation */}
      <Dialog open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
        <DialogContent>
          <Typography>Êtes-vous sûr que toutes les informations sont correctes ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmModalOpen(false)}>Annuler</Button>
          <Button onClick={handleFinalConfirm}>Confirmer</Button>
        </DialogActions>
      </Dialog>

      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
    </>
  );
};

export default CreateCessionCerfa;
