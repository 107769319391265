import DealsByOrganization from '../components/DealsByOrganization';
import { useParams } from 'react-router-dom';

const DealsByOrganizationPage = () => {
  const { org_id } = useParams();

  return (
    <div>
      <h1>Liste des Deals par Organization</h1>
      <p>This is the content for org_id : {org_id}</p>
      <DealsByOrganization org_id={org_id} />
    </div>
  );
};

export default DealsByOrganizationPage;
