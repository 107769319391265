import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Container,
  Button,
  TablePagination,
  TextField,
  Box,
  Alert,
} from '@mui/material';
import { makeAuthenticatedApiCall } from '../apiHelper';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import theme from '../theme';
import { useQueryClient } from '@tanstack/react-query';
import { set } from 'date-fns';

// Fonction pour générer un mot de passe aléatoire
const generatePassword = () => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
  let password = '';
  for (let i = 0; i < 10; i++) {
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return password;
};

const FirebaseAccountList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [accountData, setAccountData] = useState([]);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [idAgency, setIdAgency] = useState<string>('');
  const [network, setNetwork] = useState<string>('');
  const [alert, setAlert] = useState<{ type: 'success' | 'error'; message: string } | null>(null);

  const { currentUser } = useAuth();
  const userRole = currentUser?.data?.data?.role;

  const queryClient = useQueryClient();

  const fetchAccounts = async () => {
    setIsTableLoading(true);
    try {
      const response = await makeAuthenticatedApiCall(
        'get',
        `/api/account?page=${page + 1}&limit=${rowsPerPage}&search=${searchQuery}`,
      );
      setAccountData(response.data);
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
    } finally {
      setIsTableLoading(false);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [page, rowsPerPage, searchQuery]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleResetSearch = () => {
    setSearchQuery('');
    setPage(0);
  };

  const handleCreateAccount = async () => {
    setIsCreatingAccount(true);
    if (!name || !email || !idAgency || !network) {
      setAlert({ type: 'error', message: 'Tous les champs doivent être remplis.' });
      return;
    }

    if (isNaN(Number(idAgency))) {
      setAlert({ type: 'error', message: 'IdAgency doit être un nombre.' });
      return;
    }

    const password = generatePassword();

    try {
      const response = await makeAuthenticatedApiCall('post', `/api/account`, {
        email,
        password,
        idAgency: Number(idAgency),
        orgName: name,
        network: network,
      });

      if (response.status === 201) {
        setAlert({ type: 'success', message: 'Compte créé avec succès.' });
        fetchAccounts();
      } else {
        let errorMessage = 'Erreur lors de la création du compte.';
        if (response.data && typeof response.data === 'object') {
          const responseData = response.data as { message?: string; error?: { message?: string } };
          if (responseData.message) {
            errorMessage = responseData.message;
          }
          if (responseData.error && responseData.error.message) {
            errorMessage += ` ${responseData.error.message}`;
          }
        }
        setAlert({ type: 'error', message: errorMessage });
      }
    } catch (error: any) {
      let errorMessage = 'Erreur lors de la création du compte.';
      if (error.response?.data && typeof error.response.data === 'object') {
        const errorData = error.response.data as { message?: string; error?: { message?: string } };
        if (errorData.message) {
          errorMessage = errorData.message;
        }
        if (errorData.error && errorData.error.message) {
          errorMessage += ` ${errorData.error.message}`;
        }
      }
      setAlert({ type: 'error', message: errorMessage });
      console.error('Failed to create account:', error);
    } finally {
      setIsCreatingAccount(false);
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <Paper sx={{ marginTop: 5 }} elevation={3}>
          <Typography variant="h6" sx={{ paddingTop: 1, paddingLeft: 2 }}>
            <b>Attention</b> l'organisation doit déjà exister dans Pipedrive ! Une personne doit aussi être déjà créée
            et liée à l'organisation dans Pipedrive.
          </Typography>
          <Typography variant="h6" sx={{ paddingTop: 1, paddingLeft: 2 }}>
            Cette interface sert à relier la personne/organisation dans Pipedrive à l'application Skipcar.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              paddingTop: 2,
              paddingLeft: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <TextField
                label="Nom de l'agence"
                variant="outlined"
                className="inputElement"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ marginRight: 1 }}
              />
              <TextField
                label="Email"
                variant="outlined"
                className="inputElement"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginRight: 1 }}
              />
              <TextField
                label="IdAgency (Pipedrive)"
                variant="outlined"
                className="inputElement"
                value={idAgency}
                onChange={(e) => setIdAgency(e.target.value)}
                sx={{ marginRight: 1, maxWidth: 140 }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
              <TextField
                label="Franchise"
                variant="outlined"
                className="inputElement"
                value={network}
                onChange={(e) => setNetwork(e.target.value)}
                sx={{ marginRight: 1 }}
              />
              <Button
                variant="contained"
                onClick={handleCreateAccount}
                disabled={isCreatingAccount}
                sx={{
                  fontWeight: 900,
                  backgroundColor: theme.palette.saffron.main,
                  '&:hover': { backgroundColor: theme.palette.saffron.main },
                }}
              >
                {isCreatingAccount ? <CircularProgress size={20} /> : 'Créer un compte'}
              </Button>
            </Box>
            {alert && (
              <Alert severity={alert.type} onClose={() => setAlert(null)} sx={{ marginBottom: 2 }}>
                {alert.message}
              </Alert>
            )}
          </Box>
        </Paper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 5,
          }}
        >
          <Box
            sx={{
              display: 'none',
              alignItems: 'center',
            }}
          >
            <TextField
              label="Rechercher"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ marginRight: 0 }}
            />
            <Button
              variant="contained"
              onClick={() => {
                setPage(0);
                setHasSearched(true);
                fetchAccounts();
              }}
              sx={{
                marginLeft: 1,
                fontWeight: 900,
                backgroundColor: theme.palette.saffron.main,
                '&:hover': { backgroundColor: theme.palette.saffron.main },
              }}
            >
              Rechercher
            </Button>
            {hasSearched && (
              <Button variant="contained" sx={{ marginLeft: 1 }} color="error" onClick={handleResetSearch}>
                Revenir à la liste principale
              </Button>
            )}
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={accountData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Lignes par page"
            labelDisplayedRows={({ from, to, count }) => `de ${from} à ${to}`}
          />
        </Box>
        <Paper sx={{ marginTop: 5, padding: 0 }} elevation={3}>
          <Table stickyHeader>
            <TableHead className="stickyTableHead">
              <TableRow sx={{ background: '#262626' }}>
                <TableCell
                  sx={{
                    background: '#262626',
                    fontWeight: 'bold',
                    color: 'white',
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                  }}
                >
                  Nom
                </TableCell>
                <TableCell
                  sx={{
                    background: '#262626',
                    fontWeight: 'bold',
                    color: 'white',
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                  }}
                >
                  Id Organization
                </TableCell>
                <TableCell
                  sx={{
                    background: '#262626',
                    fontWeight: 'bold',
                    color: 'white',
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    background: '#262626',
                    fontWeight: 'bold',
                    color: 'white',
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                  }}
                >
                  MDP initial
                </TableCell>
                <TableCell
                  sx={{
                    background: '#262626',
                    fontWeight: 'bold',
                    color: 'white',
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                  }}
                >
                  Franchise
                </TableCell>
                <TableCell
                  sx={{
                    background: '#262626',
                    fontWeight: 'bold',
                    color: 'white',
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                  }}
                >
                  Id Firebase
                </TableCell>
              </TableRow>
            </TableHead>
            {isTableLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {accountData?.map((account: any) => (
                  <TableRow key={account.id}>
                    <TableCell>{account.org_name}</TableCell>
                    <TableCell>{account.org_id}</TableCell>
                    <TableCell>{account.org_email}</TableCell>
                    <TableCell>{account.initial_password}</TableCell>
                    <TableCell>{account.org_network}</TableCell>
                    <TableCell>{account.firebase_id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Paper>
      </Container>
    </>
  );
};

export default FirebaseAccountList;
