import { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Tooltip } from '@mui/material';
import FirebaseUploadComponent from '../../CreateCotation/FirebaseUploadComponent';
import { makeAuthenticatedApiCall } from '../../../apiHelper';
import theme from '../../../theme';

interface UploadModalProps {
  dealId: number;
  photos: Record<string, string[]>;
  extUrl?: string;
  regPlate?: string;
}

const UploadModal: React.FC<UploadModalProps> = ({ dealId, photos, extUrl, regPlate }) => {
  const [open, setOpen] = useState(false);
  const [certificateOfTransferUrl, setCertificateOfTransferUrl] = useState<string[]>(
    photos?.certificateOfTransfer || [],
  );
  const [salesCertificateUrl, setSalesCertificateUrl] = useState<string[]>(photos?.salesCertificate || []);
  const [barredVehicleRegistrationDocumentUrl, setBarredVehicleRegistrationDocumentUrl] = useState<string[]>(
    photos?.barredVehicleRegistrationDocument || [],
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadComplete = (url: string, category: string) => {
    if (category === 'certificateOfTransfer') {
      setCertificateOfTransferUrl((prevUrls) => [...prevUrls, url]);
    } else if (category === 'salesCertificate') {
      setSalesCertificateUrl((prevUrls) => [...prevUrls, url]);
    } else if (category === 'barredVehicleRegistrationDocument') {
      setBarredVehicleRegistrationDocumentUrl((prevUrls) => [...prevUrls, url]);
    }
  };

  // Fonction de suppression avec spécification de la catégorie
  const handleDeleteComplete = (url: string, category: string) => {
    if (category === 'certificateOfTransfer') {
      setCertificateOfTransferUrl((prevUrls) => prevUrls.filter((existingUrl) => existingUrl !== url));
    } else if (category === 'salesCertificate') {
      setSalesCertificateUrl((prevUrls) => prevUrls.filter((existingUrl) => existingUrl !== url));
    } else if (category === 'barredVehicleRegistrationDocument') {
      setBarredVehicleRegistrationDocumentUrl((prevUrls) => prevUrls.filter((existingUrl) => existingUrl !== url));
    } else {
      console.error('Catégorie de fichier non gérée');
    }
  };

  const isValidationButtonDisabled =
    certificateOfTransferUrl?.length === 0 ||
    salesCertificateUrl?.length === 0 ||
    barredVehicleRegistrationDocumentUrl?.length === 0;

  const handleValidation = async () => {
    try {
      if (
        certificateOfTransferUrl !== undefined &&
        salesCertificateUrl !== undefined &&
        barredVehicleRegistrationDocumentUrl !== undefined
      ) {
        await makeAuthenticatedApiCall('post', `/api/deals/${dealId}/update-car-deal-procurement-file`, {
          certificateOfTransfer: certificateOfTransferUrl,
          salesCertificate: salesCertificateUrl,
          barredVehicleRegistrationDocument: barredVehicleRegistrationDocumentUrl,
        });

        // Si les 3 catégories sont remplies, on envoie le mail pour prévenir les admins
        if (certificateOfTransferUrl && salesCertificateUrl && barredVehicleRegistrationDocumentUrl) {
          await makeAuthenticatedApiCall('post', `/api/mails/procurement-file-admin-notification`, {
            dealId: dealId,
            extUrl: extUrl,
            regPlate: regPlate,
          });
        }

        handleClose();
      } else {
        console.error('Certains éléments de photos sont indéfinis');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour des photos', error);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        className="uploadModalButton"
        sx={{
          backgroundColor: theme.palette.saffron.main,
          '&:hover': { backgroundColor: theme.palette.saffron.main },
        }}
      >
        📤 Dossier d'achat Skipcar{' '}
        {certificateOfTransferUrl?.length + salesCertificateUrl?.length + barredVehicleRegistrationDocumentUrl?.length >
        0
          ? `(${
              certificateOfTransferUrl?.length +
              salesCertificateUrl?.length +
              barredVehicleRegistrationDocumentUrl?.length
            })`
          : ''}
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <Grid container item xs={12} md={12}>
          <Grid item xs={12} md={4}>
            <DialogTitle>Certificat de cession signée</DialogTitle>
            <DialogContent>
              <FirebaseUploadComponent
                onDeleteComplete={(url) => handleDeleteComplete(url, 'certificateOfTransfer')}
                onUploadComplete={(url) => handleUploadComplete(url, 'certificateOfTransfer')}
                uploadedUrls={certificateOfTransferUrl}
              />
            </DialogContent>
          </Grid>
          <Grid item xs={12} md={4}>
            <DialogTitle>Attestation de vente signée</DialogTitle>
            <DialogContent>
              <FirebaseUploadComponent
                onDeleteComplete={(url) => handleDeleteComplete(url, 'salesCertificate')}
                onUploadComplete={(url) => handleUploadComplete(url, 'salesCertificate')}
                uploadedUrls={salesCertificateUrl}
              />
            </DialogContent>
          </Grid>
          <Grid item xs={12} md={4}>
            <DialogTitle>Carte grise barrée</DialogTitle>
            <DialogContent>
              <FirebaseUploadComponent
                onDeleteComplete={(url) => handleDeleteComplete(url, 'barredVehicleRegistrationDocument')}
                onUploadComplete={(url) => handleUploadComplete(url, 'barredVehicleRegistrationDocument')}
                uploadedUrls={barredVehicleRegistrationDocumentUrl}
              />
            </DialogContent>
          </Grid>
          <DialogActions style={{ justifyContent: 'center', width: '100%', flexDirection: 'column' }}>
            <Button onClick={handleClose}>Annuler</Button>
            <Tooltip
              title="Vous ne pourrez pas valider tant que les fichiers ne seront pas envoyés"
              placement="top"
              disableHoverListener={!isValidationButtonDisabled}
            >
              <span>
                {' '}
                {/* Englobez le bouton dans une span pour que le Tooltip fonctionne avec le bouton désactivé */}
                <Button
                  onClick={handleValidation}
                  variant="contained"
                  color="primary"
                  disabled={isValidationButtonDisabled}
                >
                  Valider les fichiers
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
};

export default UploadModal;
