import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';

interface Comment {
  comment_id: number;
  registration_id: number;
  user_id: number;
  user_role: string;
  user_name: string;
  comment: string;
  created_at: string;
}

interface ExternalCommentsProps {
  registrationId: number;
  userRole: string;
}

const ExternalComments: React.FC<ExternalCommentsProps> = ({ registrationId, userRole }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState('');

  const { t } = useTranslation('common');

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    try {
      const response = await makeAuthenticatedApiCall('get', `/api/cg/external-comments/${registrationId}`);
      setComments(response.data as Comment[]);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleAddComment = async (type: string) => {
    try {
      await makeAuthenticatedApiCall('post', '/api/cg/external-comments', {
        registrationId,
        comment: type === 'paymentReminder' ? 'Relance de paiement' : newComment,
        type,
      });
      setNewComment('');
      await fetchComments();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const formatCommentDate = (dateString: string) => {
    return moment(dateString).tz('Europe/Paris').format('DD/MM/YYYY HH:mm');
  };

  return (
    <Box>
      <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '18px' }} gutterBottom mt={4}>
        Commentaires :
      </Typography>
      {comments.map((comment) => (
        <div key={comment.comment_id}>
          <strong>
            {comment.user_name} ({t(`${comment.user_role}`)}) -{' '}
          </strong>
          <span>
            {formatCommentDate(comment.created_at)} : {comment.comment}
          </span>
        </div>
      ))}
      <Box>
        <TextField
          label="Nouveau commentaire"
          variant="outlined"
          className="inputElement inputElement--Comments"
          multiline
          fullWidth
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column' }}
        >
          <Button
            sx={{
              fontWeight: 600,
              height: 40,
              mt: 1,
              fontSize: '12px',
              textAlign: 'center',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            type="button"
            variant="contained"
            onClick={() => handleAddComment('sendComment')}
          >
            Envoyer un commentaire
          </Button>
          {userRole !== 'agency' && (
            <Button
              sx={{
                fontWeight: 600,
                height: 40,
                mt: 1,
                fontSize: '12px',
                textAlign: 'center',
                backgroundColor: theme.palette.saffron.main,
                '&:hover': { backgroundColor: theme.palette.saffron.main },
              }}
              type="button"
              variant="contained"
              onClick={() => handleAddComment('paymentReminder')}
            >
              Relance paiement
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ExternalComments;
