export const coloursOptions = [
  { label: 'Beige', value: 'beige' },
  { label: 'Beige clair', value: 'light_beige' },
  { label: 'Blanc', value: 'white' },
  { label: 'Bleu', value: 'blue' },
  { label: 'Bleu clair', value: 'light_blue' },
  { label: 'Bleu foncé', value: 'dark_blue' },
  { label: 'Bleu fonce', value: 'dark_blue' },
  { label: 'Gris', value: 'grey' },
  { label: 'Gris clair', value: 'light_grey' },
  { label: 'Gris foncé', value: 'dark_grey' },
  { label: 'Gris fonce', value: 'dark_grey' },
  { label: 'Jaune', value: 'yellow' },
  { label: 'Jaune clair', value: 'light_yellow' },
  { label: 'Marron', value: 'brown' },
  { label: 'Marron clair', value: 'light_brown' },
  { label: 'Marron foncé', value: 'dark_brown' },
  { label: 'Marron fonce', value: 'dark_brown' },
  { label: 'Noir', value: 'black' },
  { label: 'Orange', value: 'orange' },
  { label: 'Rouge', value: 'red' },
  { label: 'Rouge clair', value: 'light_red' },
  { label: 'Rouge foncé', value: 'dark_red' },
  { label: 'Rouge fonce', value: 'dark_red' },
  { label: 'Vert', value: 'green' },
  { label: 'Vert clair', value: 'light_green' },
  { label: 'Vert foncé', value: 'dark_green' },
  { label: 'Vert fonce', value: 'dark_green' },
  { label: 'Violet', value: 'purple' },
  { label: 'Inc.', value: 'unknown' },
];

export const documentationOptions = [
  { label: 'A jour', value: 'up_to_date' },
  { label: 'Pas à jour', value: 'not_up_to_date' },
  { label: "Pas de carnet d'entretien", value: 'none' },
];

export const duplicateKeysOptions = [
  { label: 'Oui', value: 'yes' },
  { label: 'Non', value: 'no' },
  { label: 'Ne sait pas', value: 'dontknow' },
];

export const options = [
  { label: 'Aixam', value: 'aixam' },
  { label: 'Alfa Romeo', value: 'alfa_romeo' },
  { label: 'Alpha', value: 'alpha' },
  { label: 'Aprilla', value: 'aprilla' },
  { label: 'Aston Martin', value: 'aston_martin' },
  { label: 'Audi', value: 'audi' },
  { label: 'Bentley', value: 'bentley' },
  { label: 'BMW', value: 'bmw' },
  { label: 'Bugatti', value: 'bugatti' },
  { label: 'Cadillac', value: 'cadillac' },
  { label: 'Chevrolet', value: 'chevrolet' },
  { label: 'Chrysler', value: 'chrysler' },
  { label: 'Citroen', value: 'citroen' },
  { label: 'Dacia', value: 'dacia' },
  { label: 'Daewoo', value: 'daewoo' },
  { label: 'Daihatsu', value: 'daihatsu' },
  { label: 'Dodge', value: 'dodge' },
  { label: 'Ds', value: 'ds' },
  { label: 'Ferrari', value: 'ferrari' },
  { label: 'Fiat', value: 'fiat' },
  { label: 'Ford', value: 'ford' },
  { label: 'Honda', value: 'honda' },
  { label: 'Hummer', value: 'hummer' },
  { label: 'Hyundai', value: 'hyundai' },
  { label: 'Infiniti', value: 'infiniti' },
  { label: 'Isuzu', value: 'isuzu' },
  { label: 'Iveco', value: 'iveco' },
  { label: 'Jaguar', value: 'jaguar' },
  { label: 'Jeep', value: 'jeep' },
  { label: 'Kawasaki', value: 'kawasaki' },
  { label: 'Keeway', value: 'keeway' },
  { label: 'Kia', value: 'kia' },
  { label: 'Lada', value: 'lada' },
  { label: 'Lamborghini', value: 'lamborghini' },
  { label: 'Lancia', value: 'lancia' },
  { label: 'Land Rover', value: 'land_rover' },
  { label: 'Lexus', value: 'lexus' },
  { label: 'Ligier', value: 'ligier' },
  { label: 'Lotus', value: 'lotus' },
  { label: 'Maserati', value: 'maserati' },
  { label: 'Mazda', value: 'mazda' },
  { label: 'Mercedes Benz', value: 'mercedes_benz' },
  { label: 'Mercedes', value: 'mercedes' },
  { label: 'MG', value: 'mg' },
  { label: 'Mini', value: 'mini' },
  { label: 'Mitsubishi', value: 'mitsubishi' },
  { label: 'Nissan', value: 'nissan' },
  { label: 'Opel', value: 'opel' },
  { label: 'Peugeot', value: 'peugeot' },
  { label: 'Piaggio', value: 'piaggio' },
  { label: 'Porsche', value: 'porsche' },
  { label: 'Renault', value: 'renault' },
  { label: 'Rover', value: 'rover' },
  { label: 'Saab', value: 'saab' },
  { label: 'Seat', value: 'seat' },
  { label: 'Skoda', value: 'skoda' },
  { label: 'Smart', value: 'smart' },
  { label: 'Ssangyong', value: 'ssangyong' },
  { label: 'Subaru', value: 'subaru' },
  { label: 'Suzuki', value: 'suzuki' },
  { label: 'Tesla', value: 'tesla' },
  { label: 'Toyota', value: 'toyota' },
  { label: 'Triumph', value: 'triumph' },
  { label: 'Volkswagen', value: 'volkswagen' },
  { label: 'Volvo', value: 'volvo' },
  { label: 'Yamaha', value: 'yamaha' },
];

export type OptionType = {
  value: string;
  label: string;
};

export const vehicleTypeOptions: OptionType[] = [
  { value: 'bp', label: 'Berline petite' },
  { value: 'bm', label: 'Berline moyenne' },
  { value: 'bg', label: 'Berline grande' },
  { value: 'm', label: 'Moto' },
  { value: 'fp', label: 'Fourgon petit' },
  { value: 'fm', label: 'Fourgon moyen' },
  { value: 'fg', label: 'Fourgon grand' },
  { value: 'suv', label: 'SUV' },
];

export const booleanOptions: OptionType[] = [
  { value: 'yes', label: 'Oui' },
  { value: 'no', label: 'Non' },
];

export const genreOptions: OptionType[] = [
  { value: 'cam', label: 'CAM' },
  { value: 'vp', label: 'VP' },
  { value: 'mtt2', label: 'MTT2' },
  { value: 'rem', label: 'REM' },
  { value: 'cl', label: 'CL' },
  { value: 'mtl', label: 'MTL' },
  { value: 'qm', label: 'QM' },
  { value: 'mtt1', label: 'MTT1' },
  { value: 'tm', label: 'TM' },
  { value: 'tcp', label: 'TCP' },
  { value: 'srem', label: 'SREM' },
  { value: 'div', label: 'DIV' },
  { value: 'tra', label: 'TRA' },
  { value: 'trr', label: 'TRR' },
  { value: 'miar', label: 'MIAR' },
  { value: 'maga', label: 'MAGA' },
  { value: 'cycl', label: 'CYCL' },
  { value: 'mot3', label: 'MOT3' },
  { value: 'moto', label: 'MOTO' },
  { value: 'tqm', label: 'TQM' },
  { value: 'srea', label: 'SREA' },
  { value: 'mot2', label: 'MOT2' },
  { value: 'rea', label: 'REA' },
  { value: 'ctte', label: 'CTTE' },
  { value: 'vasp', label: 'VASP' },
  { value: 'resp', label: 'RESP' },
];

export const fuelOptions: OptionType[] = [
  { value: 'diesel', label: 'Diesel' },
  { value: 'diesel-hybrid', label: 'Gazole - elec hybride' },
  { value: 'gasoline', label: 'Essence' },
  { value: 'gasoline-hybrid', label: 'Essence - elec hybride' },
  { value: 'gasoline-rechargeable', label: 'Essence-elec rechargeable' },
  { value: 'electric', label: 'Electrique' },
  { value: 'gasoline-lpg', label: 'Ess+g.p.l.' },
  { value: 'gazole+gaznat', label: 'Gazole+Gaz naturel' },
  { value: 'air-comprime', label: 'Air comprimé' },
  { value: 'bicarburation', label: 'Bicarburation (essence + autre énergie)' },
  { value: 'biocarburation', label: 'Biocarburation (oléagineux + gazole)' },
  { value: 'ess+elec-hr', label: 'Essence+électrique (hybride rechargeable)' },
  { value: 'ess+gpl', label: 'Essence + G.P.L.' },
  { value: 'ess+elec-hnr', label: 'Essence électricité (hybride non rechargeable)' },
  { value: 'electricite', label: 'Electricité' },
  { value: 'ess+gnv+elec', label: 'Essence-Gaz naturel-Électricité' },
  { value: 'ess+gnv', label: 'Essence + Gaz naturel comprimé' },
  { value: 'es+gznat+el-hnr', label: 'Bicarburation essence-gaz naturel et électricité (hybride non rechargeable)' },
  { value: 'es+gpl+el-hnr', label: 'Bicarburation essence-GPL et électricité (hybride non rechargeable)' },
  { value: 'ess+gpl+elec-hr', label: 'Bicarburation essence-GPL et électricité (hybride rechargeable)' },
  { value: 'essence', label: 'Essence' },
  { value: 'ethanol', label: 'Ethanol' },
  { value: 'superethanol', label: 'Superéthanol' },
  { value: 's.ethan+gpl', label: 'Superéthanol + G.P.L.' },
  { value: 'elec+s.eth-hnr', label: 'Superéthanol-électricité (hybride non rechargeable)' },
  { value: 'elec+s.ethan', label: 'Electricité + Superéthanol (hybride rechargeable)' },
  { value: 's.ethan+gznat', label: 'Bicarburation Superéthanol + Gaz naturel' },
  { value: 'fuel-oil', label: 'Fuel-oil' },
  { value: 'set+gnat+el-hr', label: 'Bicarburation Superéthanol-GPL et électricité (hybride rechargeable)' },
  {
    value: 'set+gnat+el-hnr',
    label: 'Bicarburation Superéthanol-gaz naturel et électricité (hybride non rechargeable)',
  },
  { value: 's.et+gpl+el-hnr', label: 'Bicarburation Superéthanol-GPL et électricité (hybride non rechargeable)' },
  { value: 's.et+gpl+el-hr', label: 'Bicarburation Superéthanol + G.P.L. (hybride rechargeable)' },
  { value: 'gazole+gpl', label: 'Bicarburation Gazole + GPL' },
  { value: 'gazogene', label: 'Gazogène' },
  { value: 'ess+gazo', label: 'Essence + Gazogène' },
  { value: 'gazole+gaznat', label: 'Gazole + Gaz naturel (dual fuel)' },
  { value: 'gazole+gazo', label: 'Gazole + Gazogène' },
  { value: 'gaz+elec-hnr', label: 'Gazole + électricité (hybride non rechargeable)' },
  { value: 'gaz+elec-hr', label: 'Electricité-gazole (hybride rechargeable)' },
  {
    value: 'gaz+gnv+elec-hr',
    label: 'Mélange de gazole et gaz naturel (dual fuel) et électricité (hybride rechargeable)',
  },
  { value: 'gaz-nat-veh', label: 'Gaz naturel véhicule' },
  { value: 'gazole', label: 'Gazole' },
  { value: 'g.p.l.', label: 'Gaz de pétrole liquéfié (mélange de butane et propane)' },
  {
    value: 'gazol+g.nat+hyb',
    label: 'Mélange de gazole et gaz naturel (dual fuel) et Électricité (hybride non rechargeable)',
  },
  { value: 'carb-gazeux', label: 'Autres hydrocarbures gazeux comprimés' },
  { value: 'hydrogene', label: 'Hydrogène' },
  { value: 'hydro+elec-hr', label: 'Hydrogène + électricité (hybride rechargeable)' },
  { value: 'hydro+elec-hnr', label: 'Hydrogène + électricité (hybride non rechargeable)' },
  { value: 'elec+g.nat', label: 'Electricité-gaz-naturel' },
  { value: 'gaz-nat+el-hnr', label: 'Gaz naturel-électricité (hybride non rechargeable)' },
  { value: 'elec+g.p.l.', label: 'Electricité-monocarburation GPL' },
  { value: 'elec+g.p.l.-hr', label: 'Monocarburation GPL-électricité (hybride non rechargeable)' },
  { value: 'petrol-lamp', label: 'Pétrole lampant (pour TRA et MAGA uniquement)' },
  { value: 'autres', label: 'Autre' },
  { value: 'inconnu', label: 'Energie inconnue' },
];

export const transmissionOptions: OptionType[] = [
  { value: 'AT', label: 'Automatique' },
  { value: 'MT', label: 'Manuel' },
  { value: 'ST', label: 'Séquentielle' },
];

export const businessTypeOptions: OptionType[] = [
  // { value: 'straight_trade-in', label: 'Reprise sèche' },
  { value: 'straight_trade_in', label: 'Reprise sèche' },
  // { value: '30-days_sale_mandate', label: 'Mandat 30 jours' },
  { value: 'thirty_day_sale_mandate', label: 'Mandat 30 jours' },
  { value: 'deposit_sale', label: 'Dépôt-Vente' },
];

export const vehicleOriginOptions: OptionType[] = [
  { value: 'first_hand_french', label: 'Véhicule français première main' },
  { value: 'second_hand_french', label: "Véhicule français acheté d'occasion" },
  { value: 'dealer_purchased', label: 'Véhicule acheté chez un mandataire' },
  { value: 'pro_owned', label: 'Véhicule appartient à un pro. de l’automobile' },
  { value: 'imported', label: 'Importé' },
];

export const vehicleUseOptions: OptionType[] = [
  { value: 'personal_or_corporate', label: 'Véhicule personnel / véhicule d’entreprise' },
  { value: 'taxi_school_rental', label: 'Taxi/VTC, Auto-école, VSL, Location' },
];

export const detentionOptions: OptionType[] = [
  { value: 'less_than_one', label: 'Inférieure à 1 an' },
  { value: 'one_or_two', label: '1 à 2 ans' },
  { value: 'more_than_two', label: 'Supérieure à 2 ans' },
];

export const testDriveOptions: OptionType[] = [
  { value: 'test_done', label: 'Essai roulant réalisé' },
  { value: 'vehicle_seen_no_test', label: 'Véhicule vu / Essai roulant non réalisé' },
  { value: 'assessment_based_on_description', label: 'Expertise faite sur base description client' },
];

export const mechanicalConditionOptions: OptionType[] = [
  { value: 'running_no_issue', label: 'Roulant / Pas de problème mécanique identifié' },
  { value: 'running_with_reserve', label: 'Roulant / Réserve état mécanique' },
  { value: 'not_running', label: 'Non roulant' },
  { value: 'accidented', label: 'Accidenté' },
];

export const conditionsExpertiseOptions: OptionType[] = [
  { value: 'normal', label: 'Normale' },
  { value: 'humidity_or_wet', label: 'Humidité / Véhicule mouillé' },
  { value: 'low_light', label: 'Faible luminosité' },
];
