import { Link, Typography } from '@mui/material';

interface RegistrationLinkProps {
  id: number;
  value: string;
  brand: string;
  fuel: string;
  page: number;
  rowsPerPage: number;
  dealExists: boolean;
}

const DetailsLink: React.FC<RegistrationLinkProps> = ({ id, value, brand, fuel, dealExists }) => {
  // Ici, `dealExists` est un booléen qui indique si le deal existe ou non
  return dealExists ? (
    <>
      <Link
        target="_blank"
        href={`/car-deal/${id}?make=${brand.replace(/\s+/g, '_').toLowerCase()}&fuel=${encodeURIComponent(fuel)}`}
      >
        Fiche Skipcar
      </Link>
      <br />
      {value && (
        <Link target="_blank" href={value.toString().includes('(') ? value.toString().split('(')[0] : value}>
          Fiche Spark
        </Link>
      )}
    </>
  ) : value ? (
    <Link target="_blank" href={value.toString().includes('(') ? value.toString().split('(')[0] : value}>
      Fiche Spark
    </Link>
  ) : (
    <Typography variant="body2" sx={{ fontSize: 16 }}>
      Pas de fiche
    </Typography>
  );
};

export default DetailsLink;
