import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@mui/material';
import { useState } from 'react';
import theme from '../../theme';

const AdvantagesModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpenAdvantagesModal = () => setOpen(true);
  const handleCloseAdvantagesModal = () => setOpen(false);

  return (
    <>
      <Box textAlign="center">
        <Button
          onClick={handleOpenAdvantagesModal}
          variant="contained"
          color="primary"
          sx={{
            fontWeight: 600,
            backgroundColor: theme.palette.saffron.main,
            '&:hover': { backgroundColor: theme.palette.saffron.main },
          }}
        >
          Les Avantages Skipcar
        </Button>
      </Box>
      <Dialog open={open} onClose={handleCloseAdvantagesModal}>
        <DialogTitle align="center">Les Avantages Skipcar</DialogTitle>
        <DialogContent>
          <List sx={{ pl: 1 }}>
            {[
              'Pas d’obligation d’avoir l’agrément siv',
              'Ne gérez plus les lourdeurs administratives',
              'Suivi de vos transactions dans votre espace client',
              'Plus de stockage de documents pendant 5 ans',
              'Nous gérons le suivi client',
              'Validation de vos cartes grise en 24h',
              'Accès à notre marketplace en avant première',
            ].map((text, index) => (
              <ListItem key={index} sx={{ padding: 0, lineHeight: 0, color: 'black !important' }}>
                <ListItemIcon sx={{ minWidth: '30px', fontSize: '3rem', color: 'black !important' }}>•</ListItemIcon>
                <ListItemText
                  sx={{ margin: 0 }}
                  primary={text}
                  primaryTypographyProps={{ style: { margin: 0, color: 'black', fontWeight: 400 } }}
                />
              </ListItem>
            ))}
          </List>
          <Divider
            variant="fullWidth"
            component="hr"
            style={{
              marginLeft: 24,
              backgroundColor: '#fdd15f',
              height: '2px',
              width: 'calc(100% - 24px)',
              border: 0,
              marginTop: 15,
            }}
          />
          <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
            Comment sont calculées les taxes de la carte grise ?
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{ style: { color: 'black', fontWeight: 500 } }}
                primary="Taxe régionale (Y.1 sur le certificat d’immatriculation)"
                secondary="Calculée par le conseil régional, déterminée par la taxe par cheval fiscal (CV) de la région par la puissance fiscale du véhicule."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{ style: { color: 'black', fontWeight: 500 } }}
                primary="Taxe pour le Développement des actions de formation professionnelle (Y.2)"
                secondary="Appliquée aux véhicules utilitaires, son montant varie en fonction du PTAC."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{ style: { color: 'black', fontWeight: 500 } }}
                primary="Taxes sur les véhicules polluants (Y.3)"
                secondary="Englobant la taxe CO2 et l'écotaxe, pour les véhicules de tourisme immatriculés depuis le 1er juin 2004."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{ style: { color: 'black', fontWeight: 500 } }}
                primary="Taxe de gestion (Y.4)"
                secondary="Fixée à 11 €."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{ style: { color: 'black', fontWeight: 500 } }}
                primary="Redevance pour l’acheminement de la carte grise à domicile (Y.5)"
                secondary="Établie à 2,76 €, applicable sous conditions spécifiques."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{ style: { color: 'black', fontWeight: 500 } }}
                primary="Prix de la carte grise (Y.6)"
                secondary="Arrondi à l'euro le plus proche avant l'ajout de la redevance de 2,76 €, est la somme totale des taxes et de la redevance à payer."
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontWeight: 600,
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            onClick={handleCloseAdvantagesModal}
          >
            Compris !
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdvantagesModal;
