import { Box, Typography, Link } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const FooterWhenExternal = () => (
  <Box
    component="footer"
    sx={{
      borderTop: '1px solid grey',
      color: 'grey.900',
      padding: '20px 0 10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 1200,
        alignItems: { xs: 'center', md: 'flex-start' },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: { xs: 4, md: 0 } }}>
        <img
          src="https://admin.skipcar.fr/storage/uploads/2024/08/19/skipcar_black_uid_66c356bb6db34.svg"
          alt="skipcar_black.svg"
          style={{ height: 64, width: 'auto' }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: { xs: 4, md: 0 } }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          Entreprise
        </Typography>
        <Link
          target="_blank"
          href="https://skipcar.fr/a-propos"
          sx={{ fontFamily: 'Urbanist', color: 'inherit', textDecoration: 'none', '&:hover': { opacity: 0.75 } }}
        >
          À propos
        </Link>
        <Link
          target="_blank"
          href="https://skipcar.fr/how-it-works"
          sx={{ fontFamily: 'Urbanist', color: 'inherit', textDecoration: 'none', '&:hover': { opacity: 0.75 } }}
        >
          Comment ça marche
        </Link>
        <Link
          target="_blank"
          href="https://skipcar.fr/services"
          sx={{ fontFamily: 'Urbanist', color: 'inherit', textDecoration: 'none', '&:hover': { opacity: 0.75 } }}
        >
          Services
        </Link>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          Ressource
        </Typography>
        <Link
          target="_blank"
          href="https://skipcar.fr/voiture-occasion"
          sx={{ fontFamily: 'Urbanist', color: 'inherit', textDecoration: 'none', '&:hover': { opacity: 0.75 } }}
        >
          Nos occasions
        </Link>
        <Link
          target="_blank"
          href="https://skipcar.fr/contact"
          sx={{ fontFamily: 'Urbanist', color: 'inherit', textDecoration: 'none', '&:hover': { opacity: 0.75 } }}
        >
          Contact
        </Link>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          Suivez-nous
        </Typography>
        <Link
          href="https://www.linkedin.com/company/skipcar/"
          target="_blank"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: '50%',
            backgroundColor: 'grey.900',
            color: 'white',
            '&:hover': { backgroundColor: 'grey.700' },
          }}
        >
          <LinkedInIcon />
        </Link>
      </Box>
    </Box>
    <Box sx={{ marginTop: 4, textAlign: 'center' }}>
      <Typography variant="body2" sx={{ fontSize: 14 }}>
        © 2024 Skipcar —{' '}
        <Link
          target="_blank"
          href="https://skipcar.fr/mentions-legales"
          sx={{ color: 'inherit', textDecoration: 'none' }}
        >
          Mentions légales
        </Link>{' '}
        et{' '}
        <Link
          target="_blank"
          href="https://skipcar.fr/conditions-generales-services"
          sx={{ color: 'inherit', textDecoration: 'none' }}
        >
          CGS
        </Link>
      </Typography>
    </Box>
  </Box>
);

export default FooterWhenExternal;
