// @ts-nocheck
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Paper,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Link,
  Typography,
  TableSortLabel,
  TablePagination,
} from '@mui/material';
import { Column, PersonId, OrgId, CarId, Data } from '../constants/deals';
import { Capitalize } from '../utils';

const columns: readonly Column[] = [
  { id: 'id', label: 'ID', minWidth: 30 },
  { id: 'registration', label: 'Immat', minWidth: 100 },
  { id: 'brand', label: 'Marque', minWidth: 100 },
  { id: 'mileage', label: 'Kilométrage', minWidth: 100 },
  { id: 'formatted_value', label: 'Valeur', minWidth: 80 },
  { id: 'offer_type', label: "Type d'offre", minWidth: 80 },
  { id: 'details_link', label: 'Lien vers fiche', minWidth: 100 },
  { id: 'add_time', label: "Date d'ajout", minWidth: 100 },
  { id: 'update_time', label: 'Date de modification', minWidth: 100 },
  { id: 'stage_change_time', label: "Dernier changement d'état", minWidth: 100 },
  { id: 'stage_id', label: 'StageID', minWidth: 50 },
  { id: 'last_activity_id', label: 'lastActivityId', minWidth: 50 },
];

const createData = (
  id: number,
  registration: string,
  brand: string,
  mileage: string,
  formatted_value: string,
  offer_type: string,
  details_link: string,
  add_time: string,
  update_time: string,
  stage_change_time: string,
  stage_id: number,
  last_activity_id: number,

  title: string,
  active: string,
  status: string,
  org_id: OrgId,
  person_id: PersonId,
  car_id: CarId,
): Data => {
  return {
    id,
    registration,
    brand,
    mileage,
    formatted_value,
    offer_type,
    details_link,
    add_time,
    update_time,
    stage_change_time,
    stage_id,
    last_activity_id,
    title,
    active,
    status,
    org_id,
    person_id,
    car_id,
  };
};

const DealsByOrganization = ({ org_id }) => {
  // const [deals, setDeals] = useState<any[]>([]);
  const [rows, setRows] = useState<Data[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortConfig, setSortConfig] = useState({ key: 'org_name', direction: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [isLoading, setIsLoading] = useState(true);

  const onSort = (columnId: string) => {
    const direction = sortConfig.key === columnId && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: columnId, direction });
    const sortedRows = [...rows].sort((a, b) => {
      if (a[columnId] < b[columnId]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[columnId] > b[columnId]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setRows(sortedRows);
  };

  const handleRowClick = (row: Data) => {
    setSelectedItem(row);
    setIsDrawerOpen(true);
  };

  const fetchData = async (page: number, rowsPerPage: number) => {
    try {
      setIsLoading(true);

      const response = await axios.get(
        `https://skipcar3.pipedrive.com/v1/organizations/${org_id}/deals?api_token=${
          import.meta.env.VITE_PIPEDRIVE_API_KEY
        }&sort=id%20DESC&status=all_not_deleted`,
      );

      if (response.data.data) {
        const filteredDeals = response.data.data.filter((deal: { pipeline_id: number }) => deal.pipeline_id !== 6); // Exclude the "01 - New Subscription Skipcar"
        setDataRows(filteredDeals);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des deals:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const setDataRows = (deals: any[]) => {
    const newRows = deals.map((deal) => {
      const car_id = {
        model: deal['02925a65d0ea914762f8a702363401fe042c0682'],
        version: deal['9de2c63437cba2b024fc3255c22d4121bbbecbd0'],
        brand: Capitalize(deal['5443f60884f02f4aebb678c1b031f4cdf2f1bdd7']),
        mileage: deal['3ac3c99b1ece74dc410165422b1687afeead3334'] + ' km',
        fuel: deal['bcb5ff5c08492bc23658db0a705383effa6fcd7d'],
        color: deal['453ac647ce769c38579a91a07f4b56bba3cb819f'],
        motorization: deal['425765b3b2cde03186080fcaa72cc367876154ed'],
        transmission: deal['dca90049866d5fa2089f3d10b4da9dc6711c497e'],
        date_of_circulation: deal['79ac7ef7489f93453caeb3871c7bd293c7162278'],
        registration: deal['9b46fdc3826bd4640f115914543718da6f786f37'],
        vehicle_id: deal['81d27549b4122664b81ede974fdf0b0915aa2b57'],
        registration_certificate_number: deal['0852697ff2416bc63c1706823b1ae771143229f9'],
        options: deal['011b8243b3763dab3ca191fdc912fe2a9ef3aff7'],
      };

      return createData(
        deal.id,
        car_id.registration,
        car_id?.brand,
        car_id.mileage,
        deal.formatted_value,
        deal['3ea11b0c87c7bdb47335b1dd201040b9cefc143d'], // offer_type | 21 : reprise sèche | 19 : Mandat 30 jours
        deal['81292394d28a9b8722d4f9c45bde4bcc52d1a973'], // details link
        deal.add_time,
        deal.update_time,
        deal.stage_change_time,
        deal.stage_id,
        deal.last_activity_id,
        deal.title,
        deal.active,
        deal.status,
        deal.org_id,
        deal.person_id,
        car_id,
      );
    });
    setRows(newRows);
  };

  const count = 6000; // Trouver le moyen d'avoir le vrai compte

  return (
    <>
      <Paper>
        {isLoading ? (
          <div>Loading...</div> // Replace this with your actual loader component
        ) : (
          <TableContainer>
            <TablePagination
              rowsPerPageOptions={[100, 200, 500]}
              component="div"
              rowsPerPage={rowsPerPage}
              count={count}
              page={page}
              onPageChange={(_event, newPage) => {
                setPage(newPage);
              }}
              onRowsPerPageChange={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10);
                setRowsPerPage(newRowsPerPage);
              }}
            />
            <Table stickyHeader aria-label="sticky table" size={'small'}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align || 'left'}
                      style={{ minWidth: column.minWidth }}
                      sortDirection={sortConfig.key === column.id ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.key === column.id}
                        direction={sortConfig.direction}
                        onClick={() => onSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover key={row.id} onClick={() => handleRowClick(row)} role="checkbox" tabIndex={-1}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align || 'left'}>
                            {/* column.id === 'title' ? (
                              'tooltip à ajouter avec la clef 02925a65d0ea914762f8a702363401fe042c0682'
                            ) : null
                            {column.id === 'active' ? (
                              value ? (
                                <Typography variant="body1">Oui</Typography>
                              ) : (
                                <Typography variant="body1">Non</Typography>
                              )
                            ) : null}
                            */}
                            {column.id !== 'details_link' ? (
                              value
                            ) : (
                              <>
                                {value ? (
                                  value.toString().includes('(') ? (
                                    <Link target="_blank" href={value.toString().split('(')[0]}>
                                      Cliquez ici
                                    </Link>
                                  ) : (
                                    <Link target="_blank" href={value}>
                                      Cliquez ici
                                    </Link>
                                  )
                                ) : (
                                  <Typography variant="body2" sx={{ fontSize: 16 }}>
                                    Pas de fiche
                                  </Typography>
                                )}
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[100, 200, 500]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_event, newPage) => {
                setPage(newPage);
              }}
              onRowsPerPageChange={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10);
                setRowsPerPage(newRowsPerPage);
              }}
            />
          </TableContainer>
        )}
      </Paper>
    </>
  );
};

export default DealsByOrganization;
