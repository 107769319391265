import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import {
  Paper,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TextField,
  Link,
  Typography,
  TableSortLabel,
} from '@mui/material';
import '../constants/companies';

const columns: readonly Column[] = [
  { id: 'id', label: 'ID', minWidth: 30 },
  { id: 'name', label: 'Société', minWidth: 150 },
  { id: 'address_postal_code', label: 'Code Postal', minWidth: 100 },
  { id: 'address_locality', label: 'Ville', minWidth: 150 },
  { id: 'open_deals_count', label: 'Deals ouverts', minWidth: 50 },
  { id: 'closed_deals_count', label: 'Deals clos', minWidth: 50 },
  { id: 'lost_deals_count', label: 'Deals perdus', minWidth: 50 },
  { id: 'next_activity_id', label: 'Prochaine activité', minWidth: 30 },
  { id: 'next_activity_date', label: 'Date Prochaine activité', minWidth: 50 },
  { id: 'last_activity_id', label: 'Dernière activité', minWidth: 30 },
  { id: 'last_activity_date', label: 'Date Dernière activité', minWidth: 50 },
  { id: 'add_time', label: 'Date de création', minWidth: 100 },
  { id: 'update_time', label: 'Date de modification', minWidth: 100 },
];

const renderCellValue = (
  columnId: string,
  value:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined,
  row: { [x: string]: any },
) => {
  switch (columnId) {
    case 'name':
      return (
        <Link target="_self" href={value ? `deals-by-organization/${row['id']}` : ''}>
          {value}
        </Link>
      );
    default:
      return value;
  }
};

type Data = {
  [key: string]: any;
  id: number;
  name: string;
  // ... (vos autres propriétés)
};

const createData = (
  id: number,
  name: string,
  address_postal_code: string,
  address_locality: string,
  open_deals_count: number,
  closed_deals_count: number,
  lost_deals_count: number,
  next_activity_id: string,
  next_activity_date: string,
  last_activity_id: string,
  last_activity_date: string,
  add_time: string,
  update_time: string,
): Data => {
  return {
    id,
    name,
    address_postal_code,
    address_locality,
    open_deals_count,
    closed_deals_count,
    lost_deals_count,
    next_activity_id,
    next_activity_date,
    last_activity_id,
    last_activity_date,
    add_time,
    update_time,
  };
};

const CompaniesList = () => {
  const [rows, setRows] = useState<Data[]>([]);
  const [sortConfig, setSortConfig] = useState({ key: 'org_name', direction: 'asc' });
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [initialActivities, setInitialActivities] = useState<any[]>([]);

  // Fetch the activities on initial load and store them in the state
  useEffect(() => {
    fetchAllActivities().then((activities: SetStateAction<any[]>) => {
      setInitialActivities(activities);
    });
  }, []);

  function getSortDirection(columnId: string): 'asc' | 'desc' | undefined {
    if (sortConfig.key === columnId) {
      if (sortConfig.direction === 'asc' || sortConfig.direction === 'desc') {
        return sortConfig.direction;
      }
    }
    return undefined;
  }

  const onSort = (columnId: string) => {
    const direction = sortConfig.key === columnId && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: columnId, direction });
    const sortedRows = [...rows].sort((a, b) => {
      if (a[columnId] < b[columnId]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[columnId] > b[columnId]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setRows(sortedRows);
  };

  const handleSearchChange = (event: { target: { value: SetStateAction<string> } }) => {
    setSearchTerm(event.target.value);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://skipcar3.pipedrive.com/v1/organizations?api_token=${
          import.meta.env.VITE_PIPEDRIVE_API_KEY
        }&limit=500&sort=id%20DESC`,
      );

      const activitySubjects = await (async () => {
        if (initialActivities?.length === 0) {
          const activities = await fetchAllActivities();
          setInitialActivities(activities);
          return activities;
        } else {
          return initialActivities;
        }
      })();

      setDataRows(response.data.data, activitySubjects);
    } catch (error) {
      console.error('Erreur lors de la récupération des companies:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchTerm?.length > 2) {
      setFilteredData(rows.filter((row) => row.name.toLowerCase().includes(searchTerm.toLowerCase())));
    } else {
      setFilteredData(rows);
    }
  }, [searchTerm]);

  const setDataRows = (companies: any[], activitySubjects: { [key: number]: string }) => {
    const newRows = companies.map((deal) =>
      createData(
        deal.id,
        deal.name,
        deal.address_postal_code,
        deal.address_locality,
        deal.open_deals_count,
        deal.closed_deals_count,
        deal.lost_deals_count,
        activitySubjects[deal.last_activity_id] || 'N/A', // Use the subject or 'N/A' if not found
        deal.next_activity_date,
        activitySubjects[deal.last_activity_id] || 'N/A', // Use the subject or 'N/A' if not found
        deal.last_activity_date,
        deal.add_time,
        deal.update_time,
      ),
    );
    setRows(newRows);
  };

  async function fetchAllActivities(start = 0, limit = 100) {
    const endpoint = `https://skipcar3.pipedrive.com/v1/activities/?api_token=${
      import.meta.env.VITE_PIPEDRIVE_API_KEY
    }&limit=${limit}&start=${start}`;

    // Fetch the activities from the API
    const response = await axios.get(endpoint);

    // Check if the response was successful and contained data
    if (response.data.success && response.data.data) {
      // Map the data into a {id: subject} format
      const activities = response.data.data.reduce(
        (map: { [x: string]: any }, activity: { id: string | number; subject: any }) => {
          map[activity.id] = activity.subject;
          return map;
        },
        {},
      );

      // If there are more items in the collection, fetch the next page
      if (response.data.additional_data.pagination.more_items_in_collection) {
        const nextStart = response.data.additional_data.pagination.next_start;
        const moreActivities: any = await fetchAllActivities(nextStart, limit);
        return { ...activities, ...moreActivities };
      }

      // If there are no more items, return the activities fetched so far
      return activities;
    } else {
      throw new Error('Failed to fetch activities');
    }
  }

  return (
    <>
      <Paper>
        <TextField
          id="search-field"
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ marginBottom: '1rem' }}
        />
        {isLoading ? (
          <div>Loading...</div> // Replace this with your actual loader component
        ) : (
          <>
            <Typography variant="h5" component="h2">
              Number of companies : {(filteredData && searchTerm?.length > 2 ? filteredData : rows).length}
            </Typography>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow key="table-head">
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        sortDirection={getSortDirection(column.id)}
                      >
                        <TableSortLabel
                          active={sortConfig.key === column.id}
                          direction={
                            sortConfig.key === column.id
                              ? sortConfig.direction === 'asc' || sortConfig.direction === 'desc'
                                ? sortConfig.direction
                                : undefined
                              : undefined
                          }
                          onClick={() => onSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(filteredData && searchTerm.length > 2 ? filteredData : rows).map((row) => {
                    return (
                      <TableRow hover key={row.id} role="checkbox" tabIndex={-1}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {renderCellValue(column.id, value, row)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Paper>
    </>
  );
};

export default CompaniesList;
