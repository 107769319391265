import {
  AlertColor,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import 'moment-timezone';
import { DBDeal } from '../../../types/deal';
import { useEffect, useState } from 'react';
import { makeAuthenticatedApiCall } from '../../../apiHelper';
import { formatDateToDDMMYYYY, getCleanPrice } from '../../../utils';
import { useDealMisivC } from '../../../hooks/useApiQueries';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ErrorMessageSnackbar } from '../../ErrorMessageSnackbar';

interface LogbookProps {
  deal: DBDeal;
  pipelineLetter: string;
}

const Logbook: React.FC<LogbookProps> = ({ deal, pipelineLetter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLogbookSending, setIsLogbookSending] = useState(false);
  const [showLogbookButton, setShowLogbookButton] = useState(true);
  const [logbookConfirmation, setLogbookConfirmation] = useState<string>('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('error');
  const [errorText, setErrorText] = useState<string>('');

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [purchaseDateTime, setPurchaseDateTime] = useState('');
  const [sellingPrice, setSellingPrice] = useState(getCleanPrice(deal));

  const updatedDeal = { ...deal, purchaseDateTime, sellingPrice };

  const { data: misivDealData } = useDealMisivC(deal.id);

  useEffect(() => {
    const determineButtonState = () => {
      const data = misivDealData?.data && misivDealData.data.getDealMisivFromDB[0];
      if (!data) return;

      const { lpn_id, step, last_update } = data;

      if (!lpn_id && step !== 2) {
        setShowLogbookButton(true);
      } else if (lpn_id && step === 2) {
        setLogbookConfirmation(`✅ Inscrit au LPN le : ${formatDateToDDMMYYYY(last_update)}`);
        setShowLogbookButton(false);
      }
    };

    determineButtonState();
  }, [misivDealData]);

  const formMethods = useForm({
    mode: 'all',
  });
  const { handleSubmit, control, formState, trigger } = formMethods;
  const { errors, isValid } = formState;

  const handleConfirmClick = () => {
    setIsConfirmModalOpen(true); // Ouvrir la modale de confirmation au lieu de soumettre directement
  };

  const handleFinalConfirm = async () => {
    setIsConfirmModalOpen(false); // Fermer la modale de confirmation
    handleDialogClose(); // Fermer la modale principale
    const formData = formMethods.getValues(); // Récupérer les données du formulaire actuel
    await handleSendToLogbook(formData); // Appeler la fonction pour soumettre
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSendToLogbook = async (formData: any) => {
    setIsLogbookSending(true);
    setShowLogbookButton(true);
    updatedDeal.sellingPrice = formData.sellingPrice;
    console.log('updatedDeal', updatedDeal);

    const formattedPurchaseDateTime = moment(purchaseDateTime).format('DD/MM/YYYY HH:mm:ss');

    try {
      const responseSave = await makeAuthenticatedApiCall('post', `/api/misiv/deals/c/save`, {
        updatedDeal,
        formattedPurchaseDateTime,
      });
      console.log('response save', responseSave);

      try {
        const response = await makeAuthenticatedApiCall('post', `/api/misiv/send-to-logbook`, {
          updatedDeal,
          pipelineLetter,
        });
        console.log('response logbook', response);
        setLogbookConfirmation('✅ Inscrit au LPN');
        setErrorText('');
      } catch (error: any) {
        console.error(error);
        openSnackbar(error.response.data, 'error');
        setErrorText(error.response.data);
        setShowLogbookButton(true);
      } finally {
        setIsLogbookSending(false);
      }
    } catch (error: any) {
      console.error(error);
      openSnackbar(error.response.data, 'error');
      setErrorText(error.response.data);
      setIsLogbookSending(false);
      setShowLogbookButton(true);
    } finally {
      setIsLogbookSending(false);
    }
  };

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  return (
    <>
      {showLogbookButton && (
        <Button
          onClick={() => setIsDialogOpen(true)}
          sx={{
            margin: '8px auto 0',
            width: 180,
          }}
          disabled={isLogbookSending}
          variant="contained"
        >
          {isLogbookSending ? (
            <>
              <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} /> Envoi au LPN en cours
            </>
          ) : (
            '📄 Envoyer au LPN'
          )}
        </Button>
      )}
      {logbookConfirmation && (
        <Typography variant="body2" align="center" sx={{ marginTop: 1, fontSize: 16 }}>
          {logbookConfirmation}
        </Typography>
      )}
      {errorText && (
        <Typography
          variant="body2"
          sx={{
            fontSize: '12px',
            maxWidth: '200px',
            lineHeight: '1.25',
            marginTop: 1,
            fontWeight: 'medium',
            color: 'red !important',
          }}
        >
          {errorText}
        </Typography>
      )}
      <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="lg">
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleSendToLogbook)}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                  <Typography variant="body1" textAlign="center">
                    Veuillez vérifier les informations provenants de Skipcar.
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    Nous les utilisons pour faire l'envoi au SIV.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="purchaseDateTime"
                    label="Date et heure de la vente"
                    type="datetime-local"
                    fullWidth
                    value={purchaseDateTime || ''}
                    onChange={(e) => setPurchaseDateTime(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="sellingPrice"
                    control={control}
                    defaultValue={getCleanPrice(deal)}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.sellingPrice}
                        className="inputElement"
                        margin="dense"
                        id="sellingPrice"
                        label="Prix de vente"
                        type="text"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                              €
                            </Typography>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Annuler</Button>
              <Button variant="contained" onClick={handleConfirmClick} disabled={!isValid || !purchaseDateTime}>
                Soumettre
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
      {/* Modale de confirmation */}
      <Dialog open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
        <DialogContent>
          <Typography>Êtes-vous sûr que toutes les informations sont correctes ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmModalOpen(false)}>Annuler</Button>
          <Button onClick={handleSubmit(handleFinalConfirm)}>Confirmer</Button>
        </DialogActions>
      </Dialog>
      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
    </>
  );
};

export default Logbook;
