import { Typography } from '@mui/material';
import theme from '../../theme';

interface FilesOpenerProps {
  FilesUrls: string;
}

const FilesOpener: React.FC<FilesOpenerProps> = ({ FilesUrls }) => {
  return (
    <Typography variant="body2" component="div" textAlign={'center'} display={'flex'}>
      {FilesUrls.split(',').map((url: string, index: number) => {
        const decodedUrl = decodeURIComponent(url);
        const isImage =
          decodedUrl.includes('jpg') ||
          decodedUrl.includes('jpeg') ||
          decodedUrl.includes('png') ||
          decodedUrl.includes('gif');
        return (
          <div key={index} style={{ margin: '10px', textAlign: 'left' }}>
            {isImage ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                <img src={url} alt="thumbnail" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
              </a>
            ) : (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'black',
                  background: theme.palette.saffron.main,
                  fontSize: '15px',
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                Fichier {index + 1}
              </a>
            )}
          </div>
        );
      })}
    </Typography>
  );
};

export default FilesOpener;
