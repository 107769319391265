import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_fr from './locales/fr/common.json';

i18next
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        common: common_fr // Assign french translations to 'common' namespace
      },
      en: {
        common: {} // Add your English translations here
      }
    },
    lng: 'fr', // 'lng' is correct
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    saveMissing: true,
    ns: ['common'], // Specify your namespace(s)
    defaultNS: 'common' // Specify your default namespace
  });
  
export default i18next;
