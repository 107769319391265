export interface Person {
  active_flag: boolean;
  name: string;
  email: Email[];
  phone: Phone[];
  owner_id: number;
  value: number;
}

export interface Organization {
  name: string;
  people_count: number;
  owner_id: number;
  address: string;
  active_flag: boolean;
  cc_email: string;
  owner_name: string;
  value: number;
}

export interface Email {
  label: string;
  value: string;
  primary: boolean;
}

export interface Phone {
  label: string;
  value: string;
  primary: boolean;
}

export interface PipedriveDealData {
  id: number;
  add_time: string;
  org_name: string;
  update_time: string;
  stage_id: number;
  pipeline_id: number;
  title: string;
  active: string;
  status: string;
  org_id: Organization;
  person_id: Person;
  model: string;
  date_of_circulation: string;
  fuel: string;
  brand: string;
  mileage: string;
  final_purchase_price: string;
  encheres_vo_price: string;
  offer_type: string;
  purchase_price: string;
  deposit_sale_arbitrator: string;
  straight_trade_in_arbitrator: string;
  thirty_day_sale_mandate_arbitrator: string;
  deposit_sale_supervisor: string;
  straight_trade_in_supervisor: string;
  thirty_day_sale_mandate_supervisor: string;
  details_link: string;
  version: string;
  color: string;
  motorization: string;
  transmission: string;
  registration: string;
  registration_certificate_number: string;
  options: string;
  data_store: string;
  repatriation_date: string;
  vin: string;
  num_formule: string;
  sold_mandate_date: string;
}

export const createData = (data: PipedriveDealData): PipedriveDealData => data;

export interface Column {
  id:
    | 'id'
    | 'add_time'
    | 'org_name'
    | 'registration'
    | 'brand'
    | 'model'
    | 'mileage'
    | 'date_of_circulation'
    | 'fuel'
    | 'offer_type'
    | 'details_link'
    | 'add_time'
    | 'update_time'
    | 'stage_id';
  label: string;
  minWidth?: number;
  visibleForRoles: string[];
  showOnMobile: boolean;
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify' | undefined;
  format?: (value: number) => string;
}

export const columns: Column[] = [
  {
    id: 'id',
    label: 'ID',
    minWidth: 50,
    visibleForRoles: ['god', 'arbitrator', 'head_of_network', 'agency'],
    showOnMobile: false,
  },
  {
    id: 'add_time',
    label: 'Date',
    minWidth: 30,
    visibleForRoles: ['god', 'arbitrator', 'head_of_network', 'agency'],
    showOnMobile: false,
  },
  {
    id: 'org_name',
    label: 'Société',
    minWidth: 150,
    visibleForRoles: ['god', 'arbitrator', 'head_of_network'],
    showOnMobile: false,
  },
  {
    id: 'registration',
    label: 'Immat',
    minWidth: 150,
    visibleForRoles: ['god', 'arbitrator', 'head_of_network', 'agency'],
    showOnMobile: true,
  },
  {
    id: 'brand',
    label: 'Marque',
    minWidth: 100,
    visibleForRoles: ['god', 'arbitrator', 'head_of_network', 'agency'],
    showOnMobile: true,
  },
  {
    id: 'model',
    label: 'Modèle',
    minWidth: 100,
    visibleForRoles: ['god', 'arbitrator', 'head_of_network', 'agency'],
    showOnMobile: true,
  },
  // ... autres colonnes ...
  {
    id: 'mileage',
    label: 'Kilométrage',
    minWidth: 80,
    visibleForRoles: ['god', 'arbitrator', 'agency'],
    showOnMobile: false,
  },
  { id: 'fuel', label: 'Carburant', minWidth: 20, visibleForRoles: ['god', 'arbitrator'], showOnMobile: false },
  {
    id: 'date_of_circulation',
    label: 'MEC',
    minWidth: 80,
    visibleForRoles: ['god', 'arbitrator'],
    showOnMobile: false,
  },
  {
    id: 'offer_type',
    label: "Type d'offre",
    minWidth: 120,
    visibleForRoles: ['god', 'arbitrator'],
    showOnMobile: false,
  },
  {
    id: 'stage_id',
    label: 'Étapes / Actions',
    minWidth: 150,
    visibleForRoles: ['god', 'arbitrator', 'head_of_network', 'agency'],
    showOnMobile: false,
    align: 'center',
  },
  {
    id: 'details_link',
    label: 'Lien vers fiche',
    minWidth: 100,
    visibleForRoles: ['god', 'arbitrator', 'head_of_network', 'agency'],
    showOnMobile: false,
  },
  // { id: 'add_time', label: "Date d'ajout de l'agence", minWidth: 100, visibleForRoles: ['god', 'head_of_network'] },
];

/*
 '02925a65d0ea914762f8a702363401fe042c0682': stringmodel
  '79ac7ef7489f93453caeb3871c7bd293c7162278': stringdate_of_circulation
  bcb5ff5c08492bc23658db0a705383effa6fcd7d: stringfuel
  '5443f60884f02f4aebb678c1b031f4cdf2f1bdd7': stringbrand
  '3ac3c99b1ece74dc410165422b1687afeead3334': stringmileage
  '765c004cfa164d3dcc2c66a08236447d026a1d1f': stringfinal_purchase_price
  a64377fc6d9068a19cc139acae38dbf8ca4685bf: stringencheres_vo_price
  '3ea11b0c87c7bdb47335b1dd201040b9cefc143d': stringoffer_type
  '1443a395cef3c44782b22d8bb88b077b91aef48d': stringpurchase_price
  b59078b8623241e5fc580aa2ffcc8b417f97b695: stringdeposit_sale_arbitrator
  '1a516ffc98b7e9cd2f58ca58b46c0945e069763c': stringstraight_trade_in_arbitrator
  '47cfbd6239cd6fe0504217d35988ca225c081eee': stringthirty_day_sale_mandate_arbitrator
  '3cc647d97d41a1e8562e3457d28202b1f8cb1a73': stringdeposit_sale_supervisor
  '5d0a90c6c3c4676d8b9a14cbf4191289b1afc278': stringstraight_trade_in_supervisor
  ccc37c74631e324c69d3ca085897a04b8f864c82: stringthirty_day_sale_mandate_supervisor
  '81292394d28a9b8722d4f9c45bde4bcc52d1a973': stringdetails_link
  '9de2c63437cba2b024fc3255c22d4121bbbecbd0': stringversion
  '453ac647ce769c38579a91a07f4b56bba3cb819f': stringcolor
  '425765b3b2cde03186080fcaa72cc367876154ed': stringmotorization
  dca90049866d5fa2089f3d10b4da9dc6711c497e: stringtransmission
  '9b46fdc3826bd4640f115914543718da6f786f37': stringreg_plate
  '0852697ff2416bc63c1706823b1ae771143229f9': stringregistration_certificate_number
  '011b8243b3763dab3ca191fdc912fe2a9ef3aff7': stringoptions
  b8cd89664c069e066439ee3d0a13df5c96c5a1f6: stringdata_store
  '2b9fff9d7952eaca442be026f7593a85632cce68': stringrepatriation_date
  '81d27549b4122664b81ede974fdf0b0915aa2b57': stringvin
  */
