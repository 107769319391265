import { DBDeal } from '../../types/deal';

export function isInMarketPlace(
  car: Pick<DBDeal, 'marketplace_deleted' | 'marketplace_sent_date' | 'marketplace_expiration_date'>,
): boolean {
  const now = Date.now();
  return Boolean(
    car.marketplace_deleted !== 1 &&
      car.marketplace_sent_date &&
      new Date(car.marketplace_sent_date).getTime() <= now &&
      (!car.marketplace_expiration_date || new Date(car.marketplace_expiration_date).getTime() > now),
  );
}
