import { Link } from '@mui/material';

interface OrgNameLinkProps {
  row: {
    org_id: number;
    [key: string]: any; // pour d'autres propriétés non spécifiées
  };
  value: string | null | undefined;
}

export const OrgNameLink: React.FC<OrgNameLinkProps> = ({ row, value }) => (
  <Link target="_self" href={value ? `/deals-list/agency/${row['org_id']}` : ''}>
    {value}
  </Link>
);
