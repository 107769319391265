import axios from 'axios';
import { fuelOptions } from './constants/cotation';
import { makeAuthenticatedApiCall } from './apiHelper';

export const Capitalize = (str: string) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeAndFormat = (str: string) => {
  if (!str) return str;

  // Diviser la chaîne en mots en utilisant l'underscore comme séparateur
  const words = str.split('_');

  // Capitaliser la première lettre de chaque mot
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  // Joindre les mots avec un espace et retourner le résultat
  return capitalizedWords.join(' ');
};

// Create our number formatter.
export const priceFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const numberFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'decimal',
  maximumFractionDigits: 0,
});

export const formatKilometers = (kilometers: number): string => {
  if (kilometers) {
    const formattedKilometers = kilometers?.toLocaleString('fr-FR', {
      maximumFractionDigits: 0, // Pas de décimales
    });

    // Ajoute "km" à la fin du résultat
    return `${formattedKilometers} km`;
  }
  return '';
};

export const formatString = (str: string) => {
  if (str === '') return '';

  // 1. Divisez la chaîne de caractères par `:`.
  const [initialKey, initialValue] = str.split(':').map((s) => s.trim());

  // 2. Capitalisez la première lettre de chaque mot du premier segment.
  const key = initialKey
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  // 3. Capitalisez la première lettre de chaque mot du second segment après `-`.
  const value = initialValue.includes('-')
    ? initialValue
        .split('-')
        .map((s) => s.trim())
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' - ')
    : initialValue.charAt(0).toUpperCase() + initialValue.slice(1);

  // 4. Remettez les deux segments ensemble en les joignant avec `:`.
  return `${key} : ${value}`;
};

export const formatDamages = (damagesString: string): string[] => {
  try {
    const damagesArray: string[] = JSON.parse(damagesString);
    return damagesArray.map((damage) => formatString(damage)).filter((damage) => damage.trim() !== '');
  } catch (error) {
    console.error('Erreur lors du formatage des damages:', error);
    return []; // Retournez un tableau vide en cas d'erreur
  }
};

export const convertToISO = (dateTimeStr: string) => {
  // Séparation de la date et de l'heure
  const [date, time] = dateTimeStr.split(' ');

  // Séparation des composants de la date
  const [day, month, year] = date.split('/');

  // Concaténation pour former la nouvelle chaîne de date-heure
  const newDateTime = `${year}-${month}-${day}T${time}`;

  // Création d'un objet Date pour s'assurer que le format est correct
  const dateObj = new Date(newDateTime);

  // Retourner la date en format ISO 8601
  return dateObj.toISOString().slice(0, 19);
};
export const formatDateToDDMMYYYY = (dateString: string) => {
  if (dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés de 0-11, donc on ajoute 1
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  } else return '';
};

export const formatDateToYYYYMMDD = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés de 0-11, donc on ajoute 1
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const formatDateToDDMMYYYYWithTime = (utcDateString: string) => {
  // Remplacer les espaces par des 'T' et ajouter 'Z' pour indiquer l'heure UTC
  const isoDateString = utcDateString.replace(' ', 'T') + 'Z';

  // Convertir la chaîne de date UTC en objet Date
  const utcDate = new Date(isoDateString);

  // Ajouter l'offset UTC et l'offset de Paris (heure d'été prise en compte)
  const offset = utcDate.getTimezoneOffset() * 60000; // Offset UTC en millisecondes
  const parisOffset = offset + 3600000; // *1 *2 Paris est UTC+1 ou UTC+2

  // Calculer la date et l'heure de Paris
  const parisDate = new Date(utcDate.getTime() + parisOffset);

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Paris',
  } as Intl.DateTimeFormatOptions;

  // Formater la date en français
  return parisDate.toLocaleString('fr-FR', options);
};

export const formatSimpleDateToDDMMYYYYWithTime = (utcDateString: string) => {
  const isoDateString = utcDateString.replace(' ', 'T');

  // Créer un objet Date à partir de la chaîne de date ISO
  const date = new Date(isoDateString);

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Paris',
  } as Intl.DateTimeFormatOptions;

  // Formater la date en français
  return date.toLocaleString('fr-FR', options);
};

export const getFuelValueFromLabel = (label: string): string => {
  const option = fuelOptions.find((option) => option.label === label);
  return option ? option.value : '';
};

// Fonction pour déterminer si l'URL est un PDF
export const isPdf = (file: File | string | null | undefined) => {
  // Vérifiez si 'file' est une chaîne de caractères
  if (typeof file === 'string') {
    return file.includes('.pdf');
  }
  // Vérifiez si 'file' est un objet 'File' et non null/undefined avant d'accéder à 'type'
  return file?.type === 'application/pdf';
};

export const extractFileName = (url: string) => {
  return decodeURIComponent(url?.split('/')?.pop()?.split('?')[0] ?? '');
};

export const formatPriceWithoutSpaces = (priceStr: string): string => {
  // Retirer les espaces et convertir en nombre
  const price = parseFloat(priceStr.replace(/\s+/g, ''));

  // Vérifier si le nombre est valide
  if (isNaN(price)) {
    return '0 €'; // ou toute autre valeur par défaut en cas d'erreur
  }

  // Formater le nombre en format monétaire
  return priceFormatter.format(price);
};

// Fonction pour convertir une chaîne base64 en Blob
export function base64ToBlob(base64: string, mimeType: string) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

// Fonction pour formater la plaque d'immatriculation
export const formatRegPlate = (regPlate: string) => {
  // Vérifie si regPlate est null ou undefined
  if (!regPlate) {
    return null; // ou retourner regPlate pour garder la valeur originale
  }
  const cleaned = regPlate.replace(/[-\s]/g, '').toUpperCase();
  const formatted = cleaned.replace(/^(.{2})(.{3})(.{2})$/, '$1-$2-$3');
  return isValidRegPlateFormat(formatted) ? formatted : regPlate;
};

const isValidRegPlateFormat = (regPlate: string) => /^[A-Z]{2}-\d{3}-[A-Z]{2}$/.test(regPlate);

export const getPipedriveDealData = async (dealId: number) => {
  const response = await axios.get(`/api/pipedrive/deals/${dealId}`);
  return response.data;
};

export const getPipedrivePersonData = async (personId: number) => {
  const response = await axios.get(`/api/pipedrive/persons/${personId}`);
  return response.data;
};

export const getPipedriveOrgData = async (orgId: number) => {
  const response = await axios.get(`/api/pipedrive/organizations/${orgId}`);
  return response.data;
};

export const getAdresseTypeVoie = (route: string) => {
  if (!route) return { typeVoie: '', nomVoie: '' };

  const typesVoie = ['Rue', 'Boulevard', 'Avenue', 'Chemin', 'Allée', 'Place', 'Quai', 'Impasse', 'Route'];

  const typeVoieTrouve = typesVoie.find((type) => route.startsWith(type));

  if (typeVoieTrouve) {
    const nomVoie = route.replace(typeVoieTrouve, '').trim(); // Enlève le type de voie et nettoie les espaces
    return { typeVoie: typeVoieTrouve, nomVoie };
  }

  return { typeVoie: '', nomVoie: route }; // Si aucun type de voie n'est trouvé, retourne la route entière
};

export const documentTypes: Record<string, { label: string; dbValue: string }> = {
  '64': { label: 'CI', dbValue: 'id_card' },
  '65': { label: 'Passeport', dbValue: 'passport' },
  '66': { label: 'Permis de conduire', dbValue: 'driving_licence' },
  '70': { label: 'Titre de séjour', dbValue: 'residence_permit' },
};

export const documentTypesDb: Record<string, string> = {
  CI: 'id_card',
  Passeport: 'passport',
  'Permis de conduire': 'driving_licence',
  'Titre de séjour': 'residence_permit',
};

export const genderTypes: Record<string, { label: string; value: string }> = {
  '44': { label: 'Masculin', value: 'male' },
  '45': { label: 'Féminin', value: 'female' },
};

export const formatPriceToNumber = (priceStr: any): number => {
  // S'assurer que priceStr est une chaîne
  const priceString = String(priceStr);

  // Retirer les espaces et convertir en nombre
  const price = parseFloat(priceString.replace(/\s+/g, '').replace('€', ''));

  // Vérifier si le nombre est valide
  if (isNaN(price)) {
    return 0; // ou toute autre valeur par défaut en cas d'erreur
  }

  // Retourner le nombre sans formatage monétaire
  return price;
};

export function getCleanPrice(data: any) {
  const { final_purchase_price, purchase_price } = data;

  // Fonction pour nettoyer et convertir le prix en nombre
  const cleanAndParsePrice = (price: string) => {
    return parseInt(price.replace(/[^\d]/g, ''), 10);
  };

  // Supprime tous les types d'espaces blancs et caractères non-numériques
  const cleanedFinalPurchasePrice = final_purchase_price.replace(/\s/g, '').replace('€', '');

  // Vérification si final_purchase_price est "0" après le nettoyage
  if (cleanedFinalPurchasePrice === '0') {
    return cleanAndParsePrice(purchase_price);
  } else {
    return cleanAndParsePrice(final_purchase_price);
  }
}

// Fonction pour formater la date actuelle en DD/MM/YYYY
export function getFormattedCurrentDate() {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés à partir de 0
  const year = today.getFullYear();
  return `${day}/${month}/${year}`;
}

// Fonction pour comparer deux dates et vérifier si la seconde est plus vieille de 9 ans ou plus
export function isDateMoreThan9YearsOld(dateToCompare: string) {
  const currentDate = new Date();
  const compareDate = createDateFromString(dateToCompare);

  const yearsDifference = currentDate.getFullYear() - compareDate.getFullYear();
  const monthsDifference = currentDate.getMonth() - compareDate.getMonth();
  const daysDifference = currentDate.getDate() - compareDate.getDate();

  // Si l'écart est de 9 ans mais que le mois/jour courant n'est pas encore passé, soustraire 1 de l'écart d'années
  if (
    yearsDifference > 9 ||
    (yearsDifference === 9 && (monthsDifference > 0 || (monthsDifference === 0 && daysDifference >= 0)))
  ) {
    return true;
  } else {
    return false;
  }
}

// Convertit une chaîne DD/MM/YYYY en un objet Date
export function createDateFromString(dateString: string) {
  const [day, month, year] = dateString.split('/').map((num) => parseInt(num, 10));
  return new Date(year, month - 1, day); // Les mois en JavaScript commencent à 0
}

export function formatDateToISO(dateTime: string) {
  const date = new Date(dateTime);
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
  return adjustedDate.toISOString().slice(0, 16); // Cela va couper le string pour avoir le format 'YYYY-MM-DDTHH:mm'
}

export function getFormatFromMimetype(mimetype: string): string {
  // Return the format based on the mimetype
  switch (mimetype) {
    case 'image/jpeg':
    case 'image/jpg':
      return 'jpg';
    case 'image/png':
      return 'png';
    case 'image/heic':
      return 'heic';
    case 'application/pdf':
      return 'pdf';
    case 'image/gif':
      return 'gif';
    case 'image/bmp':
      return 'bmp';
    case 'image/tiff':
      return 'tiff';
    case 'text/plain':
      return 'txt';
    case 'application/zip':
      return 'zip';
    // Add more cases for other mime types as needed
    default:
      return 'bin'; // Return 'bin' as a fallback for unknown mime types
  }
}

export function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export const convertToLocalTime = (utcDateString: string) => {
  const utcDate = new Date(utcDateString + 'Z'); // Ajout du 'Z' pour indiquer que la date est en UTC
  return utcDate.toLocaleString('fr-FR', { timeZone: 'Europe/Paris' });
};

export const convertToLocalDateTimeString = (utcDateString: string) => {
  const utcDate = new Date(utcDateString);
  const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  return localDate.toISOString().slice(0, 16);
};

export const formatUTCDateTime = (utcDateString: string) => {
  return utcDateString.slice(0, 16);
};

export const removeSpaces = (value: string) => value.replace(/\s/g, '');

export const updateDealStage = async (dealId: number, newStageId: number) => {
  try {
    await makeAuthenticatedApiCall('post', '/api/pipedrive/deals/update-stage-deal', {
      dealId: dealId,
      stageId: newStageId,
    });
    console.log(`Deal ${dealId} updated to stage ${newStageId}`);
    // refetch(); // Refresh the CG list after updates : Est-ce vraiment nécessaire ?
  } catch (error) {
    console.error('Error updating deal stage:', error);
  }
};

export const trimFormData = (formData: any) => {
  const trimmedData: any = {};
  for (const key in formData) {
    if (typeof formData[key] === 'string') {
      trimmedData[key] = formData[key].trim();
    } else {
      trimmedData[key] = formData[key];
    }
  }
  return trimmedData;
};
