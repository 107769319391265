import { Container, Typography } from '@mui/material';
import CreateCotation from '../components/CreateCotation';

const CreateCotationPage = () => {
  return (
    <Container maxWidth={false}>
      <Typography variant="h1" align="center" sx={{ margin: { xs: '0.5em auto 1em', md: '1em auto' } }}>
        Demande de cotation
      </Typography>
      <CreateCotation />
    </Container>
  );
};

export default CreateCotationPage;
