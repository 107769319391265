import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useAuth } from '../AuthContext';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import moment from 'moment-timezone';
import theme from '../../theme';

interface Comment {
  user: string;
  date: string;
  comment: string;
}

interface CGCommentsProps {
  registrationId: number;
  comments: Comment[];
  refetch: () => Promise<void>;
}

const CGComments: React.FC<CGCommentsProps> = ({ registrationId, comments, refetch }) => {
  const [newComment, setNewComment] = useState('');
  const { currentUser } = useAuth();
  const userRole = currentUser?.data?.data?.role;

  const handleAddComment = async () => {
    try {
      await makeAuthenticatedApiCall('post', '/api/cg/comments', {
        registrationId,
        comment: newComment,
      });
      setNewComment('');
      await refetch();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const formatCommentDate = (dateString: string) => {
    return moment(dateString).tz('Europe/Paris').format('DD/MM/YYYY HH:mm');
  };

  return (
    <Box>
      <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '18px' }} gutterBottom mt={4}>
        Commentaires internes :
      </Typography>
      {comments.map((comment, index) => (
        <div key={index}>
          <strong>{comment.user} - </strong>
          <span>
            {formatCommentDate(comment.date)} : {comment.comment}
          </span>
        </div>
      ))}
      {(userRole === 'god' || userRole === 'arbitrator') && (
        <Box>
          <TextField
            label="Nouveau commentaire"
            variant="outlined"
            className="inputElement inputElement--Comments"
            multiline
            fullWidth
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />
          <Button
            type="button"
            variant="contained"
            sx={{
              fontWeight: 600,
              height: 40,
              mt: 1,
              fontSize: '12px',
              textAlign: 'center',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            onClick={handleAddComment}
          >
            Ajouter un commentaire
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(CGComments);
