import React, { useState } from 'react';
import { AlertColor, Button, CircularProgress } from '@mui/material';
import { ErrorMessageSnackbar } from '../../ErrorMessageSnackbar';
import { DBDeal } from '../../../types/deal';
import { makeAuthenticatedApiCall } from '../../../apiHelper';
import { base64ToBlob } from '../../../utils';

interface CreatePurchaseCerfaProps {
  deal: DBDeal;
  isMandate: boolean;
}

const CreatePurchaseCerfa: React.FC<CreatePurchaseCerfaProps> = ({ deal, isMandate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('error');

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const updatedDeal = { ...deal, isMandate };
      const response = await makeAuthenticatedApiCall('post', `/api/misiv/cerfa/purchase`, updatedDeal);
      const base64String = response?.data;

      // Convertir le base64 en Blob
      const blob = base64ToBlob(base64String, 'application/pdf');

      // Créer un URL pour le Blob
      const blobUrl = URL.createObjectURL(blob);

      // Ouvrir le PDF dans une nouvelle fenêtre
      window.open(blobUrl);
    } catch (error: any) {
      console.error('Erreur lors de la récupération du document:', error);
      openSnackbar(error?.response?.data?.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button className="dealStageActionsButton" variant="contained" onClick={handleClick}>
        {isLoading ? (
          <CircularProgress size={24} style={{ color: 'white' }} />
        ) : isMandate ? (
          "✍️ Déclaration d'achat à signer"
        ) : (
          "ℹ️ Certificat d'achat"
        )}
      </Button>

      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
    </>
  );
};

export default CreatePurchaseCerfa;
