import create from 'zustand';

interface ButtonStepState {
  buttonSteps: Record<number, string>; // Key is PipedriveDealID, value is buttonStep
  setButtonStep: (dealId: number, buttonStep: string) => void;
}

const useButtonStepStore = create<ButtonStepState>((set) => ({
  buttonSteps: {},
  setButtonStep: (dealId, buttonStep) =>
    set((state) => ({
      buttonSteps: {
        ...state.buttonSteps,
        [dealId]: buttonStep,
      },
    })),
}));

export default useButtonStepStore;
