import { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

interface CarDialogConfirmationProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const CarDialogConfirmation: FC<CarDialogConfirmationProps> = ({ open, handleClose, handleConfirm }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{'Êtes-vous sûr ?'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Si vous n’avez déclaré aucun défaut, en continuant vous vous engagez à ce que le véhicule ne comporte aucun
          défaut. Comme indiqué dans nos CGV, tout défaut non déclaré pourra vous être facturé.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CarDialogConfirmation;
