import { Button, CircularProgress } from '@mui/material';
import { DBDeal } from '../../../types/deal';
import { useState } from 'react';
import { makeAuthenticatedApiCall } from '../../../apiHelper';

interface BillOfSaleProps {
  deal: DBDeal;
  orgId: number;
  personId: number;
}

const BillOfSale: React.FC<BillOfSaleProps> = ({ deal, orgId, personId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleBillOfSale = async () => {
    setIsLoading(true);
    try {
      const response = await makeAuthenticatedApiCall(
        'post',
        `/api/generate-bill-of-sale`,
        { deal, orgId, personId },
        {
          responseType: 'blob',
        },
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button className="dealStageActionsButton" variant="contained" onClick={handleBillOfSale}>
      {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : '📝 Attestation de vente à signer'}
    </Button>
  );
};

export default BillOfSale;
