import React, { useState, useEffect } from 'react';
import {
  AlertColor,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { DBDeal } from '../../../types/deal';
import { makeAuthenticatedApiCall } from '../../../apiHelper';
import { formatDateToDDMMYYYYWithTime, priceFormatter } from '../../../utils';
import { ErrorMessageSnackbar } from '../../ErrorMessageSnackbar';

const GenerateInvoice: React.FC<{ deal: DBDeal; pipeline: string; vat: boolean }> = ({ deal, pipeline, vat }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [lastUpdate, setLastUpdate] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [isQontoLoading, setIsQontoLoading] = useState(true);

  const [openEvoPriceDialog, setOpenEvoPriceDialog] = useState(false);
  const [evoSellingPrice, setEvoSellingPrice] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('error');

  const [openPipelineEModal, setOpenPipelineEModal] = useState(false);

  const handleOpenEvoPriceDialog = () => setOpenEvoPriceDialog(true);
  const handleCloseEvoPriceDialog = () => setOpenEvoPriceDialog(false);

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Define a function to handle the closing of the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const fetchDealQontoData = async () => {
      setIsQontoLoading(true);
      try {
        if (pipeline === 'b') {
          const response = await makeAuthenticatedApiCall('get', `/api/arbitration/mandate-calculator/${deal.id}`);
          const sellingPrice = response?.data?.selling_final_price;
          setSellingPrice(sellingPrice);
        } else if (pipeline === 'e') {
          const response = await makeAuthenticatedApiCall('get', `/api/arbitration/deposit-sale-calculator/${deal.id}`);
          const sellingPrice = response?.data?.sale_price;
          setSellingPrice(sellingPrice);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch existing mandate data', error);
        setIsLoading(false);
      }

      try {
        const response = await makeAuthenticatedApiCall('get', `/api/qonto/deals/${pipeline}/${deal.id}`);
        if (response?.data) {
          setInvoiceUrl(response.data.invoice_url_firebase || '');
          setLastUpdate(response.data.last_update || '');
        }
      } catch (error: any) {
        console.error(error);
        openSnackbar(error.response.data, 'error');
        return true; // Arrêter l'exécution ici
      } finally {
        setIsQontoLoading(false);
      }
    };

    fetchDealQontoData();
  }, [deal.id]);

  const generateInvoice = async (sellingPrice?: string) => {
    setIsLoading(true);

    try {
      const response = await makeAuthenticatedApiCall('post', `/api/qonto/generate`, {
        deal,
        pipeline,
        vat,
        sellingPrice,
      });

      if (response?.data?.invoiceFirebaseUrl) {
        window.open(response.data.invoiceFirebaseUrl, '_blank');
        setInvoiceUrl(response.data.invoiceFirebaseUrl);
      }
    } catch (error: any) {
      console.error(error);
      openSnackbar(error.response.data.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async () => {
    if (invoiceUrl) {
      window.open(invoiceUrl, '_blank');
      return;
    }

    setIsLoading(true);

    if ((!sellingPrice || sellingPrice === '0') && pipeline !== 'c') {
      handleOpenDialog();
      setIsLoading(false);
      return;
    }

    if (pipeline === 'e' && sellingPrice) {
      setOpenPipelineEModal(true);
      setIsLoading(false);
      return;
    }

    try {
      const response = await makeAuthenticatedApiCall('post', `/api/qonto/generate`, {
        deal,
        pipeline,
        vat,
        sellingPrice,
      });

      if (response?.data?.invoiceFirebaseUrl) {
        window.open(response.data.invoiceFirebaseUrl, '_blank');
        setInvoiceUrl(response.data.invoiceFirebaseUrl);
      }
    } catch (error: any) {
      console.error(error);
      openSnackbar(error.response.data.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        sx={{ fontSize: '12px', fontWeight: 600, marginTop: 2 }}
        onClick={pipeline === 'c' && !invoiceUrl ? handleOpenEvoPriceDialog : handleClick}
        variant="contained"
        disabled={isLoading || isQontoLoading}
      >
        {isLoading || isQontoLoading ? (
          <CircularProgress size={24} />
        ) : invoiceUrl ? (
          '🧾 Voir la facture'
        ) : (
          '🧾 Générer la facture'
        )}
      </Button>
      {lastUpdate && (
        <Typography
          variant="body2"
          sx={{
            fontSize: '13px',
            maxWidth: '200px',
            lineHeight: '1.25',
            marginTop: 1,
            fontWeight: 'medium',
          }}
        >
          Facture générée le {formatDateToDDMMYYYYWithTime(lastUpdate.replace('T', ' ').slice(0, -5))}
        </Typography>
      )}
      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{'Prix de vente manquant'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez d'abord définir le prix de vente dans l'univers {pipeline === 'b' ? 'Mandat' : 'Dépot-Vente'}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.open(`${deal.ext_url}&arbitrator`, '_blank');
              handleCloseDialog();
            }}
            color="primary"
          >
            Aller à {pipeline === 'b' ? 'Mandat' : 'Dépot-Vente'}
          </Button>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEvoPriceDialog} onClose={handleCloseEvoPriceDialog}>
        <DialogTitle>{'Définir le Prix de vente EVO'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Veuillez entrer le prix de vente EVO pour la génération de la facture.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="evoSellingPrice"
            label="Prix de vente EVO"
            type="number"
            fullWidth
            value={evoSellingPrice}
            onChange={(e) => setEvoSellingPrice(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEvoPriceDialog}>Annuler</Button>
          <Button
            onClick={async () => {
              setSellingPrice(evoSellingPrice);
              handleCloseEvoPriceDialog();
              await generateInvoice(evoSellingPrice);
            }}
            color="primary"
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openPipelineEModal} onClose={() => setOpenPipelineEModal(false)}>
        <DialogTitle sx={{ fontWeight: '600', color: 'black' }}>{'Confirmation du prix de vente'}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: '500', color: 'black' }}>
            Le prix de vente enregistré est de :{' '}
            <span style={{ color: 'black', fontWeight: '500' }}>{priceFormatter.format(sellingPrice)}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPipelineEModal(false)}>Annuler</Button>
          <Button
            onClick={async () => {
              setOpenPipelineEModal(false);
              await generateInvoice(sellingPrice);
            }}
            color="primary"
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenerateInvoice;
