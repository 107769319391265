import { useState } from 'react';
import { sendPasswordResetEmail, getAuth } from 'firebase/auth';
import { Box, Button, TextField, Typography, Container, Avatar } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTranslation } from 'react-i18next';
import theme from '../theme';

const PasswordResetPage: React.FC = () => {
  const { t } = useTranslation('common');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const auth = getAuth();

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(t('Password reset email sent.'));
    } catch (error: any) {
      setError(t(error.code)); // Handle error translation
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: theme.palette.saffron.main }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Reset Password')}
        </Typography>
        <Box component="form" noValidate onSubmit={handlePasswordReset} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            className="inputElement"
            required
            fullWidth
            id="email"
            label={t('Email address')}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary" // ou "secondary"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
          >
            {t('Send Password Reset Email')}
          </Button>

          {message && (
            <Typography sx={{ mt: 2 }} align="center" variant="body2">
              {message}
            </Typography>
          )}
          {error && (
            <Typography sx={{ mt: 2, color: 'red' }} color="error">
              {error}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordResetPage;
