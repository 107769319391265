import { TableRow, TableCell, Button, Typography, Link } from '@mui/material';
import { capitalizeAndFormat, formatDateToDDMMYYYYWithTime, formatKilometers, formatDateToDDMMYYYY } from '../../utils';
import { ArbitratorDealProps, DBDeal } from '../../types/deal';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import theme from '../../theme';
import React from 'react';
import { makeAuthenticatedApiCall } from '../../apiHelper';

const ArbitratorDeal: React.FC<ArbitratorDealProps> = ({
  row,
  userRole,
  userEmail,
  refetchArbitratorDeals,
  dealData,
}) => {
  const { t } = useTranslation('common');
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const getDisplayPrice = (row: DBDeal): JSX.Element => {
    const priceFormatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    });

    // Formatter les prix si non nuls
    const formatPrice = (price: number) => (price > 0 ? priceFormatter.format(price) : '');

    // Vérifier si le statut est 'processed'
    if (row.status === 'processed') {
      let prices = [];

      // Ajouter d'abord les prix supervisor si disponibles
      if (row.deposit_sale_supervisor || row.straight_trade_in_supervisor || row.thirty_day_sale_mandate_supervisor) {
        prices = [
          row.deposit_sale_supervisor && `🏪 : ${formatPrice(parseInt(row.deposit_sale_supervisor))}`,
          row.straight_trade_in_supervisor && `⚡ : ${formatPrice(parseInt(row.straight_trade_in_supervisor))}`,
          row.thirty_day_sale_mandate_supervisor &&
            `⏰ : ${formatPrice(parseInt(row.thirty_day_sale_mandate_supervisor))}`,
        ].filter(Boolean);
      }
      // Sinon, utiliser les prix arbitrator
      else {
        prices = [
          row.deposit_sale_arbitrator && `🏪 : ${formatPrice(parseInt(row.deposit_sale_arbitrator))}`,
          row.straight_trade_in_arbitrator && `⚡ : ${formatPrice(parseInt(row.straight_trade_in_arbitrator))}`,
          row.thirty_day_sale_mandate_arbitrator &&
            `⏰ : ${formatPrice(parseInt(row.thirty_day_sale_mandate_arbitrator))}`,
        ].filter(Boolean);
      }

      return (
        <>
          {prices.map((price, index) => (
            <React.Fragment key={index}>
              {price}
              {index < prices?.length - 1 && <br />}
            </React.Fragment>
          ))}
        </>
      );
    }

    // Sinon, retourner le prix encheres_vo_price
    return <>{priceFormatter.format(parseInt(row.encheres_vo_price ?? '0'))}</>;
  };

  const handleRefuseArbitration = async () => {
    try {
      const handleRefuseArbitrationCall = await makeAuthenticatedApiCall(
        'post',
        `/api/arbitration/refuse/${row.pipedrive_deal_id}`,
        {
          userEmail: userEmail,
          userRole: userRole,
        },
      );
      if (handleRefuseArbitrationCall.status === 200) {
        // console.log('handleRefuseArbitrationCall');
      } else {
        // la logique avec handleApiError et la snackbar
      }
    } catch (error) {
      console.log(error);
      // la logique avec handleApiError et la snackbar
    } finally {
      refetchArbitratorDeals();
      setDisabledButton(true);
    }
  };

  useEffect(() => {
    // Définir l'état du bouton basé sur la condition
    if (row.status === 'processed' || row.status === 'refused') {
      setDisabledButton(true);
    }
  }, [row]); // Dépendance à `row` pour réévaluer quand `row` change

  return (
    <TableRow key={row.pipedrive_deal_id} className={row.refusal_date ? 'rowRed' : 'rowGreen'}>
      <TableCell>{t(`arbitrationStatus.${row.accepted_offer ? 'accepted' : row.status}`)}</TableCell>
      <TableCell>{formatDateToDDMMYYYYWithTime(row.add_time ?? '')}</TableCell>
      <TableCell sx={{ fontWeight: 700 }}>{row.registration_plate}</TableCell>
      <TableCell>{row.org_name}</TableCell>
      <TableCell>
        <Typography sx={{ fontSize: 15 }}>{capitalizeAndFormat(row.make)}</Typography>
        {row.model} {row.derivative}
      </TableCell>
      <TableCell>{formatKilometers(row.kms)}</TableCell>
      <TableCell>{formatDateToDDMMYYYY(row.reg_date)}</TableCell>
      <TableCell>{getDisplayPrice(row)}</TableCell>
      <TableCell sx={{ textAlign: 'center', display: 'table-cell', verticalAlign: 'middle' }}>
        {!disabledButton ? (
          <>
            <Button
              variant="contained"
              href={`/car-deal/${row.pipedrive_deal_id}?make=${row.make}&fuel=${encodeURIComponent(
                row.fuel,
              )}&arbitrator`}
            >
              Arbitrer
            </Button>
            <Button
              variant="contained"
              onClick={handleRefuseArbitration}
              sx={{
                marginTop: 1,
                marginBottom: 2,
                backgroundColor: theme.palette.red.main,
                '&:hover': { backgroundColor: theme.palette.saffron.main },
                color: 'white',
              }}
            >
              Ne pas arbitrer
            </Button>
          </>
        ) : (
          <Link
            href={`/car-deal/${row.pipedrive_deal_id}?make=${row.make}&fuel=${encodeURIComponent(row.fuel)}&arbitrator`}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            Arbitrage
          </Link>
        )}
        {dealData?.refusal_date && (
          <Typography align="center" sx={{ fontSize: 14 }} variant="body2">
            Double-cotation demandée le {dealData?.refusal_date}
          </Typography>
        )}
        {/* <MarketPlaceButton dealId={row.pipedrive_deal_id} userRole={userRole} dealData={dealData} />  */}
      </TableCell>

      <TableCell>{row.pipedrive_deal_id}</TableCell>
    </TableRow>
  );
};

export default ArbitratorDeal;
