// packages/frontend/src/App.tsx
import { Outlet, useNavigate } from 'react-router-dom';
import './App.css';
import { Container, Grid } from '@mui/material';
import Header from './components/Header';
import './i18n';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';
import useFromExternalStore from './stores/FromExternalStore';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { signInWithEmailAndPassword, getAuth, signOut } from 'firebase/auth';
import { useEffect } from 'react';

// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBB8oZ8v8ph5ZnR6Q1RFGftvaMjdHx0qtQ',
  authDomain: 'skipcar-app.firebaseapp.com',
  projectId: 'skipcar-app',
  storageBucket: 'skipcar-app.appspot.com',
  messagingSenderId: '274069652490',
  appId: '1:274069652490:web:4b4ed185767dba999f4bf3',
  measurementId: 'G-FHL7M0PL2B',
};

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://6523d9dc3993a7540ec3058b675afcdc@o4505963019501568.ingest.sentry.io/4505963020812288',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/skipcar-app\.web\.app/,
          /^https:\/\/dev\.app\.skipcar\.fr/,
          /^https:\/\/app\.skipcar\.fr/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// Initialize Firebase
initializeApp(firebaseConfig);

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();

  const setFromExternal = useFromExternalStore((state) => state.setFromExternal);
  const isFromExternal = useFromExternalStore((state) => state.fromExternal);

  useEffect(() => {
    if (location.search.includes('from=external')) {
      setFromExternal(true);
    } else {
      setFromExternal(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (isFromExternal && location.pathname !== '/cotation' && location.pathname !== '/login') {
      navigate('/cotation?from=external', { replace: true });
    }
    if (auth?.currentUser?.email === 'prospection.skipcar@gmail.com') {
      if (location.pathname !== '/cotation' && location.pathname !== '/login') {
        setFromExternal(true); // Je suis déjà connecté, j'ai refresh
        navigate('/cotation?from=external', { replace: true });
      }
    }
  }, [location, isFromExternal]);

  const loginAutomatically = async () => {
    if (!isFromExternal) {
      // Si je ne suis pas déjà connecté depuis l'extérieur
      try {
        signOut(auth).then(async () => {
          // Je commence par me déconnecter
          const automaticConnectResult = await signInWithEmailAndPassword(
            auth,
            'prospection.skipcar@gmail.com',
            import.meta.env.VITE_SC_API_KEY,
          );
          if (automaticConnectResult.user.email !== 'prospection.skipcar@gmail.com') {
            // Si l'email ne correspond pas, déconnecter et rediriger vers la page de connexion
            await signOut(auth);
            navigate('/login');
          }
        });
      } catch (error) {
        console.error('Error during automatic authentication:', error);
      }
    }
  };

  const params = new URLSearchParams(location.search);
  const fromExternal = params.get('from') === 'external';

  if (location.pathname === '/login' && fromExternal) {
    setFromExternal(false); // Désactiver le mode "from external"
  } else if (location.pathname === '/cotation' && fromExternal) {
    setFromExternal(true); // Mettre à jour le store
    loginAutomatically();
  }

  return (
    <Container disableGutters maxWidth={false}>
      <Grid id="header">
        <Header />
      </Grid>
      <Grid id="main">
        <Outlet />
      </Grid>
    </Container>
  );
};

export default App;
