import {
  Dialog,
  DialogTitle,
  DialogContent,
  AlertColor,
  DialogActions,
  Button,
  Typography,
  Snackbar,
  CircularProgress,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import { ErrorMessageSnackbar } from '../ErrorMessageSnackbar';
import { useState } from 'react';
import { TaxModalProps } from '../../types/cg';

const TaxModal: React.FC<TaxModalProps> = ({
  open,
  handleClose,
  taxData,
  agencyData,
  regPlate,
  vin,
  numFormule,
  registrationId,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('error');

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const addDealToPipedrive = async () => {
    try {
      const value = agencyData?.agencyID === 343 ? taxData.taxeTotale + 12 : taxData.taxeTotale + 10; // Change 20 to 10
      const responsePipedriveNewCGDeal = await makeAuthenticatedApiCall('post', '/api/pipedrive/f', {
        regPlate,
        vin,
        numFormule,
        value,
        agencyID: agencyData?.agencyID ?? null,
        personID: agencyData?.personID ?? null,
      });

      console.log('responsePipedriveNewCGDeal', responsePipedriveNewCGDeal);
      return responsePipedriveNewCGDeal.data;
    } catch (error: any) {
      console.error(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Une erreur inattendue s'est produite. Veuillez réessayer.";
      openSnackbar(errorMessage, 'error');
    }
  };

  const handleValidate = async () => {
    setIsLoading(true);
    console.log(taxData, agencyData, regPlate);
    const deal = await addDealToPipedrive();

    // ICI on va update CG_Registrations avec le nouveau PipedriveDealId
    try {
      const responseUpdateRegistrationWithDealId = await makeAuthenticatedApiCall(
        'post',
        `/api/cg/update-pipedrive-deal-id/${registrationId}`,
        {
          dealId: deal.id,
          taxData,
        },
      );
      console.log('responseUpdateRegistrationWithDealId', responseUpdateRegistrationWithDealId);
    } catch (error: any) {
      console.error(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Une erreur inattendue s'est produite. Veuillez réessayer.";
      openSnackbar(errorMessage, 'error');
      return;
    }

    try {
      const responseInvoiceGeneration = await makeAuthenticatedApiCall('post', '/api/qonto/generate', {
        pipeline: 'f',
        deal,
        vat: 0,
        sellingPrice: taxData.taxeTotale,
        agencyData,
        regPlate,
      });

      console.log('responseInvoiceGeneration', responseInvoiceGeneration);
    } catch (error: any) {
      console.error(error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Une erreur inattendue s'est produite. Veuillez réessayer.";
      openSnackbar(errorMessage, 'error');
      return;
    }

    if (agencyData?.agencyID === 343) {
      // Biwiz Siège paye ses factures plus tard
      try {
        // Ici route pour envoyer le mail de confirmation
        const responseSendEmailToGod = await makeAuthenticatedApiCall('post', `/api/mails/ctvo/god`, {
          AgencyName: agencyData?.agencyName,
          RegPlate: regPlate,
        });
        console.log('responseSendEmailToGod', responseSendEmailToGod);
      } catch (error: any) {
        console.error(error);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Une erreur inattendue s'est produite. Veuillez réessayer.";
        openSnackbar(errorMessage, 'error');
      }
    }
    setIsLoading(false);
    navigate('/list-cg');
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle align="center" variant="body1">
          {'Confirmation des coûts'}
        </DialogTitle>
        <DialogContent>
          <Typography align="center" variant="body2" component="div" mb={5}>
            Ce changement de titulaire devrait vous être facturé :{' '}
            <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
              {agencyData?.agencyID === 343
                ? `${(taxData.taxeTotale + 12).toFixed(2)}€ HT`
                : `${(taxData.taxeTotale + 10).toFixed(2)}€ HT`}
            </span>
          </Typography>
          <Typography variant="body2" mt={0} fontSize="16px" sx={{ textAlign: 'right' }} component="div">
            Changement de titulaire taxes :{' '}
            <span style={{ fontWeight: 'bold' }}>{taxData.taxeTotale.toFixed(2)} € HT</span>
          </Typography>
          <Typography variant="body2" mt={0} fontSize="16px" sx={{ textAlign: 'right' }} component="div">
            Prestation SKIPCAR :{' '}
            {agencyData?.agencyID === 343 ? (
              <span style={{ fontWeight: 'bold' }}>12.00 € HT</span>
            ) : (
              <>
                <span style={{ color: 'red', marginRight: '8px' }}>
                  <span style={{ textDecoration: 'line-through' }}>20.00 €</span>
                  <sup style={{ fontWeight: 'bold' }}>-50%</sup>
                </span>
                <span style={{ fontWeight: 'bold' }}>/ 10.00 € HT</span>
              </>
            )}
          </Typography>
          <Typography variant="body2" mt={0} fontSize="16px" sx={{ textAlign: 'right' }} component="div">
            <span style={{ display: 'block', fontWeight: 'bold' }}>
              {' '}
              Total :{' '}
              {agencyData?.agencyID === 343
                ? `${(taxData.taxeTotale + 12).toFixed(2)} € HT`
                : `${(taxData.taxeTotale + 10).toFixed(2)} € HT`}{' '}
            </span>
            <span style={{ display: 'block', fontWeight: 'bold' }}>
              {' '}
              Total :{' '}
              {agencyData?.agencyID === 343
                ? `${(taxData.taxeTotale + 12 * 1.2).toFixed(2)} € TTC`
                : `${(taxData.taxeTotale + 10 * 1.2).toFixed(2)} € TTC`}{' '}
            </span>
          </Typography>
          <Typography variant="body2" mt={2} fontSize="16px" sx={{ textAlign: 'left' }} component="div">
            📝 Le résultat affiché est une estimation d’après les informations que vous avez fournies.
          </Typography>
          <Typography
            variant="body2"
            mt={1}
            fontSize="16px"
            sx={{ textAlign: 'left', fontStyle: 'italic' }}
            component="div"
          >
            🏷️ Le montant définitif de la carte grise sera en effet fixé par le service chargé des immatriculations.
          </Typography>
          <Typography
            variant="body2"
            mt={1}
            fontSize="16px"
            sx={{ textAlign: 'left', fontStyle: 'italic' }}
            component="div"
          >
            💵 En cas de variation entre l’estimation et le prix final vous vous engagez à régler la différence.
          </Typography>
          <Typography
            variant="body2"
            mt={1}
            fontSize="16px"
            sx={{ textAlign: 'left', fontStyle: 'italic' }}
            component="div"
          >
            📜 La délivrance d'un certificat d'immatriculation (ex-carte grise) est soumise au paiement de 4 taxes et
            d'une redevance.
          </Typography>
          <Typography
            variant="body2"
            mt={1}
            fontSize="16px"
            sx={{ textAlign: 'left', fontStyle: 'italic' }}
            component="div"
          >
            🚫 SKIPCAR ne surfacture aucunement les taxes et redevance, le prix des taxe de la carte grise est fixe.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={handleValidate}
            color="primary"
            sx={{
              fontWeight: 600,
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            variant="contained"
            autoFocus
          >
            {isLoading && <CircularProgress sx={{ marginRight: 2 }} size={24} />} Je suis d'accord
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
    </>
  );
};

export default TaxModal;
