import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JSX } from 'react/jsx-runtime';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import theme from '../../theme';
import { DealStageActionsProps } from '../../types/DealStageActions';
import { formatDateToDDMMYYYY, getFuelValueFromLabel, isDateMoreThan9YearsOld, priceFormatter } from '../../utils';
import { useAuth } from '../AuthContext';
import { openSnackbar } from '../ErrorMessageSnackbar';
import BillOfSale from './DealStageActions/BillOfSale';
import CreateCessionCerfa from './DealStageActions/CreateCessionCerfa';
import CreatePurchaseCerfa from './DealStageActions/CreatePurchaseCerfa';
import DealStageModal from './DealStageActions/DealStageModal';
import GenerateInvoice from './DealStageActions/GenerateInvoice';
import Logbook from './DealStageActions/Logbook';
import SoldDepositSaleButton from './DealStageActions/SoldDepositSaleButton';
import SubmitCessionCertificate from './DealStageActions/SubmitCessionCertificate';
import SubmitPurchaseCertificate from './DealStageActions/SubmitPurchaseCertificate';
import UploadModal from './DealStageActions/UploadModal';
import {
  default as UploadModalDepositSale,
  default as UploadModalMandate,
} from './DealStageActions/UploadModalDepositSale';
import MarketPlaceButton from './MarketPlaceButton';

interface EmojiMapType {
  [key: string]: string;
  deposit_sale: string;
  straight_trade_in: string;
  thirty_day_sale_mandate: string;
}

const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0 en JS
const day = String(today.getDate()).padStart(2, '0'); // padStart assure que le jour et le mois soient toujours de deux chiffres

const DealStageActions: React.FC<DealStageActionsProps> = ({
  stageId,
  stages,
  pipelines,
  deal,
  dealData,
  onRefresh,
}) => {
  const [dealArbitrationData, setDealArbitrationData] = useState<Record<string, any>>({});
  const [isLoadingArbitrationDealData, setIsLoadingArbitrationDealData] = useState<boolean>(false);

  const [loadingButtons, setLoadingButtons] = useState<Record<string, boolean>>({});
  const [buttonSuccess, setButtonSuccess] = useState<Record<string, boolean>>({});

  const [showRefuseButton, setShowRefuseButton] = useState<boolean>(true);
  const [openConfirmRefuseDialog, setOpenConfirmRefuseDialog] = useState(false);

  const [openConfirmAcceptDialog, setOpenConfirmAcceptDialog] = useState(false);

  const [soldMandateLoading, setSoldMandateLoading] = useState<boolean>(false);
  const [currentButton, setCurrentButton] = useState('');
  const [currentDataStore, setCurrentDataStore] = useState(null);
  const [currentDealId, setCurrentDealId] = useState(null);

  const [cancelRepatriationButton, setCancelRepatriationButton] = useState(true);

  const [repatriationRequested, setRepatriatioRequested] = useState<boolean>(false);
  const [repatriationDate, setRepatriationDate] = useState(deal['repatriation_date']);

  const [cancellationRequested, setCancellationRequested] = useState(false);
  const [cancellationDate, setCancellationDate] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('info');

  const [acceptedOffer, setAcceptedOffer] = useState<string | null>(null);
  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const [soldMandateButtonClicked, setSoldMandateButtonClicked] = useState<boolean>(false);
  const [isProcessed, setIsProcessed] = useState<boolean>(false);

  const [lastWebSocketMessage, setLastWebSocketMessage] = useState(null);

  const [openOutOfCharterDialog, setOpenOutOfCharterDialog] = useState(false);

  const [dcMarketPrice, setDcMarketPrice] = useState<number | ''>('');
  const [dcDesiredPrice, setDcDesiredPrice] = useState<number | ''>('');

  const [selectedArbitrationOffer, setSelectedArbitrationOffer] = useState<{
    offerType: string;
    price?: string | null;
  } | null>(null);

  const { currentUser } = useAuth();
  const userRole = currentUser?.data?.data?.role;

  const { t } = useTranslation('common');

  const handleOpenSnackbar = openSnackbar(setSnackbarMessage, setSnackbarOpen, setSeverity);

  const dealId = deal['id'];
  const finalPurchasePrice = deal['final_purchase_price'];
  const purchasePrice = deal['purchase_price'];
  const encheresVoPrice = deal['encheres_vo_price'];
  const dataStore = deal['data_store'];

  const personId = deal['person_id'];
  const orgId = deal['org_id'];
  const registration = deal['registration'];
  const fuel = deal['fuel'];
  const fuelValue = getFuelValueFromLabel(fuel);
  const brandValue = deal?.brand?.replace(/\s+/g, '_').toLowerCase();

  const linkToDc = `https://form.typeform.com/to/FH20uMcl#immatriculation=${registration}&org_id=${orgId}&person_id=${personId}&deal_id=${dealId}`;

  const shouldFetchArbitration = dealData?.arbitration_id;

  useEffect(() => {
    const fetchDealArbitrationData = async () => {
      try {
        if (shouldFetchArbitration) {
          setIsLoadingArbitrationDealData(true);
          // shouldFetchArbitration
          // Fetch arbitration data
          const dealArbitrationDataResponse = await makeAuthenticatedApiCall('get', `/api/arbitration/${dealId}`);
          const dealArbitrationData = dealArbitrationDataResponse.data;
          setIsProcessed(dealArbitrationData?.status === 'processed');
          setAcceptedOffer(dealArbitrationData?.accepted_offer);
          setDealArbitrationData(dealArbitrationData);
        }
      } catch (error) {
        console.error('Error fetching arbitration data:', error);
      } finally {
        setIsLoadingArbitrationDealData(false);
      }
    };

    if (dealData && dealId >= 8929) {
      const refusalDate = dealData.refusal_date;

      setCancellationRequested(!!refusalDate);
      if (refusalDate) {
        setCancellationDate(refusalDate);
      }

      // Information que je passe ensuite à /mails/acceptation pour le lien vers la fiche (Rien à voir avec l'arbitrage)
      deal.ext_url = dealData.ext_url;
    }

    fetchDealArbitrationData();
  }, [dealData, deal, shouldFetchArbitration]);

  const handleCancelPickupButton = async (
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    dataStore: any,
    dealId: any,
  ) => {
    event.stopPropagation();

    const updateDealEndpoint = `https://skipcar3.pipedrive.com/v1/deals/${dealId}?api_token=${
      import.meta.env.VITE_PIPEDRIVE_API_KEY
    }`;
    axios
      .put(updateDealEndpoint, {
        '2b9fff9d7952eaca442be026f7593a85632cce68': '',
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setButtonSuccess((prevState) => ({
          ...prevState,
          [`cancel-repatriation-${dataStore}`]: true,
        }));
        setCancelRepatriationButton(false);
      });
  };

  const handlePickupButton = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, dataStore: string, dealId: any) => {
      event.stopPropagation();

      const formattedDate = `${year}-${month}-${day}`;

      // Ajouter ce cas
      // await callSparkCreateAPI('ready_pickup', currentDataStore, currentDealId);

      const updateDealEndpoint = `https://skipcar3.pipedrive.com/v1/deals/${dealId}?api_token=${
        import.meta.env.VITE_PIPEDRIVE_API_KEY
      }`;
      axios
        .put(updateDealEndpoint, {
          '2b9fff9d7952eaca442be026f7593a85632cce68': formattedDate,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setButtonSuccess((prevState) => ({
            ...prevState,
            [`repatriation-${dataStore}`]: true,
          }));
          sendRepatriationMail(deal, currentUser?.data);
          setRepatriatioRequested(true);
          setRepatriationDate(formattedDate);
        });
    },
    [],
  );

  const sendRepatriationMail = async (deal: any, userData: any) => {
    try {
      const response = await makeAuthenticatedApiCall('post', '/api/mails/repatriation', { deal, userData });
    } catch (error) {
      console.error('Error sending email', error);
    }
  };

  const handleApiError = (error: any, context: string) => {
    Sentry.withScope((scope) => {
      scope.setTag('where', context);
      scope.setExtra('deal', deal);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          scope.setExtra('response', {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers,
          });
          handleOpenSnackbar(`Erreur serveur: ${error.response.status}`, 'error');
        } else if (error.request) {
          scope.setExtra('request', error.request);
          handleOpenSnackbar('Erreur réseau ou serveur non disponible', 'error');
        } else {
          handleOpenSnackbar('Erreur inattendue lors de la communication avec le serveur', 'error');
        }
      } else {
        handleOpenSnackbar('Une erreur inattendue est survenue', 'error');
      }

      Sentry.captureException(error);
    });
  };

  const sendAcceptationMail = async (deal: any, userData: any) => {
    try {
      const response = await makeAuthenticatedApiCall('post', '/api/mails/acceptation', { deal, userData });
    } catch (error) {
      console.error('Error sending email', error);
    }
  };

  const isVehicleOutOfCharter = (deal: any) => {
    const kmsLimit = 150000;
    const isLandRover = deal.brand === 'Land Rover';

    const mileage = parseInt(deal.mileage.replace(/\s+km/g, '').replace(/\s/g, ''), 10);
    const dateToCompare = deal.date_of_circulation;

    return mileage >= kmsLimit || isLandRover || isDateMoreThan9YearsOld(dateToCompare);
  };

  const handleRefuseButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    button: string,
    dataStore: any,
    dealId: any,
  ) => {
    setCurrentButton(button);
    setCurrentDataStore(dataStore);
    setCurrentDealId(dealId);

    if (isVehicleOutOfCharter(deal)) {
      // Ouvre la modale spécifique pour les véhicules hors charte
      setOpenOutOfCharterDialog(true);
    } else {
      // Procédure habituelle en cas de refus pour les véhicules dans la charte
      setOpenConfirmRefuseDialog(true);
      return;
    }
  };

  const confirmRefusal = async (dcMarketPrice?: number, dcDesiredPrice?: number) => {
    setOpenConfirmRefuseDialog(false);

    try {
      const response = await makeAuthenticatedApiCall('post', `/api/deals/${currentDealId}/refusal-date`);
      if (response.status === 200) {
        await callSparkCreateAPI('refuse', currentDataStore, currentDealId);
      }
    } catch (error) {
      handleApiError(error, 'confirmRefusal');
    } finally {
      setCancellationRequested(true);
      const refusalDate = new Date().toLocaleDateString('fr-FR');
      setCancellationDate(refusalDate);
      // refetchArbitrationDealData();
    }

    if (dcMarketPrice) {
      try {
        const response = await makeAuthenticatedApiCall('post', `/api/deals/double-cotation`, {
          currentDealId,
          dcMarketPrice,
          dcDesiredPrice,
        });
        console.log(response.data);
      } catch (error) {
        handleApiError(error, 'confirmRefusal');
      } finally {
        // refetchArbitrationDealData();
      }
    }
  };

  const confirmAcceptance = async () => {
    setOpenConfirmAcceptDialog(false);

    if (selectedArbitrationOffer) {
      setIsAccepting(true);

      try {
        const responseArbitrationOffer = await makeAuthenticatedApiCall('post', `/api/arbitration/accept/${deal.id}`, {
          offerType: selectedArbitrationOffer.offerType,
          dealId: deal.id,
          price: selectedArbitrationOffer.price,
        });
        if (responseArbitrationOffer.status === 200) {
          setAcceptedOffer(selectedArbitrationOffer.offerType);
        }
      } catch (error) {
        console.error("Erreur lors de l'acceptation de l'offre d'arbitration", error);
      } finally {
        setIsAccepting(false);
        // refetchArbitrationDealData();
      }
    } else {
      if (!currentDealId || !currentDataStore) return;
      // Logique pour envoyer l'acceptation au backend ou API
      try {
        await callSparkCreateAPI('accept', currentDataStore, currentDealId);
      } catch (error) {
        console.error("Erreur lors de l'enregistrement de l'acceptation: ", error);
      } finally {
        sendAcceptationMail(deal, currentUser?.data);
        setShowRefuseButton(false); // Cacher le bouton Refuser
      }
    }
  };

  const callSparkCreateAPI = async (button: string, dataStore: any, dealId: any) => {
    setLoadingButtons((prevState) => ({ ...prevState, [`${button}-${dataStore}`]: true }));

    const url = '/spark/create/';
    const data = {
      type: 'cotation_response',
      t_s_key: 'SVEHeNoxB4bjTKDjnHqJi15L1fc6MRBYwV1lBqr8NjCZhvYCGP',
      system: 'skipcar',
      data_store: dataStore,
      data: {
        response_val: button,
      },
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    try {
      const response = await axios.post(url, data, config);
      //  3) Pour faire l'équivalent du bouton "Vente sous mandat" que j'ai créé en attendant,
      //  il faut que ton appel à l'API se fasse avec "response_val": "cancel" (comme ça chez nous la demande de cotation est annulée)
      //  et que tu mettes à jour le champ "Date de demande de vente sous mandat (agence)" (13d1285980d957f7b41f60e6ddbc1ed7b6713545) de l'Affaire

      if (response.data.response.valid) {
        const nextStage = (button: string) => {
          if (button == 'accept') return 30; // A2
          if (button == 'cancel') return 1; // B2
          if (button == 'refuse') return 14; // A1
          return 14;
        };

        const dateFieldToUse = (button: string) => {
          if (button == 'accept') return 'f1527a798f3678aa2b7c2491a2f52b2a81d42b9e'; // Date d'acceptation
          if (button == 'cancel') return '13d1285980d957f7b41f60e6ddbc1ed7b6713545'; // Date de demande de vente sous mandat (agence)
          if (button == 'refuse') return 'f1527a798f3678aa2b7c2491a2f52b2a81d42b9e'; // Date d'acceptation que je vais remettre à '' (voir plus bas)
          return '';
        };
        const fieldToUpdate = dateFieldToUse(button);
        const formattedDate = button == 'refuse' ? '' : `${year}-${month}-${day}`;

        const updateDealEndpoint = `https://skipcar3.pipedrive.com/v1/deals/${dealId}?api_token=${
          import.meta.env.VITE_PIPEDRIVE_API_KEY
        }`;

        axios
          .put(updateDealEndpoint, {
            stage_id: nextStage(button),
            [fieldToUpdate]: formattedDate,
          })
          .then((response) => {
            // console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(async () => {
            setButtonSuccess((prevState) => ({
              ...prevState,
              [`${button}-${dataStore}`]: true,
            }));
            if (button == 'cancel') {
              try {
                const sendMandateSoldEmail = await makeAuthenticatedApiCall('post', '/api/mails/mandate-sold', {
                  dealId,
                  extUrl: dealData.ext_url,
                  regPlate: registration,
                  orgId,
                });
                console.log('sendMandateSoldEmail', sendMandateSoldEmail);
              } catch (error) {
                console.error('sendMandateSoldEmail', error);
              }
              setSoldMandateLoading(false);
              setSoldMandateButtonClicked(true);
            }
          });
      }
    } catch (error) {
      console.error(`Erreur lors de l'envoi de la requête : ${error}`);
    } finally {
      setLoadingButtons((prevState) => ({ ...prevState, [`${button}-${dataStore}`]: false }));
    }
  };

  const handleStageButton = async (
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    button: string,
    dataStore: any,
    dealId: any,
  ) => {
    if (event) {
      event.stopPropagation();
    }

    if (button === 'accept') {
      setCurrentButton(button);
      setCurrentDataStore(dataStore);
      setCurrentDealId(dealId);
      setOpenConfirmAcceptDialog(true);
      return;
    } else if (button === 'refuse') {
      setCurrentButton(button);
      setCurrentDataStore(dataStore);
      setCurrentDealId(dealId);
      setOpenConfirmRefuseDialog(true);
      return;
    } else if (button === 'cancel') {
      setSoldMandateLoading(true);
    }

    callSparkCreateAPI(button, dataStore, dealId);
  };

  const uniqueButtonKey = `accept-${dataStore}`;
  // const repatriationDateAsDateFormat = new Date(repatriationDate);
  // const differenceInTime = today.getTime() - repatriationDateAsDateFormat.getTime();
  // const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  const emojiMap: EmojiMapType = {
    deposit_sale: '🏪',
    straight_trade_in: '⚡',
    thirty_day_sale_mandate: '⏰',
  };

  const createAcceptButtons = () => {
    if (acceptedOffer) return null;

    const priceTypes = ['deposit_sale', 'straight_trade_in', 'thirty_day_sale_mandate'];

    const buttons: JSX.Element[] = [];

    if (isProcessed) {
      // Vérifier si au moins un prix superviseur est défini
      const isSupervisorPriceDefined = priceTypes.some((type) => dealArbitrationData[`${type}_supervisor`] > 0);

      priceTypes.forEach((type) => {
        const priceKey = isSupervisorPriceDefined ? `${type}_supervisor` : `${type}_arbitrator`;
        const price = dealArbitrationData[priceKey];

        if (price > 0) {
          const formattedPrice = priceFormatter.format(price);
          const buttonText = `${emojiMap[type]} Accepter ${formattedPrice}`;
          buttons.push(
            <Tooltip placement="top" title={`Offre Arbitrée : ${t(`business_type.${type}`)}`} key={`tooltip-${type}`}>
              <Button
                onClick={() =>
                  handleAcceptArbitrationOffer(type, isSupervisorPriceDefined ? 'supervisor' : 'arbitrator')
                }
                variant="contained"
                id={`accept-${type}-${isSupervisorPriceDefined ? 'supervisor' : 'arbitrator'}-${deal['data_store']}`}
                color="primary"
                key={type}
                disabled={isAccepting}
                className="dealStageActionsButton"
              >
                {buttonText}
              </Button>
            </Tooltip>,
          );
        }
      });
    }

    // Si aucun bouton n'est créé à ce stade, afficher le bouton par défaut
    if (buttons?.length === 0) {
      // || (buttons?.length === 1 && (userRole === 'god' || userRole === 'arbitrator'))) {
      const buttonText =
        userRole === 'agency'
          ? `Accepter ${deal['purchase_price']}`
          : `Accepter ${deal['purchase_price']} / ${deal['encheres_vo_price']}`;

      return (
        <Button
          onClick={(event) => handleStageButton(event, 'accept', dataStore, dealId)}
          variant="contained"
          id={`accept-${deal['data_store']}`}
          color="primary"
          disabled={isAccepting}
          className="dealStageActionsButton"
        >
          {buttonText}
        </Button>
      );
    }

    return buttons;
  };

  // Fonction pour gérer l'acceptation d'une offre
  const handleAcceptArbitrationOffer = async (offerType: string, priceType: 'arbitrator' | 'supervisor') => {
    const priceKey = `${offerType}_${priceType}`;
    const price = dealArbitrationData[priceKey];
    setSelectedArbitrationOffer({ offerType, price });
    setOpenConfirmAcceptDialog(true);
  };
  // Garder dataStore tant que EVO est dans la place

  const currentStage = stages.find((stage) => stage.id === stageId);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* Ajouter le lien d'arbitrage uniquement pour les utilisateurs 'god' ou 'arbitrator' */}
        {dealData && dealData?.pipedrive_deal_id && (userRole === 'god' || userRole === 'arbitrator') && (
          <Link
            textAlign="center"
            href={`/car-deal/${deal.id}?make=${brandValue}&fuel=${encodeURIComponent(fuelValue)}&arbitrator`}
            key="arbitrationLink"
            mt={1}
            mb={1}
          >
            Arbitrage
          </Link>
        )}
        {/* A1 */}
        {stageId === 14 && dataStore /* && days < 31 */ && (
          <>
            {loadingButtons[uniqueButtonKey] ? (
              <Typography sx={{ textAlign: 'center' }} variant="body2">
                <CircularProgress size={24} />
              </Typography>
            ) : (
              <>
                {buttonSuccess[`accept-${dataStore}`] ? (
                  <Button
                    variant="contained"
                    color="success"
                    className="dealStageActionsButton"
                    sx={{
                      pointerEvents: 'none',
                      backgroundColor: '#2e7d32 !important',
                    }}
                    disabled
                  >
                    {userRole === 'god' || userRole === 'arbitrator'
                      ? `Accepté ${purchasePrice} / ${encheresVoPrice}`
                      : `Accepté : ${purchasePrice}`}
                  </Button>
                ) : (
                  <>
                    {isAccepting ? (
                      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                      </Box>
                    ) : (
                      createAcceptButtons()
                    )}
                    {acceptedOffer && (
                      <>
                        <Tooltip
                          title={`Offre Arbitrée : ${t(`business_type.${acceptedOffer}`)}`}
                          placement="top"
                          key={`tooltip-${acceptedOffer}`}
                        >
                          <span>
                            <Button
                              variant="contained"
                              color="success"
                              className="dealStageActionsButton"
                              sx={{
                                pointerEvents: 'none',
                                backgroundColor: '#2e7d32 !important',
                              }}
                              disabled
                            >
                              {`${emojiMap[acceptedOffer]} Accepté ${
                                dealArbitrationData[`${acceptedOffer}_supervisor`] &&
                                parseInt(dealArbitrationData[`${acceptedOffer}_supervisor`]) > 0
                                  ? dealArbitrationData[`${acceptedOffer}_supervisor`]
                                  : dealArbitrationData[`${acceptedOffer}_arbitrator`]
                              }`}{' '}
                              €
                            </Button>
                          </span>
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {!cancellationRequested ? (
              <Fade in={showRefuseButton}>
                <Button
                  variant="contained"
                  onClick={(event) => handleRefuseButtonClick(event, 'refuse', dataStore, dealId)}
                  // onClick={handleRefuseButtonClick}
                  sx={{
                    fontSize: '13px',
                    fontWeight: 600,
                    backgroundColor: theme.palette.saffron.main,
                    '&:hover': { backgroundColor: theme.palette.saffron.main },
                  }}
                  id={`refuse-${dataStore}`}
                >
                  Double-cotation
                </Button>
              </Fade>
            ) : (
              <Typography align="center" sx={{ fontSize: 14 }} variant="body2">
                Double-cotation demandée le {cancellationDate}
              </Typography>
            )}
          </>
        )}
        {stageId !== 14 && stageId !== 39 && (
          <>
            {dealData?.arbitration_id && (userRole === 'god' || userRole === 'arbitrator') ? (
              <>
                {acceptedOffer ? (
                  <Tooltip
                    title={`Offre Arbitrée : ${t(`business_type.${acceptedOffer}`)}`}
                    placement="top"
                    key={`tooltip-${acceptedOffer}`}
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="success"
                        className="dealStageActionsButton"
                        sx={{
                          pointerEvents: 'none',
                          backgroundColor: '#2e7d32 !important',
                        }}
                        disabled
                      >
                        {`${emojiMap[acceptedOffer]} Accepté ${
                          dealArbitrationData[`${acceptedOffer}_supervisor`]
                            ? priceFormatter.format(dealArbitrationData[`${acceptedOffer}_supervisor`])
                            : priceFormatter.format(dealArbitrationData[`${acceptedOffer}_arbitrator`])
                        }`}
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    variant="contained"
                    color="success"
                    className="dealStageActionsButton"
                    sx={{
                      backgroundColor: '#2e7d32 !important',
                    }}
                    disabled
                  >
                    Accepté{' '}
                    {stageId === 30
                      ? purchasePrice
                      : finalPurchasePrice.replace(/\s/g, '') !== '0€'
                        ? finalPurchasePrice
                        : purchasePrice}
                    / {encheresVoPrice}
                  </Button>
                )}
              </>
            ) : (
              <>
                {acceptedOffer ? (
                  <Button
                    variant="contained"
                    color="success"
                    className="dealStageActionsButton"
                    sx={{
                      pointerEvents: 'none',
                      backgroundColor: '#2e7d32 !important',
                    }}
                    disabled
                  >
                    {`${emojiMap[acceptedOffer]} Accepté ${
                      dealArbitrationData[`${acceptedOffer}_supervisor`]
                        ? priceFormatter.format(dealArbitrationData[`${acceptedOffer}_supervisor`])
                        : priceFormatter.format(dealArbitrationData[`${acceptedOffer}_arbitrator`])
                    }`}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="success"
                    className="dealStageActionsButton"
                    sx={{
                      pointerEvents: 'none',
                      backgroundColor: '#2e7d32 !important',
                    }}
                    disabled
                  >
                    {userRole === 'god' || userRole === 'arbitrator'
                      ? `Accepté ${purchasePrice} / ${encheresVoPrice}`
                      : `Accepté : ${stageId === 30 && finalPurchasePrice.replace(/\s/g, '') !== '0€' ? finalPurchasePrice : purchasePrice}`}
                  </Button>
                )}
              </>
            )}
            {/* !cancellationRequested ? (
              <Button
                onClick={(event) => handleStageButton(event, 'refuse', dataStore, dealId)}
                color="error"
                id={`refuse-${dataStore}`}
                sx={!showCancel ? { display: 'none' } : {}}
              >
                Refuser
              </Button>
            ) : (
              <Typography align="center" sx={{ fontSize: 14 }} variant="body2">
                Refus demandé le {cancellationDate}
              </Typography>
            )*/}
            {!repatriationDate ? (
              (userRole === 'god' || userRole === 'arbitrator') && (
                <Button
                  id={`repatriation-${dataStore}`}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    handlePickupButton(event, dataStore, deal.id)
                  }
                >
                  🚚 Demande de rapatriement
                </Button>
              )
            ) : (
              <>
                <Typography variant="body2" align="center" sx={{ marginTop: 1, fontSize: 16 }}>
                  🚚 Demandée le {formatDateToDDMMYYYY(repatriationDate)}
                </Typography>
                {/* differenceInDays <= 3 && (
                  <Fade in={cancelRepatriationButton}>
                    <Button
                      id={`cancel-repatriation-${dataStore}`}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                        handleCancelPickupButton(event, dataStore, dealId)
                      }
                    >
                      🚚 Annulation
                    </Button>
                  </Fade>
                    )*/}
              </>
            )}
          </>
        )}
        {/* A2 */}
        {stageId === 30 && personId && orgId && (
          <>
            <Button href={linkToDc} target="_blank" sx={{ textAlign: 'center' }} variant="contained">
              Dossier de conformité
            </Button>
          </>
        )}

        {/* A5 + A6 */}
        {dealData && (stageId === 33 || stageId === 15) && (
          <>
            {(userRole === 'god' || userRole === 'arbitrator') && (
              <>
                <BillOfSale deal={deal} orgId={orgId} personId={personId} />
                <CreateCessionCerfa deal={deal} isDepositSale={false} isMandate={false} onRefresh={onRefresh} />
                <UploadModal
                  dealId={dealId}
                  photos={dealData?.photos}
                  extUrl={dealData?.ext_url}
                  regPlate={dealData?.registration_plate}
                />
                <SubmitPurchaseCertificate deal={deal} isMandate={false} />
              </>
            )}
          </>
        )}
        {/* B1 */}
        {stageId === 6 &&
          dataStore &&
          (soldMandateButtonClicked ? (
            <Button
              variant="contained"
              className="dealStageActionsButton"
              sx={{ backgroundColor: '#2e7d32 !important' }}
            >
              Vente sous mandat demandée
            </Button>
          ) : (
            <Button
              onClick={(event) => handleStageButton(event, 'cancel', dataStore, dealId)}
              id={`cancel-${dataStore}`}
              variant="contained"
              disabled={soldMandateLoading}
            >
              {soldMandateLoading ? (
                <CircularProgress size={24} />
              ) : userRole === 'god' || userRole === 'arbitrator' ? (
                `Vente sous mandat ${purchasePrice} / ${encheresVoPrice}`
              ) : (
                `Vente sous mandat ${purchasePrice}`
              )}
            </Button>
          ))}
        {/* B2 + B3 + B6 */}
        {dealData && (stageId === 1 || stageId === 40 || stageId === 35) && (
          <Button
            variant="contained"
            className="dealStageActionsButton"
            sx={{ backgroundColor: '#2e7d32 !important', fontSize: '11px', fontWeight: 700 }}
          >
            {deal?.sold_mandate_date
              ? `Date demande de vente sous mandat ${formatDateToDDMMYYYY(deal.sold_mandate_date)}`
              : 'Vente sous mandat demandée'}
          </Button>
        )}
        {/* B3 */}
        {dealData && stageId === 40 && (userRole === 'god' || userRole === 'arbitrator') && (
          <GenerateInvoice deal={deal} pipeline={'b'} vat={dealData?.vat} />
        )}
        {/* B4 + B5 */}
        {dealData && (stageId === 34 || stageId === 43) && (
          <>
            <Button
              variant="contained"
              className="dealStageActionsButton"
              sx={{ backgroundColor: '#2e7d32 !important', fontSize: '11px', fontWeight: 700 }}
            >
              {deal?.sold_mandate_date
                ? `Date demande de vente sous mandat ${formatDateToDDMMYYYY(deal.sold_mandate_date)}`
                : 'Vente sous mandat demandée'}
            </Button>

            {(userRole === 'god' || userRole === 'arbitrator') && (
              <>
                <CreatePurchaseCerfa deal={deal} isMandate={true} />
                <UploadModalMandate
                  dealId={dealId}
                  photos={dealData?.photos}
                  extUrl={dealData?.ext_url}
                  regPlate={dealData?.registration_plate}
                />
                <SubmitPurchaseCertificate deal={deal} isMandate={true} />
              </>
            )}
          </>
        )}
        {/* C2 */}
        {dealData && stageId === 10 && (userRole === 'god' || userRole === 'arbitrator') && (
          <GenerateInvoice deal={deal} pipeline={'c'} vat={dealData?.vat} />
        )}
        {/* C4 */}
        {dealData && stageId === 11 && (userRole === 'god' || userRole === 'arbitrator') && (
          <Logbook deal={deal} pipelineLetter="c" />
        )}
        {/* E1 */}
        {dealData && stageId === 36 && <SoldDepositSaleButton deal={deal} onRefresh={onRefresh} />}
        {/* E2 */}
        {dealData && stageId === 41 && (userRole === 'god' || userRole === 'arbitrator') && (
          <GenerateInvoice deal={deal} pipeline={'e'} vat={dealData?.vat} />
        )}
        {/* E4 + E5 */}
        {dealData && (stageId === 37 || stageId === 44) && (
          <>
            {(userRole === 'god' || userRole === 'arbitrator') && (
              <>
                <CreateCessionCerfa deal={deal} isDepositSale={true} isMandate={false} onRefresh={onRefresh} />
                <UploadModalDepositSale
                  dealId={dealId}
                  photos={dealData?.photos}
                  extUrl={dealData?.ext_url}
                  regPlate={dealData?.registration_plate}
                />

                <SubmitCessionCertificate deal={deal} pipelineLetter="e" />
              </>
            )}
          </>
        )}
        {userRole === 'agency' ? (
          <Typography sx={{ marginTop: 1, fontSize: 16 }} align="center" variant="body2" className="MuiTypographyTable">
            {currentStage ? currentStage.name : 'Loading...'}
          </Typography>
        ) : (
          <>
            <Typography
              sx={{ marginTop: 1, fontSize: 16 }}
              align="center"
              variant="body2"
              className="MuiTypographyTable"
            >
              {currentStage ? currentStage.name : 'Loading...'}
            </Typography>
            <DealStageModal
              dealId={dealId}
              dealPipelineId={deal.pipeline_id}
              stages={stages}
              pipelines={pipelines}
              initialStageId={String(stageId)}
              onRefresh={onRefresh}
            />
          </>
        )}
        <MarketPlaceButton dealId={deal.id} userRole={userRole} dealData={dealData} />
      </Box>
      <Dialog
        open={openConfirmRefuseDialog}
        onClose={() => setOpenConfirmRefuseDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">Votre demande de double-cotation a bien été prise en compte !</Typography>
            <Typography variant="body2" sx={{ marginTop: 2 }}>
              Veuillez entrer le prix du marché et le prix désiré par le client.
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <TextField
                autoFocus
                margin="dense"
                className="inputElement"
                id="dcMarketPrice"
                label="Prix du marché"
                required
                sx={{ marginRight: 2 }}
                type="number"
                fullWidth
                value={dcMarketPrice}
                onChange={(e) => setDcMarketPrice(e.target.value === '' ? '' : Number(e.target.value))}
              />
              <TextField
                autoFocus
                margin="dense"
                className="inputElement"
                id="dcDesiredPrice"
                label="Prix désiré par le client"
                type="number"
                fullWidth
                value={dcDesiredPrice}
                onChange={(e) => setDcDesiredPrice(e.target.value === '' ? '' : Number(e.target.value))}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmRefuseDialog(false)}>Annuler</Button>
          <Button
            disabled={dcMarketPrice === ''}
            onClick={() => {
              confirmRefusal(dcMarketPrice || undefined, dcDesiredPrice || undefined);
            }}
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmAcceptDialog}
        onClose={() => setOpenConfirmAcceptDialog(false)}
        aria-labelledby="confirm-accept-dialog-title"
        aria-describedby="confirm-accept-dialog-description"
      >
        <DialogTitle id="confirm-accept-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-accept-dialog-description" sx={{ color: 'black', fontWeight: '500' }}>
            {selectedArbitrationOffer ? (
              <>
                Êtes-vous sûr(e) de vouloir accepter l'offre arbitrée{' '}
                <b>{t(`business_type.${selectedArbitrationOffer?.offerType}`)} </b> à{' '}
                <b>{priceFormatter.format(parseInt(selectedArbitrationOffer?.price ?? '')) || 0}</b> ?
                <br />
                <br />
                Cela lance le processus d'acceptation chez SKIPCAR.
              </>
            ) : (
              <>
                Êtes-vous sûr(e) de vouloir valider cette cotation ? <br />
                Cela lance le processus d'acceptation chez SKIPCAR.
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmAcceptDialog(false)}>Annuler</Button>
          <Button onClick={confirmAcceptance} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openOutOfCharterDialog}
        onClose={() => setOpenOutOfCharterDialog(false)}
        aria-labelledby="out-of-charter-dialog-title"
        aria-describedby="out-of-charter-dialog-description"
      >
        <DialogTitle variant="body1" id="out-of-charter-dialog-title">
          Véhicule Hors Charte
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body2" id="out-of-charter-dialog-description">
            <>
              Bonjour,
              <br />
              <br />
              Ce véhicule ne fait pas partie de la charte SKIPCAR, nous ne pouvons pas le doubler en interne.
              <br />
              Soucieux de toujours trouver les couvertures les plus intéressantes et cohérentes, nous diffusons ce
              véhicule sur nos <b>marketplaces</b> partenaires afin d’obtenir des propositions commerciales.
              <br />
              Nous reviendrons vers vous en cas d'offre sur le véhicule.
              <br />
              <br />À bientôt.
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenOutOfCharterDialog(false)}>Annuler</Button>
          <Button
            onClick={() => {
              confirmRefusal();
              setOpenOutOfCharterDialog(false);
            }}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DealStageActions;
