import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist',
    body1: {
      fontWeight: 900,
      color: '#262626',
      fontSize: 20,
    },
    body2: {
      fontWeight: 500,
      color: '#464646',
      fontSize: 20,
    },
    h1: {
      fontWeight: 900,
      fontSize: 60,
    },
    h2: {
      fontWeight: 500,
      fontSize: 32,
      color: '#464646',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Personnalisez le style du TableCell ici
          color: '#262626',
          fontSize: 16,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          // Personnalisez le style des boutons "contained" ici
          color: 'white', // Couleur du texte en blanc
          fontWeight: 500,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#464646',
          textDecorationColor: '#464646',
          fontWeight: 600,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: '#464646',
          fontSize: 18,
          fontFamily: 'Urbanist',
          lineHeight: 1,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          paddingLeft: 10,
          background: '#262626',
          backgroundColor: '#262626',
          color: 'white',
          '& svg': {
            color: 'white !important',
          },
          '&.Mui-active svg': {
            color: 'white !important',
          },
          '&.Mui-active ': {
            color: 'white !important',
          },
        },
      },
    },
  },
  palette: {
    black: {
      main: '#262626',
      light: '#262626',
      dark: '#262626',
      contrastText: '#262626',
    },
    sunburst: {
      main: '#FDD15F',
    },
    amber: {
      main: '#FDC75F',
    },
    saffron: {
      main: '#FDBB5F',
      light: '#FDBB5F',
      dark: '#FDBB5F',
      contrastText: '#FDBB5F',
    },
    dark: {
      main: '#262626',
      light: '#464646',
    },
    orange: {
      main: '#EF8F35',
    },
    red: {
      main: '#FF6060',
    },
  },
});

export default theme;
