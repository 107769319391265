import { useRouteError } from 'react-router-dom';

interface RouteError {
  statusText?: string;
  message?: string;
}

export default function ErrorPage() {
  const error = useRouteError();

  console.error(error);

  if (error && typeof error === 'object' && 'statusText' in error && 'message' in error) {
    const routeError = error as RouteError;

    return (
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{routeError.statusText || routeError.message}</i>
        </p>
      </div>
    );
  } else {
    // Handle the case where error is not a RouteError
    return (
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
      </div>
    );
  }
}
